import { IBleSensorInformation } from "../../models/BleSensorInformation";
import { ITemplateModel, INikoLibraryModel } from "../../models/TemplatesModel";
import { IReceivedTemplateModel } from "../../models/TemplatesModel";
import {rawDetectorJsonSource} from "./RawDetectorJsonSource";

export interface ITemplateResponseModel {
  id: string;
  favoriteName: string;
  pinCode: string;
  updatedAt: string;
  uploadedAt: string;
  bleSensorInformationId: string;
  sensorInformation: IBleSensorInformation;
  userConfigModel: string;
  sensorProfile: string;
  zones: string;
  settingType: string;

  macAddress?: string;
  daliBusDevices?: string,
  controlGearDeviceCount?: number,
  inputDeviceCount?: number,
  hvacShortAddressExternalRelay?: number;
  cutOffShortAddressExternalRelay?: number;
  wiredPushButtonModel?: string;
  blePushButtonModel?: string;
  daliPushButtonModel?: string;
  getSetTunableWhiteListModelReply?: string;
  getSetTunableWhitePresetModelReply?: string;
  daliDeviceDataScenesModelReplies?: any[];
  getSetWirelessLinkFunctionModelReply: string;
}

export interface IReceivedTemplateResponseModel {
  id: string;
  name: string;
  receivedOn: string;
  receivedFrom: string;
  bleSensorInformationId: string;
  sensorInformation: IBleSensorInformation;
  userConfigModel: string;
  sensorProfile: string;
  zones: string;
  settingType: string;
  uploadedAt: number;


  macAddress?: string;
  daliBusDevices?: string,
  controlGearDeviceCount?: number,
  inputDeviceCount?: number,
  hvacShortAddressExternalRelay?: number;
  cutOffShortAddressExternalRelay?: number;
  wiredPushButtonModel?: string;
  blePushButtonModel?: string;
  daliPushButtonModel?: string;
  getSetTunableWhiteListModelReply?: string;
  getSetTunableWhitePresetModelReply?: string;
  daliDeviceDataScenesModelReplies?: any[];
  getSetWirelessLinkFunctionModelReply: string;
}

export interface INikoLibraryResponseModel {
  id: string;
  favoriteName: string;
  updatedAt: string;
  uploadedAt: string;
  sensorInformation: IBleSensorInformation;
  userConfigModel: string;
  sensorProfile: string;
  zones: string;
}

const newMotionDetectors = ["353-700111","353-600111","353-602111","353-702111","353-601111","353-701111"];

const isStringNullOrEmpty = (str: any): Boolean => {
  if (typeof str !== 'undefined' && str) {
    return false;
  }
  return true;
}

export const getDetectorTypeFormattedForFilterMenu = (data: any, translation: any): string => {
  let sensorInfo = data?.sensorInformation;

  let shortDescription = !isStringNullOrEmpty(sensorInfo?.detectorShortDescription) ? sensorInfo?.detectorShortDescription : "short-description";

  let detectorType = !isStringNullOrEmpty(sensorInfo?.detectorType) ? translation(`translation:BLE_SENSOR_INFORMATION.${sensorInfo.detectorType}`) : "";
  if (sensorInfo.detectorType === "detectorTypeDaliBMS") {
    detectorType = translation(`translation:BLE_SENSOR_INFORMATION.detectorTypeSlave`);
  }
  if (detectorType.indexOf("-") !== -1) {
    detectorType = detectorType.substr(0, detectorType.indexOf("-"));
  }
  let detectorTypeWords = detectorType.split(/[ ]+/);
  detectorType = detectorTypeWords[0];

  let res;
  if (sensorInfo?.detectorOutputInfo === "twoChannel") {
    res = shortDescription + ", " + translation(`translation:BLE_SENSOR_INFORMATION.twoChannelExtended`) + ", " + detectorType;
  } else {
    res = shortDescription + ", " + detectorType;
  }

  return res;
}

export const getDetectorTypeFormatted = (data: any, translation: any): string => {

  let sensorInfo = data?.sensorInformation;

  let detectorFamily = !isStringNullOrEmpty(sensorInfo?.detectorFamily) ? translation(`translation:BLE_SENSOR_INFORMATION.${sensorInfo.detectorFamily}`) : "";
  let detectorOutputInfo = !isStringNullOrEmpty(sensorInfo?.detectorOutputInfo) ? translation(`translation:BLE_SENSOR_INFORMATION.${sensorInfo.detectorOutputInfo}`) : "";
  let detectorType = !isStringNullOrEmpty(sensorInfo?.detectorType) ? translation(`translation:BLE_SENSOR_INFORMATION.${sensorInfo.detectorType}`) : "";

  let res = "";

  if (!isStringNullOrEmpty(sensorInfo?.detectorShortDescription))
  {
    res += sensorInfo?.detectorShortDescription;
  }

  if (!isStringNullOrEmpty(detectorFamily))
  {
    res +=  ", " + detectorFamily;
  }

  if (sensorInfo?.detectorFamily == '230VDectector' && sensorInfo?.detectorType == 'detectorTypeMaster' && !isStringNullOrEmpty(detectorOutputInfo))
  {
    res +=  ", " + detectorOutputInfo;
  }

  if (!isStringNullOrEmpty(detectorType))
  {
    if (!newMotionDetectors.includes(sensorInfo?.name)){
      res +=  ", " + detectorType;
    }
  }

  return res;
};

const detecorsJsonOb = JSON.parse(rawDetectorJsonSource);

const getFilterDataFormattedForDetector = (detector: any, translation: any): any => {

  let detectorCategory: string;
  if (detector?.DetectorFamily.includes("230V")) {
    detectorCategory = "230V";
  } else {
    detectorCategory = "dali";
  }

  let inputForGetDetectorTypeFormatted = {
    sensorInformation: {
      detectorFamily: detector?.DetectorFamily,
      detectorOutputInfo: detector?.DetectorOutputInfo,
      detectorType: detector?.DetectorType,
      detectorShortDescription: detector?.DetectorShortDescription
    }
  };

  let detectorTypeFormatted = getDetectorTypeFormattedForFilterMenu(inputForGetDetectorTypeFormatted, translation);

  return { type: detectorCategory, detectorType: detectorTypeFormatted, name: detector?.DetectorShortDescription };
}

export const getAllDetectorsByType = (translation: any, type: string): any => {
  let res = [];
  for (let i = 0; i < detecorsJsonOb.length; ++i) {
    let detectorFormatted = getFilterDataFormattedForDetector(detecorsJsonOb[i], translation);
    if (detectorFormatted.type === type) {
      res.push(detectorFormatted);
    }
  }
  return res;
}

export const mapFromTemplateResponseModelToITemplateModel = (
  data: ITemplateResponseModel
): ITemplateModel => ({
  templateSensorId: data.id,
  templateName: data.favoriteName,
  detectorType: data.sensorInformation.detectorType,
  detectorFamily: data.sensorInformation.detectorFamily,
  lastUpload: data.updatedAt,
  uploadedAt: data.uploadedAt,
  pinCode: data.pinCode,
  bleSensorInformationId: data.sensorInformation.id,
  userConfigModel: data.userConfigModel,
  updatedAt: data.updatedAt,
  sensorInformation: data.sensorInformation,
  sensorProfile: data.sensorProfile,
  zones: data.zones,
  settingType: data.settingType,

  macAddress: data.macAddress,
  daliBusDevices: data.daliBusDevices,
  controlGearDeviceCount: data.controlGearDeviceCount,
  inputDeviceCount: data.inputDeviceCount,
  hvacShortAddressExternalRelay: data.hvacShortAddressExternalRelay,
  cutOffShortAddressExternalRelay: data.cutOffShortAddressExternalRelay,
  wiredPushButtonModel: data.wiredPushButtonModel,
  blePushButtonModel: data.blePushButtonModel,
  daliPushButtonModel: data.daliPushButtonModel,
  getSetTunableWhiteListModelReply: data.getSetTunableWhiteListModelReply,
  getSetTunableWhitePresetModelReply: data.getSetTunableWhitePresetModelReply,
  daliDeviceDataScenesModelReplies: data.daliDeviceDataScenesModelReplies,
  getSetWirelessLinkFunctionModelReply: data.getSetWirelessLinkFunctionModelReply
});

export const mapReceivedTemplates = (
  data: IReceivedTemplateResponseModel
): IReceivedTemplateModel => ({
  templateSensorId: data.id,
  templateName: data.name,
  detectorType: data.sensorInformation.detectorType,
  detectorFamily: data.sensorInformation.detectorFamily,
  receivedOn: data.receivedOn,
  receivedFrom: data.receivedFrom,
  bleSensorInformationId: data.bleSensorInformationId,
  sensorInformation: data.sensorInformation,
  userConfigModel: data.userConfigModel,
  sensorProfile: data.sensorProfile,
  zones: data.zones,
  settingType: data.settingType,
  uploadedAt: data.uploadedAt,


  macAddress: data.macAddress,
  daliBusDevices: data.daliBusDevices,
  controlGearDeviceCount: data.controlGearDeviceCount,
  inputDeviceCount: data.inputDeviceCount,
  hvacShortAddressExternalRelay: data.hvacShortAddressExternalRelay,
  cutOffShortAddressExternalRelay: data.cutOffShortAddressExternalRelay,
  wiredPushButtonModel: data.wiredPushButtonModel,
  blePushButtonModel: data.blePushButtonModel,
  daliPushButtonModel: data.daliPushButtonModel,
  getSetTunableWhiteListModelReply: data.getSetTunableWhiteListModelReply,
  getSetTunableWhitePresetModelReply: data.getSetTunableWhitePresetModelReply,
  daliDeviceDataScenesModelReplies: data.daliDeviceDataScenesModelReplies,
  getSetWirelessLinkFunctionModelReply: data.getSetWirelessLinkFunctionModelReply,
});

export const mapNikoLibraryModel = (
  data: INikoLibraryResponseModel
): INikoLibraryModel => ({
  templateName: data.favoriteName,
  detectorType: data.sensorInformation.detectorType,
  detectorFamily: data.sensorInformation.detectorFamily,
  updatedAt: data.updatedAt,
  uploadedAt: data.uploadedAt,
  sensorInformation: data.sensorInformation,
  userConfigModel: data.userConfigModel,
  sensorProfile: data.sensorProfile,
  zones: data.zones,
});

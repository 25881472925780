import { settings } from "cluster";
import {
  GetSensorDescription230VMaster,
  GetSensorDescription230VSlave,
  GetSensorDescriptionDaliMaster,
  GetSensorDescriptionDaliMasterComfort,
  GetSensorDescriptionDaliSlave,
} from "./GetSensorDescription";

const getNameAndValue = (settingList: any, type: string): string => {
  const element = settingList.find((el: any) => el.type === type);
  if (element) {
    return `${element.label} ${element.value.join(" ")}`;
  }
  return "";
};

const getName = (settingList: any, type: string): string => {
  const element = settingList.find((el: any) => el.type === type);
  if (element) {
    return `${element.label}`;
  }
  return "";
};
const getValue = (settingList: any, type: string): string => {
  const element = settingList.find((el: any) => el.type === type);
  if (element) {
    return `${element.value.join(" ")}`;
  }
  return "";
};

const GetProjectDetails = (detector: any) => {
  //const details = GetSensorDescription(detector);
  try {
    let family = detector.sensorInformation.detectorFamily.toLowerCase();
    let type = detector.sensorInformation.detectorType.toLowerCase();
    let daliComfort = detector.sensorInformation.detectorOutputInfo;

    if (family.includes("230v") && type.includes("master")) {
      let data = GetSensorDescription230VMaster(detector, true);
      const { channels } = data;

      return `${getName(channels, "name")} ${getNameAndValue(
        channels,
        "luxLevel"
      )}; ${getNameAndValue(channels, "switchOffAboveLux")}; ${getNameAndValue(
        channels,
        "switchOffDelay"
      )}`;
    } else if (family.includes("230v") && type.includes("slave")) {
      let data = GetSensorDescription230VSlave(detector);
      const { fineTunning } = data;
      let fineTunningDescription = "";

      if (fineTunning.length > 2)
      {
        fineTunning.map((el:any, i:number) => {
          if(i < 2 || el.label.includes("sector")) fineTunningDescription += ` ${getNameAndValue(fineTunning, el.type)}`;
        });
      }
      else
      {
        fineTunningDescription += fineTunningDescription += ` ${getNameAndValue(fineTunning.slice(1), "sensitivity")}`;
      }
      return fineTunningDescription;
    } else if (
      family.includes("dali") &&
      type.includes("master") &&
      (daliComfort === "wirelessComfort" || daliComfort === "daliComfort")
    ) {
      let { settings } = GetSensorDescriptionDaliMasterComfort(detector, true);

      let daliDescription = "";
      if (settings[0].type.toLowerCase().includes("zones"))
        daliDescription += `${getValue(settings, "zones")}; `;
      else
        daliDescription += `${getNameAndValue(settings, "name")} ${settings[0].label.trim()}; `;

      daliDescription += `${getNameAndValue(settings, "luxLevel")}; ${getNameAndValue(
        settings,
        "movementIsDetected"
      )};
       ${getNameAndValue(settings, "switchOffAboveLux")}; ${getNameAndValue(
        settings,
        "switchOffDelay"
      )};  ${getNameAndValue(
        settings,
        "orientationLightLevel"
      )}; ${getNameAndValue(settings, "orientationLightSwitchOffDelay")}`;
      return daliDescription;
    } else if (family.includes("dali") && type.includes("master")) {
      let { settings } = GetSensorDescriptionDaliMaster(detector, true);

      let daliDescription = "";
      if (settings[0].type.toLowerCase().includes("zones"))
        daliDescription += `${getValue(settings, "zones")}; `;
      else
        daliDescription += `${getNameAndValue(settings, "name")} ${settings[0].label.trim()}; `;
        
      daliDescription += `${getNameAndValue(settings, "name")} ${getNameAndValue(
          settings,
          "luxLevel"
        )}; ${getNameAndValue(
          settings,
          "switchOffAboveLux"
        )}; ${getNameAndValue(settings, "switchOffDelay")}`;
        return daliDescription;
    } else if (
      family.includes("dali") &&
      (type.includes("bms") || type.includes("slave"))
    ) {
      let data = GetSensorDescriptionDaliSlave(detector);
      const { fineTunning } = data;
      let fineTunningDescription = "";

      if (fineTunning.length > 2)
      {
        fineTunning.map((el:any, i:number) => {
          if(i < 2 || el.label.includes("sector")) fineTunningDescription += ` ${getNameAndValue(fineTunning, el.type)}`;
        });
      }
      else
      {
        fineTunningDescription += fineTunningDescription += ` ${getNameAndValue(fineTunning.slice(1), "sensitivity")}`;
      }

      return fineTunningDescription;
    } else {
      return "Detector details not found";
    }
  } catch (e) {
    console.log(e);
    return "Detector details not found";
  }
};

export default GetProjectDetails;

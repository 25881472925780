import { makeStyles, createStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => createStyles({
    buttonContainer: {
        width: '352px',
    },

    buttonsContainerTwo: {
        display: "flex",
        "& button": {
            width: "160px",
            height: "48px",
        },
        width: "80%",
        justifyContent: "space-around",
    },
}));

export default useStyles;

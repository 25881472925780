import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import ResponseStatus from "../../../common/components/Auth/ResponseStatus";
import useButtonStyles from "../../../common/styles/buttonStyles";
import useStyles from "./ResetPassword.style";
import { IResetPasswordModel } from "../../../models/AuthModel";
import { resetPassword } from "../../../data-services/Auth/AuthService";
import ShowPassword from "../../../common/components/ShowPassword";
import { getLocalizedRoute } from "../../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../../common/constants/routes";
import validators from "../../../common/utils/validators";

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState<string>();
  const [repeatPassword, setRepeatPassword] = useState<string>();
  const [isReset, setIsReset] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { formatMessage, locale } = useIntl();
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(true);
  let history = useHistory();
  let { token } = useParams();


  const getPasswordError = () => {
    if (password === "") {
      return t("translation:common.requiredError");
    }
    if (!isPasswordValid) {
      return t("translation:common.passwordWeakError");
    }
  };

  const getRepeatPasswordError = () => {
    if (repeatPassword === "") {
      return t("translation:common.requiredError");
    }
    if (!isRepeatPasswordValid) {
      return t("translation:common.repeatPasswordError");
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    if (newPassword !== undefined) {
      setPassword(newPassword);
      setIsPasswordValid(validators.isPasswordValid(newPassword));
      repeatPassword && setIsRepeatPasswordValid(newPassword === repeatPassword);
    }
  };

  const handleRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRepeatPassword = event.target.value;
    if (newRepeatPassword !== undefined) {
      setRepeatPassword(newRepeatPassword);
      setIsRepeatPasswordValid(newRepeatPassword === password);
    }
  };

  const setNewPassword = async (token: string, password: string) => {
    
      if (password) {
        const userData: IResetPasswordModel = { token, password };
        const { status, message } = await resetPassword(userData);
        if (status === "success") {
          setIsReset(true);
        }
        else {
          setIsError(true);
          setErrorMessage(message);
          //history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
        }
      }
    
  };

  const handleResetPassword = () => {
    if (password && repeatPassword && password === repeatPassword) {
      setNewPassword(token, password);
    }
  };

  return isReset ? (
    <ResponseStatus
      title={t("translation:login_Forgotpassword_Passwordreset.title")}
      content={t("translation:login_Forgotpassword_Passwordreset.content")}
    />
  ) : (
    <Grid container className={classes.resetPasswordWrapper}>
      <Grid item className={classes.itemContainer}>
        <img
          className={classes.image}
          src="/img/Detectortoolportal_height_small.jpg"
          alt="forgotPassword"
        />
      </Grid>
      <Grid item className={classes.contentWrapper}>
        <Typography component="h1" className={classes.formTitle}>
          {t("translation:login_Forgotpassword_Resetpassword.title")}
        </Typography>
        <div
          className={`${classes.verticalInputSpacing} ${
            (password === "" || repeatPassword === "" || !isRepeatPasswordValid) && classes.errorBorder
          } `}
        >
          <InputLabel className={classes.inputLabel} required={true}>
            {t("translation:login_Forgotpassword_Resetpassword.newPassword")}
          </InputLabel>
          <TextField
            type={showPassword ? "text" : "password"}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword}>
                    <ShowPassword showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            focused={false}
            placeholder="********"
            className={classes.textInput}
            onChange={handlePasswordChange}
          />
          {isError && (
            <Box>
                <Typography style={{ float: 'left' }} className={classes.error}>
                  {errorMessage || t("translation:common.passwordError")}
                </Typography>
              </Box>
          )}
          {(password === "" || !isPasswordValid)   &&  (
            <Box>
              <Typography className={classes.error} style={{maxWidth:"350px"}}>
                {getPasswordError()}
              </Typography>
            </Box>
          )}
        </div>
        <div
          className={`${classes.verticalInputSpacing} ${
            (password === "" || repeatPassword === "" || !isRepeatPasswordValid) && classes.errorBorder
          } `}
        >
          <InputLabel className={classes.inputLabel} required={true}>
            {t("translation:login_Forgotpassword_Resetpassword.repeatPassword")}
          </InputLabel>
          <TextField
            type={showPassword ? "text" : "password"}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword}>
                    <ShowPassword showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            focused={false}
            placeholder="********"
            className={classes.textInput}
            onChange={handleRepeatPasswordChange}
          />
          {(repeatPassword === "" || !isRepeatPasswordValid)   &&  (
            <Box>
              <Typography className={classes.error}>
                {getRepeatPasswordError()}
              </Typography>
            </Box>
          )}
          <InputLabel className={classes.inputLabel} required={true}>
            {t("translation:common.requiredField")}
          </InputLabel>
        </div>
        <Button
          onClick={handleResetPassword}
          className={`${buttonClasses.primaryButton} ${classes.button}`}
          disabled={!password || password !== repeatPassword || !isPasswordValid || !isRepeatPasswordValid}
        >
          {t("translation:login_Forgotpassword.resetButton")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
import * as localizations from './localizations';
import { ILanguage } from './localizations';

interface IAppStrings {
    [key: string]: ILanguage
}

export const appStrings: IAppStrings = localizations;
export * from './components/LocalizedRouter';
export * from './components/LocalizedSwitch';
export * from './components/LanguageSwitcher';

import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import Register from "../Auth/Register/Register";
import useStyles from "./CreateNewAccount.style";

const CreateNewAccount: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.newAccountWrapper}>
      <Grid item className={classes.newAccountItem}>
        <img className={classes.image} src="/img/Detectortoolportal_height_tall_small.jpg" alt="register" />
      </Grid>
      <Grid item className={classes.registerContainer}>
        <Register />
      </Grid>
    </Grid>
  );
};

export default CreateNewAccount;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import { commonParagraph, mainHeader } from "../../common/styles/typography";

const inputAlign = {
  paddingLeft: "8px",
  borderLeft: `4px solid transparent`,
  marginLeft: `-12px`,
  minHeight: "100px",
};

const useStyles = makeStyles((theme) =>
  createStyles({
    newAccountWrapper: {
      width: "100%",
      maxWidth: "1920px",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignContent: "center",
      },
    },
    myAccountWrapper: {
      width: "100%",
      //margin: "0 auto",
      //paddingTop: theme.spacing(10),
      //paddingBottom: theme.spacing(13.75),
      margin: "10px 0px 10px 30px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignContent: "center",
        margin:"0"
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
      },
    },
    mainHeader: {
      ...mainHeader,
      paddingBottom: "35px"
    },
    pictureContainer: {
      //width: "45%",
      //marginTop: "92px",
      //marginLeft: "100px",
      overflow: "hidden",
      marginBottom: "-6px",
      textAlign: "center",
      "& img": {
        height: "100%",
        //width: "800px",
        //width: "575px",
        //maxWidth: "770px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    myAccountForm: {
      //width: "45%",
      width: "352px",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "20px 0",
      },
    },
    inputItem: {
      width: "352px",
      //   ...inputAlign,
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      marginBottom: "7px"
    },
    buttonsItem: {
      width: "352px",
      display: "flex",
      justifyContent: "space-between",
      //marginTop: theme.spacing(6),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    verticalInputSpacing: {
      ...inputAlign,
    },
    lastTextfield: {
      marginBottom: "7px",
      ...inputAlign,
    },
    inputLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingBottom: "8px",
      paddingTop: "8px",
      "& .MuiInputLabel-asterisk": {
        color: "red",
      },
    },
    inputAsterixOnLeftLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingTop: "10px",
      alignSelf: "start",
      paddingLeft: "10px",
      "& .MuiInputLabel-asterisk": {
        color: "red",
        float: "left",
        marginLeft: "-15px"
      },
    },
    changeLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingBottom: "8px",
      paddingTop: "8px",
      textDecoration: "underline",
      textDecorationSkipInk: "none",
      cursor: "default",
    },
    textInput: {
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "0",
        },
      },
      padding: "10px 24px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "18px",
        fontFamily: "Roboto, sans-serif",
        color: colors.primaryColor,
        height: "18px",
        backgroundColor: `${colors.veryLightGreyColor}!important`,
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px 0",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
    },
    textInputEmailPassword: {
      borderRadius: "24px",
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
          "& fieldset": {
              border: "0",
          },
      },
      "& .MuiInputBase-input": {
          fontSize: "16px",
          lineHeight: "18px",
          fontFamily: "Roboto, sans-serif",
          color: colors.primaryColor,
          height: "18px",
      },
      "& .MuiOutlinedInput-input": {
          padding: "5px 0",
      },
      "& .MuiInputBase-root": {
          paddingRight: "0",
      },
    },
    required: {
      color: "red",
    },
    spaceItems: {
      marginLeft: "7px",
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
    errorBorder: {
      borderLeft: `4px solid ${colors.errorRedColor}`,
    },
    recaptcha: {
      marginBottom: theme.spacing(4),
    },
    labelPasswordOrEmail: {
      display: "flex",
      justifyContent: "space-between",
    },
    width: {
      width: "160px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
    },
    modalCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "402px",
      padding: "104px 32px 0",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalStatusCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "442px",
      height: "336px",
      padding: "46px 40px 32px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      flexWrap: "nowrap",
    },
    modalChangePasswordCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      // height: "476px",
      padding: "104px 32px 32px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      flexWrap: "nowrap",
    },
    placeholderInput: {
      '&::placeholder': {
        color: colors.primaryColor,
        opacity: 1
      },
    },
  })
);

export default useStyles;

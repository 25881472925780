import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";

const inputAlign = {
  paddingLeft: "8px",
  borderLeft: `4px solid transparent`,
  marginLeft: `-12px`,
  minHeight: "100px",
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      width: "352px",
      [theme.breakpoints.down("md")]: {
        margin: "0 auto",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    verticalInputSpacing: {
      ...inputAlign,
    },
    lastTextfield: {
      marginBottom: "7px",
      ...inputAlign,
    },
    inputLabel: {
      color: colors.darkerGreyColor,
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Roboto, sans-serif",
    },
    textInput: {
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "0",
        },
      },
      padding: "10px 24px",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "18px",
        fontFamily: "Roboto, sans-serif",
        color: colors.primaryColor,
        height: "18px",
        backgroundColor: `${colors.veryLightGreyColor}!important`,
      },
      "& .MuiOutlinedInput-input": {
        padding: "5px 0",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
    },
    labelPassword: {
      display: "flex",
      justifyContent: "space-between",
    },
    verticalSpacing: {
      marginBottom: theme.spacing(2),
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
    errorBorder: {
      borderLeft: `4px solid ${colors.errorRedColor}`,
    },
    mainTitle: {
      fontSize: "32px",
      fontFamily: "VistaSansReg",
      lineHeight: "34px",
      textAlign: "left",
      marginBottom: theme.spacing(10),
      color: colors.blackColor,
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    lineHeight: {
      lineHeight: "28px",
    },
  })
);

export default useStyles;

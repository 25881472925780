import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      minWidth: "156px",
      height: "32px",
      background: colors.darkGreyColor,
      borderRadius: "24px",
      color: colors.whiteColor,
      fontSize: "12px",
      fontFamily: "Roboto, sans-serif",
      lineHeight: "16px",
      textTransform: "none",
      padding: "8px 16px",
      display: "flex",
      justifyContent: "space-between",
    },
    chevronDown: {
      "& img": {
        width: "16px",
        height: "6px",
      },
    },
    dropdown: {
      top: theme.spacing(8),
      "& .MuiPaper-rounded": {
        borderRadius: "0",
        top: theme.spacing(8),
        boxShadow: "none",
      },
    },
    dropdownItem: {
      backgroundColor: colors.veryLightGreyColor,
      width: "172px",
      padding: "0 0px",
      fontSize: "14px",
      fontFamily: "Roboto, sans-serif",
      cursor: "default",
      "& a": {
        textDecoration: "none",
        color: colors.primaryColor,
        lineHeight: "24px",
        padding: "4px 0",
        display: "inline-block",
        fontSize: "14px",
        fontFamily: "Roboto, sans-serif",
      },
      "&:hover": {
        backgroundColor: colors.lightGreyColor,
      },
    },
    navlinkDropdownItem: {
      padding: "4px 16px !important",
      width: "172px"
    }
  })
);

export default useStyles;

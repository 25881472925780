import React, { useState } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { IDetectorModel } from "../../../models/DetectorModel";
import SensorDataComponent from "../../../common/components/SensorDataComponent/SensorDataComponent";
import useTypographyStyles from "../../../common/styles/typography";
import useButtonStyles from "../../../common/styles/buttonStyles";
import useStyles from "./DetectorDetails.style";
import { getDetectorTypeFormatted } from "../../../data-services/Templates/TemplateMapper";
import { useAuth } from "../../../context/context";

interface IDetectorDetails {
  detector: IDetectorModel;
  closeModal: () => void;
  openExportModal: () => void;
}

const DetectorDetails: React.FC<IDetectorDetails> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const buttonStyles = useButtonStyles();
  const timeZone = moment.tz.guess();
  const { t } = useTranslation(["translation"]);
  const { openExportModal, closeModal, detector } = props;
  const authStore = useAuth();

  const handleOpenExportModal = () => {
    closeModal();
    openExportModal();
  };

  return (
    <>
      <Grid item className={classes.closeIcon} onClick={closeModal}>
        <img src="/img/close.svg" alt="close" />
      </Grid>
      <Grid item>
        <Typography className={typoClasses.headerThreeBlack} component="h3">
          {getDetectorTypeFormatted(detector, t)}
        </Typography>
      </Grid>
      <Grid item className={classes.list}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button disableRipple={true} className={classes.infoItem}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:common.modals.detectorName")}
              secondary={detector.customName}
            />
          </ListItem>
          <ListItem button disableRipple={true} className={classes.infoItem}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:common.modals.MACaddress")}
              secondary={detector.macAddress}
            />
          </ListItem>
          <ListItem button disableRipple={true} className={classes.infoItem}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:common.modals.projectName")}
              secondary={detector.projectName}
            />
          </ListItem>

          <ListItem button disableRipple={true} className={classes.infoItem}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:common.modals.lastUpload")}
              secondary={`${moment
                .tz(detector.lastUpload, timeZone)
                .format("DD.MM.YYYY")} `}
            />
          </ListItem>

          <SensorDataComponent detector={detector} isSensorSetting={false} />
        </List>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
          className={`${buttonStyles.lightButtonWithBorder} ${classes.spaceBetween}`}
          onClick={closeModal}
        >
          {t("translation:common.closeButton")}
        </Button>
        <Button
          className={buttonStyles.primaryButton14px}
          onClick={handleOpenExportModal}
        >
          {t("translation:common.export")}
        </Button>
      </Grid>
    </>
  );
};

export default DetectorDetails;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";
import {
  paragraph14pxHumanist,
} from "../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    detectorActions: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      paddingBottom: theme.spacing(10),
      paddingTop: theme.spacing(2),
    },
    infoItem: {
      borderBottom: `0.5px solid ${colors.primaryColor}`,
      padding: "7px 24px",
      cursor: "default",
      "&.MuiListItem-button:hover": {
         backgroundColor: colors.secondaryColor,
       },
    },
    list: {
      width: "100%",
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      "& button": {
        width: "352px",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      marginTop: theme.spacing(5),
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    primaryStyle: {
      display: "inline-block",
      ...paragraph14pxHumanist,
      color: colors.primaryColor,
    },
    secondaryStyle: {
      display: "inline-block",
      color: colors.primaryColor,
      ...paragraph14pxHumanist,
    },
    closeIcon: {
      paddingBottom: "18px",
      alignSelf: "flex-end",
      cursor: "default",
    },
    spaceBetween: {
      marginBottom: "31px",
    },
  })
);

export default useStyles;

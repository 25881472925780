import React, {useEffect, useState} from "react";
import { AppBar, Toolbar, MenuItem, Grid, Badge, Button, IconButton } from "@material-ui/core";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import i18n from "../../i18n";
import LanguageSwitcher from "../../common/services/i18n/components/LanguageSwitcher";
import {getLocalizedRoute} from "../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../common/constants/routes";
import MyAccount from "./components/MyAccount";
import { useAuth } from "../../context/context";
import {useHistory} from "react-router-dom";
import useStyles from "./Header.style";
import { useMediaQuery, useTheme, List, ListItem, ListItemText } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
//import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';


const Header: React.FC = () => {
  const classes = useStyles();
  const { formatMessage, locale } = useIntl();
  const authStore = useAuth();
  let location = useLocation();
  let history = useHistory();

  let globalAccess = localStorage.getItem("globalAccess") ?? "";
  const [hasAccessToPlatform] = useState(globalAccess === 'true');

  const [seeInstallerBar, setSeeInstallerBar] = useState(false);
  const [userHistory, setUserHistory] = useState(undefined);
  const [hiddenInstaller, setHiddenInstaller] = useState(false);

  const toImpersonateUserEmail = localStorage.getItem("toImpersonateUserEmail");
  const impersonatedUserEmail = localStorage.getItem("impersonatedUserEmail");

  const [impersonatedUserFullname, setImpersonatedUserFullname] = useState('');

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isSM =  useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // console.log(impersonatedUserEmail);
    if (toImpersonateUserEmail != undefined &&
        toImpersonateUserEmail != null &&
        toImpersonateUserEmail != "null" &&
        toImpersonateUserEmail.length > 4) {
      setSeeInstallerBar(true);
      let impersonatedUserHist = authStore?.getImpersonatedUserHistory();
      setUserHistory(impersonatedUserHist);

      setImpersonatedUserFullname(impersonatedUserHist['firstName'] + ' ' + impersonatedUserHist['lastName']);
    }
    else {
      setSeeInstallerBar(false);
    }
    setHiddenInstaller(false);
  }, [impersonatedUserEmail]);

  useEffect(() => {
    i18n.changeLanguage(locale);

    if (impersonatedUserEmail == undefined ||
        impersonatedUserEmail == null ||
        impersonatedUserEmail == "null" ||
        impersonatedUserEmail.length <= 1) {
      setHiddenInstaller(true);
    }
  }, []);

  const localizeRouteKey = (path: string) => {
    return `/${locale}` + formatMessage({ id: path });
  };

  const { t } = useTranslation(["translation"]);

  const countNotifications = () => {
    if (authStore) {
      const newReceivedTemplates = authStore.newReceivedTemplates;
      if (newReceivedTemplates > 0) {
        return newReceivedTemplates;
      }
      return authStore.myNewTemplates;
    }
  };

  const shouldHideForMaintenancePage = () => {
    if (authStore?.isLoggedIn) {
      return false;
    }

    return !hasAccessToPlatform;
  }

  const deactivateInstallerView = () => {
    setSeeInstallerBar(false);
    setHiddenInstaller(false);

    authStore?.setImpersonatedUserHistory(null);
    authStore?.setImpersonatedUserEmail(null);

    localStorage.setItem("toImpersonateUserEmail", "null");

    history.push(getLocalizedRoute(AppRoute.InstallerView, locale, formatMessage));
    //window.location.reload();
  };

  const hideInstallerView = () => {
    if (hiddenInstaller) {
      setHiddenInstaller(false);
      authStore?.setImpersonatedUserEmail(toImpersonateUserEmail);
    }
    else {
      setHiddenInstaller(true);
      authStore?.setImpersonatedUserEmail(null);
    }
    window.location.reload();
    //history.push(getLocalizedRoute(AppRoute.InstallerView, locale, formatMessage));
  };

  const hideInstallerBar = () => {
    setSeeInstallerBar(false);
  };

  

  return (
    <>
    <AppBar className={classes.headerContainer}>
      <Toolbar>  
        <Grid container direction="row" className={classes.wrapContainer}>
          <Grid item className={classes.logo}>
            <Link to={localizeRouteKey(AppRoute.Home)}>
              <img src="/img/LogoNiko.png" alt="logo" />
            </Link>
          </Grid>

          <Grid
            item
            className={`${classes.menu} ${
              authStore?.isLoggedIn ? classes.align : classes.alignStart
            }`}
          >
            {!isSM && <MenuItem
              component="div"
              className={classes.items}
              disableGutters={true}
              disabled={shouldHideForMaintenancePage()}
              selected={location.pathname === localizeRouteKey(AppRoute.About)}
            >
              <NavLink
                to={localizeRouteKey(AppRoute.About)}
                activeClassName="underline"
              >
                { !shouldHideForMaintenancePage() && t("translation:menu.about") }
                { shouldHideForMaintenancePage() && <p style={{color: "black"}}>-</p> }
              </NavLink>
            </MenuItem>}
            {authStore?.isLoggedIn && (
              <>
                {!isSM && <MenuItem
                  component="div"
                  className={classes.items}
                  disableGutters={true}
                >
                  <NavLink
                    to={localizeRouteKey(AppRoute.ProjectsMy)}
                    activeClassName="underline"
                    isActive={() => {
                      return location.pathname.includes("projects/my") || location.pathname.includes("project/");
                    }}
                  >
                    {t("translation:menu.projects")}
                  </NavLink>
                </MenuItem>}
                {!isSM && <MenuItem
                  component="div"
                  className={classes.items}
                  disableGutters={true}
                >
                  <NavLink
                    to={localizeRouteKey(AppRoute.Detector)}
                    activeClassName="underline"
                  >
                    {t("translation:menu.detector")}
                  </NavLink>
                </MenuItem>}
                {!isSM && <MenuItem
                  component="div"
                  className={classes.items}
                  onClick={authStore.countReceivedTemplates}
                  disableGutters={true}
                >
                  <NavLink
                    to={localizeRouteKey(AppRoute.Templates)}
                    activeClassName="underline"
                  >
                    <Badge
                      badgeContent={authStore && countNotifications()}
                      className={classes.badge}
                    >
                      {t("translation:menu.templates")}
                    </Badge>
                  </NavLink>
                </MenuItem>}
              </>
            )}
          </Grid>

          <Grid item className={classes.myAccount}>
            { !shouldHideForMaintenancePage() && <MyAccount hideInstallerBar={hideInstallerBar}/> }
          </Grid>

          {isSM && <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>}

          {!isSM && <Grid item className={classes.languages}>
            <LanguageSwitcher />
          </Grid>}
        </Grid>
      </Toolbar>
     {seeInstallerBar &&
     <Grid item style={{backgroundColor: "#CCCCCC", height: "40px"}}>

        <Button
          className={classes.hideInstaller}
          onClick={hideInstallerView}
          >
          {hiddenInstaller ? `${i18n.t("translation:installerView.unhideView",
                              {installer: impersonatedUserFullname})}`
                           : `${i18n.t("translation:installerView.hideView",
                              {installer: impersonatedUserFullname})}`
          }
        </Button>

          <img
            style={{ imageRendering: "-webkit-optimize-contrast", width: "24px", verticalAlign: "middle" }}
            src={`/img/${hiddenInstaller ? "icon_camera_peek_view_disabled_R00.png" : "icon_camera_peek_view_R00.png"}`}
            alt="seePassword"
            onClick={hideInstallerView}
          />

        <Button
          className={classes.disableInstaller}
          onClick={deactivateInstallerView}>
          {`${i18n.t("translation:installerView.disableInstallerView",
          {installer: impersonatedUserFullname})}`}
        </Button>
      </Grid>
      }
    </AppBar>
    <SwipeableDrawer
            anchor={"right"}
            open={open}
            onClose={()=>setOpen(false)}
            onOpen={()=>setOpen(true)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
          {authStore?.isLoggedIn && <List onClick={()=>setOpen(false)} className={classes.drawerMenu}>
            <div className={classes.drawerHeader}>
              <IconButton onClick={()=>setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider />
            <ListItem button key="about" component={NavLink} to={localizeRouteKey(AppRoute.About)} 
            selected={location.pathname === localizeRouteKey(AppRoute.About)}
            >
              <ListItemText primary={t("translation:menu.about")} />
            </ListItem>
            <ListItem button key="projects" component={NavLink} to={localizeRouteKey(AppRoute.ProjectsMy)}
            selected={location.pathname.includes("projects/my") || location.pathname.includes("project/")}
            >
              <ListItemText primary={t("translation:menu.projects")} />
            </ListItem>
            <ListItem button key="detector" component={NavLink} to={localizeRouteKey(AppRoute.Detector)}
            selected={location.pathname === localizeRouteKey(AppRoute.Detector)}
            >
              <ListItemText primary={t("translation:menu.detector")} />
            </ListItem>
            <ListItem button key="templates" component={NavLink} to={localizeRouteKey(AppRoute.Templates)}
            selected={location.pathname === localizeRouteKey(AppRoute.Templates)}
            >
              <ListItemText primary={t("translation:menu.templates")} />
            </ListItem>
          </List>}
          <Grid style={{marginLeft:"auto",marginRight:"auto",bottom:"20px"}} item className={classes.languages}>
            <LanguageSwitcher />
          </Grid>
        </SwipeableDrawer>
    </>
  );
};
export default Header;

import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./DetectorsHeader.style";

const DetectorsHeader: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);

  return (
    <Grid item>
      <Typography className={classes.heading} component="h1">
        {t("translation:Detectors.title")}
      </Typography>
      <Typography className={classes.content}>
        {t("translation:Detectors.content")}
      </Typography>
    </Grid>
  );
};

export default DetectorsHeader;

import httpService from "../../data-services/HTTPService";
import { ISendModel, IShareManyModel } from "../../models/SendModel";
import { AxiosResponse } from "axios";

const baseUrl = "SharedSetting";

export const send = async (data: ISendModel): Promise<any> => {
  try {
    const response: AxiosResponse = await httpService.sendMessage(
      "post",
      baseUrl,
      data
    );
    return { status: "success", data: response.data };
  } catch (error) {
    return { status: "error", message: "Error" };
  }
};

export const shareMany = async (data: IShareManyModel): Promise<any> => {
  const url = baseUrl + `/ShareMany`;
  try {
    const response: AxiosResponse = await httpService.sendMessage(
      "post",
      url,
      data
    );
    if (response.status === 204) {
      return { status: "success" };
    } else if (response.status === 401) {
      return { status: "error", message: "Test error not auth" };
    } else {
      return { status: "error", message: "Test error bad request" };
    }
  } catch (error) {
    return { status: "error", message: "Error" };
  }
};

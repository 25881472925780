import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import { AppRoute } from "../../common/constants/routes";
import { getLocalizedRoute } from "../../common/services/i18n/utils/routeLocalization";
import useButtonStyles from "../../common/styles/buttonStyles";
import useStyles from "./ActivationFailed.style";

const ActivationFailed: React.FC = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const { formatMessage, locale } = useIntl();
  let history = useHistory();

  const redirectToLogin = () => {
    history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
  };

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.itemContainer}>
        <img
          className={classes.image}
          src="/img/Detectortoolportal_height_small.jpg"
          alt="activationFailed"
        />
      </Grid>

      <Grid item className={classes.contentWrapper}>
        {/* <img src="/img/checkmark.svg" alt="checkmark" /> */}
        <Typography className={classes.title} component="h3">
          {t("translation:Activation_Failed.title")}
        </Typography>
        <Typography className={classes.content}>
          {t("translation:Activation_Failed.content")}
        </Typography>
        <Button
          onClick={redirectToLogin}
          className={` ${buttonClasses.primaryButton} ${classes.button}`}
        >
          {t("translation:common.loginButton")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ActivationFailed;

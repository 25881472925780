import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Input,
} from "@material-ui/core";
import Delete from "../../../../common/components/Actions/Delete";
import Send from "../../../../common/components/Actions/Send";
import { ITemplateModel } from "../../../../models/TemplatesModel";
import SensorDataComponent from "../../../../common/components/SensorDataComponent/SensorDataComponent";
import useTypographyStyles from "../../../../common/styles/typography";
import useButtonStyles from "../../../../common/styles/buttonStyles";
import useStyles from "./MyTemplateDetails.style";
import { getDetectorTypeFormatted } from "../../../../data-services/Templates/TemplateMapper";

interface ITemplateDetails {
  newName: string;
  setNewName(name: string): void;
  template: ITemplateModel;
  removeTemplate(id: string): void;
  openSendModal(): void;
  openDeleteModal(): void;
  openSaveModal(templateSensorId: string): void;
  closeModal(): void;
}

const MyTemplateDetails: React.FC<ITemplateDetails> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const buttonStyles = useButtonStyles();
  const { t } = useTranslation(["translation"]);

  const timeZone = moment.tz.guess();

  const {
    template,
    openSendModal,
    openDeleteModal,
    closeModal,
    newName,
    setNewName,
    openSaveModal,
  } = props;

  const [innput, setInnput] = useState(template.templateName);

  const handleSetNewName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
    setInnput(e.target.value)
  };

  const closeTemplateDetailsModal = () => {
    if (!!newName) {
      openSaveModal(template.templateSensorId);
      closeModal();
    }
  };

  useEffect(() => {
    const inputSpanLabel = document.getElementById("inputExtender") as HTMLInputElement;
    const inputLabel = document.getElementById("inputLabel") as HTMLInputElement;

    inputLabel.style.width = inputSpanLabel.clientWidth + "px";

    if (inputSpanLabel.clientWidth > 432) {
      inputLabel.style.width = 432 + "px";
    }
    else if (inputSpanLabel.clientWidth < 80) {
      inputLabel.style.width = 80 + "px";
    }
  })
  //}, [innput])

  useEffect(() => {
    setInnput(template.templateName);
  }, [template])

  return (
    <>
      <Grid item className={classes.closeIcon} onClick={closeModal}>
        <img src="/img/close.svg" alt="close" />
      </Grid>
      <Grid item>
        <Typography className={typoClasses.headerThreeBlack} component="h3">
          {template.templateName}
        </Typography>
      </Grid>
      <Grid item className={classes.list}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button className={`${classes.infoEditableItem} ${classes.display}`}>
            <ListItemText
              classes={{ primary: typoClasses.modalLabel }}
              primary={`${t(
                "translation:Templates_Mytemplates.details.templateName"
              )}:`}
              style={{ marginBottom: "0px"}}
            />
            <div
              className="auto-grow-input"
              style={{
                display: "inline-grid",
                alignItems: "center",
                justifyItems: "start",
                border: "none",
                borderRadius: 4,
                // width: "80px"
              }}
            >
              <Input
                id="inputLabel"
                defaultValue={innput}
                inputProps={{ "aria-label": "description" }}
                disableUnderline={true}
                className={classes.inputName}
                onChange={handleSetNewName}
                value={innput} //!!newName ? innput: template.templateName
                style={{
                  gridArea: "1 / 1 / 2 / 2",
                }}
              />
              <span id="inputExtender"
              style={{
                fontFamily: "humanistBD",
                fontSize: "14px",
                gridArea: "1 / 1 / 2 / 2",
                visibility: "hidden",
                marginLeft: "16px",
                whiteSpace: "pre-wrap"
              }}>
                {innput}
              </span>
            </div>
          </ListItem>
          <ListItem button disableRipple={true} className={classes.infoItem}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabel,
                secondary: typoClasses.modalContent,
              }}
              primary={`${t(
                "translation:Templates_Mytemplates.details.detectorType"
              )}: \u00A0`}
              secondary={getDetectorTypeFormatted(template, t)}
            />
          </ListItem>
          <ListItem button disableRipple={true} className={classes.infoItem}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabel,
                secondary: typoClasses.modalContent,
              }}
              primary={`${t(
                "translation:Templates_Mytemplates.details.lastUpload"
              )}: \u00A0`}
              secondary={`${moment
                .tz(template.lastUpload, timeZone)
                .format("DD.MM.YYYY")}`}
            />
          </ListItem>

          <SensorDataComponent detector={template} isSensorSetting={false} />
        </List>
      </Grid>
      <Grid item className={classes.saveButton}>
        <Button
            className={buttonStyles.lightButton}
            onClick={openSendModal}
        >
          {t("translation:common.send")}
        </Button>
      </Grid>
      <Grid item className={classes.saveButton}>
        <Button
          className={buttonStyles.primaryButton14px}
          onClick={closeTemplateDetailsModal}
          disabled={!newName}
        >
          {t("translation:common.save")}
        </Button>
      </Grid>
    </>
  );
};

export default MyTemplateDetails;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import {
  commonParagraph14px,
  commonParagraph,
} from "../../common/styles/typography";
import { min } from "moment-timezone";

const useStyles = makeStyles((theme) =>
  createStyles({
    detectorContainer: {
      maxWidth: "1280px",
      margin: "0 auto",
      justifyContent: "space-between",
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
      },
    },
    actionsBar: {
      display: "flex",
      justifyContent: "flex-start",
      "& button": {
        marginRight: "32px",
        [theme.breakpoints.down("sm")]: {
          marginRight: "2px",
        },
      },
    },
    searchContainer: {
      backgroundColor: colors.secondaryColor,
      borderRadius: "24px",
      padding: "4px 14px 4px 24px",
      marginLeft: "auto",
      marginRight: "120px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "auto",
        marginRight: "auto",
      }
    },
    endAlign: {
      justifyContent: "flex-end",
    },
    iconButton: {
      height: "20px",
      width: "20px",
    },
    tableContainer: {
      margin:0,
      marginTop: theme.spacing(6.5),
      marginBottom: theme.spacing(42.5),
      [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      width:"100%",
      }
    },
    table: {
      minWidth: "700px",
      border: "none",
      "& th": {
        borderBottom: "none",
      },
      "& td": {
        borderBottom: "none",
        [theme.breakpoints.down("sm")]: {
          padding: "6px !important",
        },
      },
      "& tr": {
        borderBottom: "none",
      }
    },
    columnName: {
      color: colors.darkGreyColor,
      fontWeight: 700,
      ...commonParagraph14px,
    },
    headingRow: {
      paddingBottom: "12px",
      backgroundColor: colors.secondaryColor,
    },
    rowContent: {
      // "& td": {
      //   borderBottom: `1px solid ${colors.veryLightGreyColor}`,
      // },
    },
    star: {
      cursor: "default",
    },
    content: {
      ...commonParagraph14px,
      color: colors.blackColor,
      cursor: "default",
      wordBreak: "break-word",
    },
    detectorType: {
      ...commonParagraph14px,
      color: colors.blackColor,
      fontWeight: 700,
      cursor: "default",
      wordBreak: "break-word",
    },
    noSearchBody: {
      width: "100%",
      textAlign: "center",
    },
    title: {
      marginTop: theme.spacing(10),
      paddingBottom: theme.spacing(2),
    },
    infoFavorite: {
      position: "absolute",
      bottom: 0,
      backgroundColor: colors.darkGreyColor,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "25px 120px 47px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: "15px 5px",
      },
      "& img": {
        marginRight: "45px",
        width: "40px",
        height: "40px",
        [theme.breakpoints.down("sm")]: {
          marginRight: "10px",
        },
      },
      "& h3": {
        lineHeight: "36px",
      },
    },
    infoContent: {
      ...commonParagraph,
      color: colors.secondaryColor,
      paddingBottom: "22px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
      },
    },
    buttonWraper: {
      width: "100px",
      marginLeft: "77px",
      height: "48px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
      },
    },
    detectorDetailsContainer: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "536px",
      flexWrap: "nowrap",
      maxHeight: "580px",
      overflowY: "scroll",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      padding: "35px 40px 60px",
    },
    addTemplateCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "536px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      padding: "104px 32px 11px",
    },
    errorContent: {
      paddingTop: "68px",
      borderBottom: "0",
      "& img": {
        width: "40px",
        height: "40px",
        marginBottom: "36px",
      },
    },
    modalSendCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      maxHeight: "580px",
      overflowY: "scroll",
      flexWrap: "nowrap",
      padding: "88px 38px 20px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalStatusCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      maxHeight: "488px",
      overflowY: "scroll",
      padding: "88px 32px 50px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      flexWrap: "nowrap",
    },
    modalParagraph: {
      marginBottom: theme.spacing(6),
      color: colors.darkerGreyColor,
      textAlign: "center",
      ...commonParagraph,
    },
    check: {
      paddingRight: "7px",
      width: "20px",
      height: "21px",
      "& span": {
        height: "21px",
      },
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    macAddressSuffix: {
        fontSize: "initial",
        marginLeft: "18px",
    }
  })
);

export default useStyles;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";
import { commonParagraph, mainHeader } from "../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    forgotPasswordWrapper: {
      //paddingTop: theme.spacing(10),
      //paddingBottom: theme.spacing(11),
      marginBottom: "-6px",
      width: "85%",
      //margin: "0 auto",
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 0,
        //paddingTop: "20px",
          width: "100%",
        padding: "25px 15px",
      },
    },
    title: {
      marginLeft: "100px",
      marginRight: "100px",
      marginTop: "65px",
      paddingBottom: theme.spacing(5.6),
      color: colors.blackColor,
      ...commonParagraph,
    },
    formTitle: {
      ...mainHeader,
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("xs")]: {
        paddingBottom: "20px",
      },
    },
    descriptionContainer: {
      width: "50%",
      "& img" :{
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
          textAlign: "center",
        display:'none',
      },
      },
      loginContainer: {
        width: "50%",
        paddingLeft: "4rem",
          "& h1" :{
            paddingTop: "4rem",
          },
          [theme.breakpoints.down("md")]: {
              width: "100%",
              textAlign: "center",
              "& h1" :{
                paddingTop: "2rem",
              },
          },
      },
    contentWrapper: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        margin: "0 auto",
        paddingTop: "95px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "20px 15px",
      },
    },
    content: {
      width: "55%",
      color: colors.primaryColor,
      ...commonParagraph,
      textAlign: "center",
      paddingBottom: theme.spacing(5),
    },
    textInput: {
      width: "352px",
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      color: colors.primaryColor,
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "0",
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      padding: "10px 24px",
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Roboto, sans-serif",
      "& .MuiOutlinedInput-input": {
        padding: "5px 0",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
    },
    required: {
      color: "red",
    },
    inputLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingTop: "10px",
      alignSelf: "start",
      "& .MuiInputLabel-asterisk": {
        color: "red",
      },
    },
    inputAsterixOnLeftLabel: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingTop: "10px",
      alignSelf: "start",
      paddingLeft: "10px",
      "& .MuiInputLabel-asterisk": {
        color: "red",
        float: "left",
        marginLeft: "-15px"
      },
    },
    error: {
      color: colors.errorRedColor,
      fontSize: "12px",
      lineHeight: "16px",
      marginTop: "8px",
    },
    verticalInputSpacing: {
      paddingLeft: "8px",
      borderLeft: `4px solid transparent`,
      marginLeft: `-12px`,
      minHeight: "88px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    errorBorder: {
      borderLeft: `4px solid ${colors.errorRedColor}`,
    },
    button: {
      width: "352px",
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    image: {
      //paddingLeft: "22px",
      paddingRight: "22px",
      //width: "100%",
    },
  })
);

export default useStyles;

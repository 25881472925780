import GetSensorDescription from "../SensorData/GetSensorDescription";
import i18n from "i18next";
import * as master_dali_json from './default-json/master_dali.json';
import * as master_230V_one_json from './default-json/master_230V_one.json';
import * as master_230V_two_json from './default-json/master_230V_two.json';
import * as secondary_230V_json from './default-json/secondary_230V.json';
import * as secondary_dali_json from './default-json/secondary_dali.json';
import * as comfort_dali_json from './default-json/comfort_dali.json';
import * as comfort_dali_zones_json from './default-json/comfort_dali_zones.json';
import SensorData from "../SensorData/SensorData";


const GetDefaultDescription = (detector:any, isDetectorSetting: boolean):any => {
    const master_230V_one:any = master_230V_one_json;
    const master_230V_two:any = master_230V_two_json;
    const secondary_230V:any = secondary_230V_json;
    const master_dali:any = master_dali_json;
    const secondary_dali:any = secondary_dali_json;
    const comfort_dali:any = comfort_dali_json;
    const comfort_dali_zones:any = comfort_dali_zones_json;
    let defaultDetectors = [master_230V_one, master_230V_two, secondary_230V, master_dali, secondary_dali, comfort_dali]


    //CURRENT DETECTOR
    const family = detector.sensorInformation.detectorFamily;
    const type = detector.sensorInformation.detectorType;
    const outputInfo = detector.sensorInformation.detectorOutputInfo;
    let currentDetector:any =  GetSensorDescription(detector, isDetectorSetting);
    const sensorData = new SensorData(detector)


    //FIND DEFAULT DETECTOR FOR CURRENT CATEGORY
    let defaultDetector:any = detector;
    for(let i=0; i<defaultDetectors.length; i++){
        if(family === defaultDetectors[i].default.sensorInformation.detectorFamily && 
           type === defaultDetectors[i].default.sensorInformation.detectorType && 
           outputInfo === defaultDetectors[i].default.sensorInformation.detectorOutputInfo
        ){
            //DALI COMFORT WITH ZONES CONFIGURED
            if(family === comfort_dali.default.sensorInformation.detectorFamily && sensorData.AreZonesConfigured())
                defaultDetector = comfort_dali_zones.default
            else
                defaultDetector = defaultDetectors[i].default
        }
    }

    
    let defaultDetectorObj:any = GetSensorDescription(defaultDetector, isDetectorSetting)
    //console.log("Default Detector JSON: ", defaultDetector)
    //console.log("Default Detector:", defaultDetectorObj)
      
    //ADD "isDefault" PROPERTY
    if (currentDetector !== undefined) {
        Object.entries(currentDetector).forEach((tab: any, i: number) => {
            const [tabName, properties] = tab;
            //console.log(tabName, properties)
            if (tabName !== "tabs") {
            properties.forEach((item: any, j:number) => {
                let currentValue = item.value.join(", ");

                item.isDefault = false;

                for(const defaultProperty of defaultDetectorObj[tabName]){
                    if(defaultProperty.type == item.type){
                        if(defaultProperty.value.join(", ") == item.value.join(", ")){
                            // console.log("Default Value: ", defaultProperty.value.join(", "))
                            // console.log("Current Value: ", item.value.join(", "))
                            item.isDefault = true;
                            break
                        }
                        
                    }
                }
                //console.error(item.type)
                //console.error(item.value)

                //EDGE CASES
                if (item.type == "standardHvacSwitchOnDelay" && item.value.join(", ").startsWith("2")) item.isDefault = true;
                if (item.type == "standardHvacSwitchOffDelay" && item.value.join(", ").startsWith("10")) item.isDefault = true;
                if (item.type == "foldingDoorsEmpty" || item.type == "foldingDoorWired" || item.type == "adjAreaEmpty" || item.type == "adjArealightLevel") item.isDefault = true;
                if (item.type == "zones") item.isDefault = true;
                
                if( currentValue == i18n.t(`translation:SensorData.settings.notUsed`) ) item.isDefault = true;
                else if(item.type.includes("sector"))
                {
                    item.isDefault = currentValue == i18n.t(`translation:SensorData.common.high`);
                } 
            })}
        })
    }

    //console.log("Default props Detector:", currentDetector)

    return currentDetector;
}

export default GetDefaultDescription;
import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";
import { commonParagraph } from "../../styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    delete: {
      backgroundColor: colors.whiteColor,
      border: `1px solid ${colors.darkGreyColor}`,
      borderRadius: "100px",
      "&.MuiButton-contained:hover": {
        boxShadow: "none",
        backgroundColor: colors.whiteColor,
      },
      textTransform: "none",
      color: colors.primaryColor,
      ...commonParagraph,
      padding: "6px 24px",
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "space-between",
      },
      "& .MuiButton-startIcon": {
        marginRight: "15px",
      },
      minWidth: "144px",
      boxShadow: "none",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        marginRight: "32px",
        minWidth: "auto",
        padding: "6px 12px",
        fontSize: "14px !important",
      },
    },
  })
);

export default useStyles;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";
import { commonParagraph } from "../../../common/styles/typography";
import { CSSProperties } from "react";

const commonContent: CSSProperties = {
  ...commonParagraph,
  color: colors.darkerGreyColor,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    inputName: {
      backgroundColor: colors.veryLightGreyColor,
      borderRadius: "24px",
      padding: "10px 56px 9px 24px",
      fontSize: "14px",
      fontFamily: "Roboto, sans-serif",
      lineHeight: "16px",
      color: colors.primaryColor,
    },
    wrapperButton: {
      width: "352px",
      margin: "0 auto",
      paddingBottom: theme.spacing(4),
    },
    label: {
      ...commonContent,
      paddingBottom: "10px",
    },
    paragraph: {
      ...commonContent,
      marginBottom: theme.spacing(5),
    },
  })
);

export default useStyles;

import React, { LegacyRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { getLocalizedRoute } from "../../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../../common/constants/routes";
import validators from "../../../common/utils/validators";
import DropDownSelect from "../../../common/components/DropDownSelect";
import { register } from "../../../data-services/Auth/AuthService";
import { IRegisterModel } from "../../../models/AuthModel";
import { reCAPTCHASiteKey } from "../../../common/constants/recaptcha";
import useButtonStyles from "../../../common/styles/buttonStyles";
import useStyles from "./Register.style";
import i18n from "../../../i18n";
import { useAuth } from "../../../context/context"
import { useInterval } from "../../../context/interval";
import { ILanguageCountryModel } from "../../../models/LanguageCountryModel";
import { languageCountryList } from "../../../common/constants/countries";
import ShowPassword from "../../../common/components/ShowPassword";
import { LangObjectName } from "../../../common/enums/laguages";
import { appStrings } from "../../../common/services/i18n";

const Register: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const { formatMessage, locale } = useIntl();
  const recaptchaRef: LegacyRef<ReCAPTCHA> = React.createRef();
  const authStore = useAuth();
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [reCaptchaValue, setReCaptchaValue] = useState<string>("");
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isEmailInUse, setIsEmailInUse] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isRecaptcha, setIsRecaptcha] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState(1);
  const [newLocaleCode, setNewLocaleCode] = useState(locale);

  const getLanguageCountryList = (): ILanguageCountryModel[] => {
    return [
      {
        name: t("translation:languages.Select"),
        key: "0",
        languageId: "",
        localeCode: "",
        localCodeGoogle: "en",
        country: "Belgium",
      },
      ...languageCountryList
    ] as ILanguageCountryModel[];
  }

  const languagesAndCountries = getLanguageCountryList();
  let languageCountry = languagesAndCountries.find(c => c.localeCode == authStore?.getCurrentLanguageCode()) ?? languagesAndCountries.find(l => l.localeCode == 'en') as ILanguageCountryModel;
  const [selectedLanguageModel, setSelectedLanguageModel] = useState<ILanguageCountryModel>(languageCountry);

  let history = useHistory();

  useInterval(() => {
    if (selectedLanguageModel.localeCode !== authStore?.getCurrentLanguageCode()) {
      let newLanguageModel = (languagesAndCountries.find(l => l.localeCode == authStore?.getCurrentLanguageCode()) ?? languagesAndCountries.find(l => l.localeCode == 'en')) as ILanguageCountryModel;

      changeLanguage(newLanguageModel)
    }
  }, 300);
  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newName = event.target.value;
    if (newName !== undefined) {
      setFirstName(newName.charAt(0).toUpperCase() + newName.slice(1));
      setIsFirstNameValid(newName.length > 0);
    }
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = event.target.value;
    if (newLastName !== undefined) {
      setLastName(newLastName.charAt(0).toUpperCase() + newLastName.slice(1));
      setIsLastNameValid(newLastName.length > 0);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmailInUse(false);
    const newEmail = event.target.value;
    if (newEmail !== undefined) {
      setEmail(newEmail);
      setIsEmailValid(validators.isEmailValid(newEmail));
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    if (newPassword !== undefined) {
      setPassword(newPassword);
      setIsPasswordValid(validators.isPasswordValid(newPassword));
    }
  };

  const handleFormValidation = () => {
    if (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      password !== undefined && password?.length > 0 &&
      selectedLanguageModel.key !== "0" &&
      isPasswordValid //&&
      //isRecaptcha
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changeLanguage = (language: ILanguageCountryModel | undefined) => {
    language && setSelectedLanguageModel(language);

    if (language && language.localeCode) {
      i18n.changeLanguage(language.localeCode);
      authStore?.updateCurrentLanguageCode(language.localeCode);

      if (language.localCodeGoogle) {
        setSelectedLanguageModel(language);
        setRecaptchaKey(recaptchaKey + 1);
      }
    }
  }

  const selectCountry = (event: React.ChangeEvent<{ value: any }>) => {
    const countryCode = event.target.value;
    const languageCountry = getLanguageCountryList().find((c) => c.key === countryCode);

    if (languageCountry) {
      changeLanguage(languageCountry);

      // Change url when changing the language and the country in the form
      window.history.replaceState(null, '',
      `${getLocalizedRoute(
        AppRoute.Register,
        languageCountry.localeCode,
        formatMessage
        )}`
      )

      setNewLocaleCode(languageCountry.localeCode);
    }
  };

  const handleRecaptcha = (value: any) => {
    setReCaptchaValue(value);
    //console.log("Values is ", !!value);
    setIsRecaptcha(!!value);
  };

  const handleRecaptchaExpiration = () => {
    setIsFormValid(false);

    recaptchaRef.current?.reset();
  };

  const redirectToConfirmation = () => {
    const languageCountry = languagesAndCountries.find((l) => l.key === selectedLanguageModel.key);
    let localeCode = locale;

    if (languageCountry) {
      localeCode = languageCountry.localeCode;
    }
    if (email !== undefined){
      history.push(
        `${getLocalizedRoute(
          AppRoute.RegisterConfirmation,
          localeCode,
          formatMessage
        )}?email=${encodeURIComponent(email)}`
      );
    }
  };

  const createUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (firstName && lastName && email && password) {
        const languageCountry = languagesAndCountries.find((l) => l.key === selectedLanguageModel.key);
        const newUser: IRegisterModel = {
          firstName,
          lastName,
          email,
          password,
          country: languageCountry?.country || "",
          language: languageCountry?.languageId || "",
          browserLanguage: i18n.language,
          reCaptcha: reCaptchaValue,
        };

        const { status } = await register(newUser);

        if (status === "success") {
          redirectToConfirmation();
        }

        if (status === "error_emailInUse" || status === "error_invalidRecaptcha") {
          setIsEmailInUse(true);
          handleRecaptcha(undefined);
          setRecaptchaKey(recaptchaKey + 1);
        }
      }
    } catch (error) {
      handleRecaptcha(undefined);
      recaptchaRef.current?.reset();
      setRecaptchaKey(recaptchaKey + 1);

      return { status: "error", message: "Error" };
    }
  };

  useEffect(() => {
    handleFormValidation();
  });

  const getEmailError = () => {
    if (email === "") {
      return t("translation:common.requiredError");
    }

    if (!isEmailValid) {
      return t("translation:common.emailError");
    }

    if (isEmailInUse) {
      return t("translation:common.emailUsedError");
    }
  };

  const getPasswordError = () => {
    if (password === "") {
      return t("translation:common.requiredError");
    }
    if (!isPasswordValid) {
      return t("translation:common.passwordWeakError");
    }
  };

  const getPrivacyPolicyLink = () => {
      const langCode = authStore?.getCurrentLanguageCode() ?? "en";
      const langObject = LangObjectName[langCode];
      return appStrings[langObject]['privacyPolicyLink'];
  }

  const getPrivacyLinkTextBeforeLink = () => {
    let privacyPolicy = t("translation:register.privacyPolicy");
    let words = t("translation:register.privacyLinkText").split(privacyPolicy);
    return words[0];
  }

  const getPrivacyLinkTextAfterLink = () => {
    let privacyPolicy = t("translation:register.privacyPolicy");
    let words = t("translation:register.privacyLinkText").split(privacyPolicy);
    return words[1];
  }

  return (
    <Grid
      container
      component="form"
      onSubmit={createUser}
      className={classes.registerWrapper}
    >
      <Grid item>
        <Typography className={classes.mainHeader} component="h1">
          {t("translation:register.title")}
        </Typography>
      </Grid>
      <Grid item className={classes.registerItem}>
        <div
          className={`${classes.verticalInputSpacing} ${firstName === "" && !isFirstNameValid ? classes.errorBorder : ""
            } `}
        >
          <InputLabel className={classes.inputLabel}>
            {t("translation:register.firstName")}
            <span className={classes.required}> *</span>
          </InputLabel>
          <TextField
            type="text"
            variant="outlined"
            fullWidth={true}
            focused={false}
            placeholder="First Name"
            className={classes.textInput}
            onChange={handleFirstNameChange}
          />
          {firstName === "" && !isFirstNameValid && (
            <Box>
              <Typography className={classes.error}>
                {t("translation:common.requiredError")}
              </Typography>
            </Box>
          )}
        </div>
      </Grid>
      <Grid item className={classes.registerItem}>
        <div
          className={`${classes.verticalInputSpacing} ${lastName === "" && !isLastNameValid ? classes.errorBorder : ""
            } `}
        >
          <InputLabel className={classes.inputLabel}>
            {t("translation:register.lastName")}
            <span className={classes.required}> *</span>
          </InputLabel>
          <TextField
            type="text"
            variant="outlined"
            fullWidth={true}
            focused={false}
            placeholder="Last Name"
            className={classes.textInput}
            onChange={handleLastNameChange}
          />
          {lastName === "" && !isLastNameValid && (
            <Box>
              <Typography className={classes.error}>
                {t("translation:common.requiredError")}
              </Typography>
            </Box>
          )}
        </div>
      </Grid>
      <Grid item className={classes.registerItem}>
        <div
          className={`${classes.verticalInputSpacing} ${((!!email || email === "") && !isEmailValid) || isEmailInUse
            ? classes.errorBorder
            : ""
            } `}
        >
          <InputLabel className={classes.inputLabel}>
            {t("translation:register.email")}
            <span className={classes.required}> *</span>
          </InputLabel>
          <TextField
            type="email"
            variant="outlined"
            fullWidth={true}
            focused={false}
            placeholder="name@gmail.com"
            className={classes.textInput}
            onChange={handleEmailChange}
          />
          {((!!email || email === "") && !isEmailValid) || isEmailInUse ? (
            <Box>
              <Typography className={classes.error}>
                {getEmailError()}
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </div>
      </Grid>
      <Grid item className={classes.registerItem}>
        <div
          className={`${classes.verticalInputSpacing} ${password === "" && classes.errorBorder
            } `}
        >
          <InputLabel className={classes.inputLabel}>
            {t("translation:register.password")}
            <span className={classes.required}> *</span>
          </InputLabel>
          <TextField
            type={showPassword ? "text" : "password"}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} style = {{marginRight: "-26px"}}>
                    <ShowPassword showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            focused={false}
            placeholder="********"
            className={classes.textInput}
            onChange={handlePasswordChange}
          />
          {(password === "" || !isPasswordValid)   &&  (
            <Box>
              <Typography className={classes.error}>
                {getPasswordError()}
              </Typography>
            </Box>
          )}
        </div>
      </Grid>
      <Grid item className={classes.registerItem}>
        <InputLabel className={classes.inputLabel}>
          {t("translation:register.country") + '/' + t("translation:register.language")}
        </InputLabel>
        <DropDownSelect
          dropdownType="countries"
          selectOptions={getLanguageCountryList()}
          selectedOption={selectedLanguageModel.key}
          handleChangeSelect={selectCountry}
        />
      </Grid>
      {/* <Grid item className={` ${classes.registerItem} ${classes.recaptcha}`}>
        <InputLabel className={classes.inputLabel}>
          <span className={classes.required}>* </span>
          {t("translation:register.requiredFields")}
        </InputLabel>
        <ReCAPTCHA
          key={recaptchaKey}
          ref={recaptchaRef}
          sitekey={reCAPTCHASiteKey}
          onExpired={handleRecaptchaExpiration}
          size="normal"
          hl={selectedLanguageModel.localCodeGoogle}
          onChange={handleRecaptcha}
        />
      </Grid> */}
      <Grid item className={classes.registerItem}>
        <Button
          disabled={!isFormValid}
          type="submit"
          className={
            isFormValid
              ? buttonClasses.primaryButton
              : buttonClasses.inactiveButton
          }
          fullWidth={true}
        >
          {t("translation:register.registerButton")}
        </Button>
      </Grid>
      <Grid item className={classes.registerItem} style={{marginTop: "-25px"}}>
        <p className={classes.privacyText}>
          {/* {getPrivacyLinkTextBeforeLink()} */}
          <a style={{color:"inherit"}} href={getPrivacyPolicyLink()} target="_blank">
            {t("translation:register.privacyLinkText")}
          </a>
          {/* {getPrivacyLinkTextAfterLink()} */}
        </p>
      </Grid>
    </Grid>
  );
};

export default Register;

import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import useTypographyStyles from "../../../common/styles/typography";
import useButtonStyles from "../../../common/styles/buttonStyles";
import useStyles from "./SaveChanges.style";

interface ISaveChanges {
  saveUpdates(): void;
  closeModal(): void;
}

const SaveChanges: React.FC<ISaveChanges> = (props) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);

  const { saveUpdates, closeModal } = props;

  const handleSaveChanges = () => {
    saveUpdates();
    closeModal();
  };

  return (
    <>
      <Grid item>
        <Typography
          align="center"
          component="h3"
          className={typographyClasses.headerThreeBlack}
        >
          {t("translation:Templates_Mytemplates.saveModal.title")}
        </Typography>
        <Typography className={classes.modalParagraph}>
          {t("translation:Templates_Mytemplates.saveModal.content")}
        </Typography>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
          className={buttonClasses.lightButtonWithBorderLargeWidth}
          onClick={closeModal}
        >
          {t("translation:common.cancel")}
        </Button>
      </Grid>
       <Grid item className={classes.buttonsContainer}>
        <Button
          className={buttonClasses.primaryButtonLargeWidth}
          onClick={handleSaveChanges}
        >
          {t("translation:common.yes")}
        </Button>
      </Grid>
    </>
  );
};

export default SaveChanges;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import {
  commonParagraph14px,
  paragraph14pxHumanist,
} from "../../../../common/styles/typography";
import { lightButtonWithBorder } from "../../../../common/styles/buttonStyles";

const useStyles = makeStyles((theme) =>
  createStyles({
    templateActions: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      padding: "16px 0 48px",
      "& button": {
        ...lightButtonWithBorder,
        "&.MuiButton-contained:hover": {
          boxShadow: "none",
          backgroundColor: colors.secondaryColor,
        },
      },
    },
    closeIcon: {
      paddingBottom: "18px",
      alignSelf: "flex-end",
      cursor: "default",
    },
    infoEditableItem: {
      borderBottom: `0.5px solid ${colors.primaryColor}`,
      padding: "7px 0px 7px 24px",
      fontSize: "14px",
    },
    infoItem: {
      borderBottom: `0.5px solid ${colors.primaryColor}`,
      padding: "7px 24px",
      fontSize: "14px",
      cursor: "default",
      "&.MuiListItem-button:hover": {
          backgroundColor: colors.secondaryColor,
      },
    },
    list: {
      width: "100%",
    },
    saveButton: {
      "& button": {
        width: "352px",
      },
      marginTop: theme.spacing(5),
    },
    inputName: {
      marginTop: "16px",
      padding: "1px 16px",
      borderRadius: "24px",
      backgroundColor: colors.veryLightGreyColor,
      ...paragraph14pxHumanist,
      "& input": {
        maxWidth: "380px",
        padding: "8px 0px",
        color: colors.primaryColor,
        ...paragraph14pxHumanist,
      },
      cursor: "default",
    },
    primaryStyle: {
      display: "inline-block",
      fontWeight: 700,
      ...commonParagraph14px,
    },
    secondaryStyle: {
      display: "inline-block",
      color: colors.primaryColor,
      ...paragraph14pxHumanist,
    },
    display: {
      display: "inline-block",
    },
  })
);

export default useStyles;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import { commonParagraph } from "../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalParagraph: {
      marginBottom: theme.spacing(6),
      paddingTop: theme.spacing(2.5),
      color: colors.darkerGreyColor,
      ...commonParagraph,
    },
    buttonsContainer: {
      display: "flex",
      "& button": {
        width: "160px",
        height: "48px",
      },
      width: "80%",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    lightButtonColor: {
      backgroundColor: colors.secondaryColor,
    },
  })
);

export default useStyles;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../common/styles/colors";
import { commonParagraph14px } from "../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    projectsWrapper: {
      margin: "0 auto",
      maxWidth: "1040px",
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        width: "100%",
        marginLeft: "2px",
        marginRight: "2px",
      },
    },
    projectsContainer: {
      paddingTop: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        width: "100%",
        overflowX: "scroll",
      },
    },
    table: {
      overflowX: "scroll",
      minWidth: "600px",
      margin: "40px 0 95px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "20px 0",
        padding: "15px 0",
      },
      "& th": {
        borderBottom: "none",
      },
      "& td": {
        borderBottom: "none",
         [theme.breakpoints.down("sm")]: {
          padding: "6px !important",
        },
      },
      "& tr": {
        borderBottom: `1px solid ${colors.veryLightGreyColor}`,
      },
      "& tr:last-child":{
        borderBottom: "none",
      }
    },
    projectsTableHead: {
      backgroundColor: colors.secondaryColor,
    },
    columnName: {
      color: colors.darkGreyColor,
      fontWeight: 800,
      ...commonParagraph14px,
    },
    projectName: {
      color: colors.blackColor,
      fontWeight: 800,
      ...commonParagraph14px,
      wordBreak: "break-word",
    },
    content: {
      color: colors.blackColor,
      ...commonParagraph14px,
      wordBreak: "break-word",
    },
    check: {
      paddingLeft: "23px",
      width: "20px",
      height: "20px",
      "& span": {
        padding: 0,
      },
    },
    rowContent: {
      cursor: "default",
    },
  })
);

export default useStyles;

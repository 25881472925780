import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import useStyles from "./Delete.style";

interface IDelete {
  openDeleteModal(): void;
  closeModal?(): void;
}

const Delete: React.FC<IDelete> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { openDeleteModal, closeModal } = props;

  const handleClick = () => {
    openDeleteModal();
    closeModal && closeModal();
  };

  return (
    <Button
      variant="contained"
      className={classes.delete}
      startIcon={<img src="/img/garbageRemove.svg" alt="remove" />}
      onClick={handleClick}
    >
      {t("translation:common.delete")}
    </Button>
  );
};

export default Delete;

import React from 'react';

const Check: React.FC = (() => {
    return (
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.91277 9.1569H1.2453C0.559738 9.1569 0 8.59833 0 7.9116V1.2453C0.00117592 0.558562 0.559738 0 1.2453 0H7.9116C8.30318 0 8.67595 0.188147 8.91113 0.502118C9.04636 0.684386 9.00873 0.943089 8.82646 1.07832C8.64655 1.21237 8.38667 1.17592 8.25026 0.993653C8.1703 0.885468 8.04683 0.823145 7.91277 0.823145H1.2453C1.01364 0.823145 0.82432 1.01247 0.82432 1.2453V7.9116C0.82432 8.14443 1.01364 8.33258 1.2453 8.33258H7.9116C8.30318 8.33258 8.33375 7.81164 8.33375 7.65054V5.19287C8.33375 4.96591 8.51719 4.78129 8.74532 4.78129C8.97345 4.78129 9.1569 4.96591 9.1569 5.19287V7.65054C9.1569 8.69123 8.53248 9.1569 7.91277 9.1569Z" fill="#1A1A1A"/>
            <path d="M4.5332 7.54231C4.42854 7.54231 4.32271 7.50233 4.24157 7.42119L1.76743 4.94823C1.60633 4.78713 1.60633 4.52725 1.76743 4.36615C1.92853 4.20505 2.18841 4.20505 2.34951 4.36615L4.53202 6.54866L9.70842 1.37225C9.86953 1.21115 10.1282 1.21115 10.2893 1.37225C10.4504 1.53335 10.4504 1.79323 10.2893 1.95433L4.82247 7.42236C4.74369 7.50115 4.63785 7.54231 4.5332 7.54231Z" fill="#1A1A1A"/>
            <path d="M7.91277 20H1.2453C0.559738 20 0 19.4414 0 18.7547V12.0884C0 11.4017 0.558562 10.8431 1.2453 10.8431H7.9116C8.59833 10.8431 9.1569 11.4017 9.1569 12.0884V18.4948C9.1569 19.5343 8.53248 20 7.91277 20ZM1.2453 11.6662C1.01247 11.6662 0.82432 11.8556 0.82432 12.0884V18.7547C0.82432 18.9875 1.01364 19.1769 1.2453 19.1769H7.9116C8.30318 19.1769 8.33375 18.6547 8.33375 18.4948V12.0884C8.33375 11.8556 8.14443 11.6662 7.9116 11.6662H1.2453Z" fill="#1A1A1A"/>
        </svg>
    );
});

export default Check;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      fontSize: "14px",
      lineHeight: "14px",
      fontFamily: "Roboto, sans-serif",
      minWidth: "234px",
      color: colors.primaryColor,
      backgroundColor: colors.secondaryColor,
      borderRadius: "24px",
      borderWidth: "0",
      "& span": {
        color: colors.primaryColor,
        textTransform: "none",
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0",
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        padding: "14px 56px 12px 24px",
      },
      "& img": {
        width: "30px",
        height: "20px",
      },
      "& .MuiList-padding": {
        paddingTop: "0",
        paddingBottom: 0,
      },
      "& .MuiSelect-select:focus": {
        borderRadius: "24px",
      },
    },
    notchedOutline: {
      borderWidth: "0",
    },
    dropdownList: {
      boxShadow: "none",
      borderRadius: 0,
      "& ul": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    popupSelect: {
      backgroundColor: colors.veryLightGreyColor,
      "&:hover": {
        backgroundColor: colors.lightGreyColor,
      },
      "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
        backgroundColor: colors.lightGreyColor,
      },
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: colors.veryLightGreyColor,
      },
    },
  })
);

export default useStyles;

import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./ChangeEmailStatus.style";
import { changeEmail as changeEmailService } from "../../data-services/Auth/AuthService";
import i18n from "../../i18n";

const ChangeEmailStatus: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation(["translation"]);
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    let query = useQuery();

    const resendEmail = async () => {
        debugger;
        try {
            let email = query.get("email")
            if (email === null) {
                return { status: "error", message: "Missing email" };
            }
            await changeEmailService({
                email: email,
                browserLanguage: i18n.language
            });
        } catch (error) {
            return { status: "error", message: "Error" };
        }
    };

    return (
        <Grid container className={classes.statusWrapper}>
            <Grid item className={classes.itemContainer}>
                <img
                    className={classes.image}
                    src="/img/Detectortoolportal_height_small.jpg"
                    alt="sentRegisterEmail"
                />
            </Grid>
            <Grid item className={classes.contentWrapper}>
                <img src="/img/mailMessage.svg" alt="message" style={{width: "40px"}}/>
                <Typography className={classes.title} component="h3">
                    {t("translation:ChangeEmailConfirmation.title")}
                </Typography>
                <Typography className={classes.content}>
                    {t("translation:ChangeEmailConfirmation.firstContent")}
                </Typography>
                <Typography className={classes.content}>
                    <p>{t("translation:ChangeEmailConfirmation.secondContent")}</p>
                </Typography>
                <Typography className={classes.link} onClick={resendEmail}>
                    {t("translation:ChangeEmailConfirmation.linkTitle")}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ChangeEmailStatus;

const isEmailValid = (email: string): boolean => {
  const regexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regexp.test(email);
};

const containsNumber = (text: string): boolean => {
  return /\d/.test(text);
};

const isPasswordValid = (password: string): boolean => {

  if(password.length < 8) return false; 

  if(!containsNumber(password)) return false; 

  var isSpecialChar = /[^a-zA-Z0-9]/g; // if contain anything else then english alphabet or numbers

  if(!isSpecialChar.test(password)) return false;

  const  isUpperChar = /\p{Lu}/u;  // Does contain uppercase char

  if(!isUpperChar.test(password)) return false;

  const isLowerChar = /\p{Ll}/u; // Does contain lowercase char

  if(!isLowerChar.test(password)) return false;

  return true;

};

export default { isEmailValid, isPasswordValid };




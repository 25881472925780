import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './ProjectHeader.style';


const ProjectHeader: React.FC = (() => {
    const classes = useStyles();
    const { t } = useTranslation(['translation']);

    return (
        <Grid item>
            <Typography className={classes.heading} component="h1">{t('translation:Projects_Myprojects.title')}</Typography>
            <Typography className={classes.content}>{t('translation:Projects_Myprojects.contentHeader')}</Typography>
        </Grid>
    );
});

export default ProjectHeader;

import { IFavoriteDetector } from "../../models/DetectorModel";
import httpService from "../HTTPService";
import {
  IDetectorFavoriteResponse,
  IDetectorNameResponse,
  IDetectorsResponse,
} from "../../models/ResponseModel";
import {
  mapFromDetectorResponseToIDetectorModel,
  IDetectorResponseModel,
} from "./DetectorMapper";
import { AxiosResponse } from "axios";
import SettingsMocks from "../../mocks/Settings.json";
import { IDetectorNameModel } from "../../models/TemplatesModel";

const baseUrl = "Detector";

export const getDetectorByMacAddress = async (
  macAddress: string
): Promise<IDetectorsResponse> => {
  try {
    const url = baseUrl + `/getByMacAddress/${macAddress}`;
    const { data, status } = await httpService.sendMessage("get", url);

    const mock = SettingsMocks as any;
    if (status === 200 && data.length > 0) {
      return {
        status: "success",
        data: data.map((item: IDetectorResponseModel) =>
          mapFromDetectorResponseToIDetectorModel(item)
        ),
        // data: mock.map((item: IDetectorResponseModel) =>
        //   mapFromDetectorResponseToIDetectorModel(item)
        // ),
      };
    }

    return { status: "error" };
  } catch (error) {
    return { status: "error" };
  }
};


export const markAsFavorite = async (
  data: IFavoriteDetector
): Promise<IDetectorFavoriteResponse> => {
  const url = baseUrl + `/MarkAsFavorite`;

  try {
    const { status }: AxiosResponse = await httpService.sendMessage(
      "post",
      url,
      data
    );
    if (status === 200) {
      return { status: "success" };
    }

    return { status: "error" };
  } catch (error) {
    return { status: "error", message: "Error" };
  }
};

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./TemplateHeader.style";

interface ITemplateHeader {
  content: string;
}

const TemplateHeader: React.FC<ITemplateHeader> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);

  const { content } = props;

  return (
    <Grid item className={classes.headerTemplate}>
      <Typography className={classes.heading} component="h1">
        {t("translation:Header_Templates.title")}
      </Typography>
      <Typography className={classes.content}>{content}</Typography>
    </Grid>
  );
};

export default TemplateHeader;

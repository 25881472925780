import { createStyles, makeStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import {
  commonParagraph,
  commonParagraph14px,
} from "../../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    myTemplatesContainer: {
      paddingTop: "32px",
    },
    headingRow: {
      backgroundColor: colors.secondaryColor,
    },
    columnName: {
      color: colors.darkGreyColor,
      fontWeight: 800,
      ...commonParagraph14px,
    },
    table: {
      border: "none",
      tableLayout: "fixed",
      "& th": {
        borderBottom: "none",
      },
      "& td": {
        borderBottom: "none",
        [theme.breakpoints.down("sm")]: {
          padding: "6px !important",
        },
      },
      "& tr": {
        borderBottom: `1px solid ${colors.veryLightGreyColor}`,
      },
      "& tr:last-child":{
        borderBottom: "none",
      },
      [theme.breakpoints.down("sm")]: {
        tableLayout: "auto",
        minWidth: "700px",
      }, 
    },
    check: {
      paddingRight: "7px",
      width: "20px",
      height: "20px",
    },
    favorite: {
      width: "20px",
      height: "20px",
      paddingRight: "10px",
      cursor: "default",
      paddingTop: "22px"
    },
    templateName: {
      ...commonParagraph14px,
      color: colors.blackColor,
      fontWeight: 700,
      cursor: "default",
      wordBreak: "break-word",
    },
    content: {
      ...commonParagraph14px,
      color: colors.blackColor,
      wordBreak: "break-word",
    },
    actionsBar: {
      justifyContent: "space-between",
      margin: "40px 0",
      flexWrap: "nowrap",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        justifyContent: "center",
        margin: "20px 0",
        "& div": {  
          marginTop: "5px",
        },
      },
    },
    actionItems: {
      display: "flex",
      // width: "45%",
      justifyContent: "flex-start",
      "& button": {
        marginRight: "40px",
        [theme.breakpoints.down("sm")]: {
          marginRight: "2px",
        }
      },
    },
    modalDeleteCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      padding: "104px 32px 76px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    templateDetailsContainer: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "536px",
      flexWrap: "nowrap",
      height: "580px",
      overflowY: "scroll",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      padding: "35px 40px 60px",
    },
    modalSaveCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      padding: "104px 32px 76px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalSendCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "580px",
      overflowY: "scroll",
      flexWrap: "nowrap",
      padding: "88px 38px 20px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    modalStatusCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      maxHeight: "488px",
      overflowY: "scroll",
      padding: "88px 32px 50px",
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
      flexWrap: "nowrap",
    },
    modalParagraph: {
      marginBottom: theme.spacing(6),
      color: colors.darkerGreyColor,
      textAlign: "center",
      ...commonParagraph,
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
   },
  })
);

export default useStyles;

import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import useStyles from './ExportModal.style';
import useTypographyStyles from '../../common/styles/typography';
import useButtonStyles from '../../common/styles/buttonStyles';


interface IExportModal {
    title: string;
    closeModal(): void;
    exportPDF?(): void;
    exportExcel?(): void;
}

const ExportModal: React.FC<IExportModal> = ((props) => {
    const classes = useStyles();
    const typographyClasses = useTypographyStyles();
    const buttonClasses= useButtonStyles();

    const { title, closeModal, exportPDF, exportExcel} = props;

    return (
        <>
            <Grid item>
                <Typography align="center" component="h3" className={typographyClasses.headerThreeBlack}>{title}</Typography>
                <Typography className={classes.modalParagraph}>Select the export format</Typography>
            </Grid>
            <Grid item className={classes.exportType}>
                <Button
                    className={buttonClasses.lightButtonWithBorderLargeWidth}
                    fullWidth={true}
                    onClick={closeModal}
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item className={classes.exportType}>
                <Button
                    className={buttonClasses.primaryButton}
                    fullWidth={true}
                    onClick={exportPDF}
                >
                    PDF
                </Button>
            </Grid>
            <Grid item className={classes.exportType}>
                <Button
                    className={buttonClasses.primaryButton}
                    fullWidth={true}
                    onClick={exportExcel}
                >
                    Excel
                </Button>
            </Grid>
        </>
    );
});

export default ExportModal;

import React from "react";

const CheckedBoldBox: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-1"
        width="22"
        height="22"
      >
        <path
          d="M15 1C15 0.2 14.3333 0 14 0H2C0.4 0 0 1.33333 0 2V18C0 19.6 1.33333 20 2 20H18C19.6 20 20 18.6667 20 18V11C20 10.6667 19.8 10 19 10C18.2 10 18 10.6667 18 11V18H2V2H14C14.3333 2 15 1.8 15 1Z"
          fill="#C4C4C4"
          stroke="#1A1A1A"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="1"
          stroke="#1A1A1A"
          strokeWidth="2"
        />
      </g>
      <path
        d="M10.0567 15C9.88834 15 9.71993 14.9336 9.59104 14.7999L4.19263 9.19874C3.93579 8.93225 3.93579 8.49884 4.19263 8.23235C4.44948 7.96586 4.8672 7.96586 5.12404 8.23235L10.0567 13.3503L18.876 4.19987C19.1328 3.93338 19.5505 3.93338 19.8074 4.19987C20.0642 4.46636 20.0642 4.89977 19.8074 5.16626L10.5224 14.7999C10.3945 14.9326 10.2261 15 10.0567 15Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

export default CheckedBoldBox;

import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../styles/colors';
import { commonParagraph } from '../styles/typography';


const useStyles = makeStyles((theme) => createStyles({
    modalParagraph: {
        color: colors.darkerGreyColor,
        ...commonParagraph,
        marginBottom: theme.spacing(5),
    },
    exportType: {
        width: '352px',
        height: '48px',
        marginBottom: theme.spacing(2),
    },
}));

export default useStyles;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, TextField, Box } from "@material-ui/core";
import useTypographyStyles from "../styles/typography";
import useButtonStyles from "../styles/buttonStyles";
import useStyles from "./SendModal.style";

interface ISendModal {
  title: string;
  subTitle?: string;
  content: string;
  emails: string[];
  send(): void;
  setEmails(emails: string[]): void;
  closeModal(): void;
}

const SendModal: React.FC<ISendModal> = (props) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);

  const [duplicateEmails, setDuplicateEmails] = useState(false);

  const { title, subTitle, content, closeModal, send, emails, setEmails } = props;

  const handleChange = (
    event: React.ChangeEvent<{ value: any }>,
    index: number
  ) => {
    setDuplicateEmails(false);
    const values = [...emails];
    values[index] = event.target.value;
    setEmails(values);
  };

  const checkAreDuplicateEmails = () => {
    let r: string[] = [];

    for (let i = 0; i < emails.length; i++) {
      if (r.includes(emails[i])) {
        setDuplicateEmails(true);
        return true;
      }
      r.push(emails[i]);
    }
  };

  const handleAddEmail = () => {
    checkAreDuplicateEmails();
    setEmails(["", ...emails]);
  };

  const removeEmail = (index: number) => {
    setDuplicateEmails(false);
    const values = emails;
    values.splice(index, 1);
    setEmails([...values]);
  };

  const showAddIcon = (index: number) => {
    return emails.length >= 1 && index === 0;
  };

  const handleSend = () => {
    let areDuplicates = checkAreDuplicateEmails();
    if (areDuplicates) {
      return;
    }
    send();
  };

  return (
    <>
      <Grid item>
        <Typography
          align="center"
          component="h3"
          className={typographyClasses.headerThreeBlack}
        >
          {title}
        </Typography>
        { subTitle && <Typography className={classes.modalParagraphSubTitle}>{subTitle}</Typography> }
        <Typography className={classes.modalParagraph}>{content}</Typography>
      </Grid>
      {emails.map((email, index) => {
        return (
          <Grid item className={classes.emailInput} key={index}>
            <TextField
              type="email"
              variant="outlined"
              focused={false}
              className={classes.textInput}
              value={email}
              placeholder="Name@email.com"
              onChange={(e) => {
                handleChange(e, index);
              }}
            />
            {showAddIcon(index) ? (
              <img
                src="/img/IconButton_L_NoOutline_Normal.svg"
                alt="addEmail"
                onClick={handleAddEmail}
              />
            ) : (
              <img
                src="/img/RemoveIcon_L_NoOutline_Remove_Email.svg"
                alt="removeEmail"
                style={{width: "44px"}}
                onClick={() => removeEmail(index)}
              />
            )}
          </Grid>
        );
      })}
      <Grid item className={classes.error}>
        {duplicateEmails && (
          <Box style={{}}>
            <Typography className={classes.error}>
              {`${t("translation:common.emailUsedError")}`}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item className={classes.bottomButton}>
        <Button
            className={buttonClasses.lightButton}
            fullWidth={true}
            onClick={() => { setEmails([""]); closeModal(); } }
        >
          {t("translation:common.cancel")}
        </Button>
      </Grid>
      <Grid item className={classes.bottomButton}>
        <Button
          className={buttonClasses.primaryButton}
          fullWidth={true}
          onClick={handleSend}
        >
          {t("translation:common.send")}
        </Button>
      </Grid>
    </>
  );
};

export default SendModal;

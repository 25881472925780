import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Button,
  InputAdornment,
  Popover,
} from "@material-ui/core";
import i18n from "../../../../i18n";
import { AppLanguage, AppCountries, LangObjectName } from "../../../enums/laguages";
import { appStrings } from "..";
import useStyles from "./LanguageSwitcher.style";
import { useAuth } from "../../../../context/context"
import { AppRoute } from "../../../constants/routes";
import { useInterval } from "../../../../context/interval";

const LanguageSwitcher: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { locale, messages } = useIntl();
  const authStore = useAuth();
  const [currentLanguage, setCurrentLanguage] = useState(authStore?.getCurrentLanguageCode());
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
    authStore?.updateCurrentLanguageCode(code);
    setCurrentLanguage(code);
  };

  useInterval(() => {
    if (currentLanguage !== authStore?.getCurrentLanguageCode()) {
      setCurrentLanguage(authStore?.getCurrentLanguageCode());
    }
  }, 300);

  const displayCurrentLocaleCode = () => {
    return currentLanguage || "en";
  }

  return (
    <>
      <Button
        aria-describedby={id}
        className={classes.select}
        onClick={handleClick}
      >
        {AppCountries[displayCurrentLocaleCode()]}
        <InputAdornment position="end" className={classes.chevronDown}>
          <img src="/img/chevronDown.svg" alt="dropdown" />
        </InputAdornment>
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: "center",
        }}
        className={classes.dropdown}
        getContentAnchorEl={null}
      >
        {Object.keys(AppLanguage).map((lang) => (
          <div key={lang} className={classes.dropdownItem}>
            <NavLink
              className={classes.navlinkDropdownItem}
              onClick={() => {
                changeLanguage(AppLanguage[lang]);
                handleClose();
              }}
              to={getMatchingRoute(AppLanguage[lang])}
            >
              {AppCountries[AppLanguage[lang]]}
            </NavLink>
          </div>
        ))}
      </Popover>
      {/* <FormControl variant="outlined">
        <Select
          value={locale}
          onChange={handleChangeLanguage}
          className={classes.select}
          IconComponent={ChevronDown}
          MenuProps={{
            classes: { paper: classes.dropdownList },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {Object.keys(AppLanguage).map((lang, index) => (
            <MenuItem key={index} value={lang} className={classes.dropdownList}>
              {AppCountries[AppLanguage[lang]]}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </>
  );

  function getMatchingRoute(language: string) {
    const [, route] = pathname.split(locale);
    const routeKey: string = Object.keys(messages).find((key) => messages[key] === route) || "";

    let matchingRoute = "";

    if (routeKey === "" && route.indexOf(AppRoute.Project) !== -1) {
      // current route is like "/locale/project/:id"
      let splitRouteBySlash = pathname.split("/");
      if (splitRouteBySlash.length > 0) {
        let projectId = splitRouteBySlash[splitRouteBySlash.length - 1];
        matchingRoute = "/" + AppRoute.Project + "/" + projectId;
      }
    } else {
      let langObject = LangObjectName[language];
      matchingRoute = appStrings[langObject][routeKey];
    }
    if (query.toString()) {
      return `/${language}` + matchingRoute + '?' + query.toString();
    }

    return `/${language}` + matchingRoute;
  }
};

export default LanguageSwitcher;

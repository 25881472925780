import React from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { getLocalizedRoute } from "../../common/services/i18n/utils/routeLocalization";
import { AppRoute } from "../../common/constants/routes";
import useStyles from "./RegisterStatus.style";
import { IResendRegisterModel } from "../../models/AuthModel";
import { resendRegisterEmail } from "../../data-services/Auth/AuthService";
import i18n from "../../i18n";

const RegisterStatus: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { formatMessage, locale } = useIntl();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  const resendEmail = async () => {
    try {
      const resendData: IResendRegisterModel = {
        email: query.get("email"),
        browserLanguage: i18n.language
      };

      await resendRegisterEmail(resendData);
    } catch (error) {
      return { status: "error", message: "Error" };
    }
  };

  return (
    <Grid container className={classes.statusWrapper}>
      <Grid item className={classes.itemContainer}>
        <img
          className={classes.image}
          src="/img/Detectortoolportal_height_small.jpg"
          alt="sentRegisterEmail"
        />
      </Grid>
      <Grid item className={classes.contentWrapper}>
        <img src="/img/mailMessage.svg" alt="message" style={{width: "40px"}}/>
        <Typography className={classes.title} component="h3">
          {t("translation:REGISTER_CONFIRMATION.title")}
        </Typography>
        <Typography className={classes.content}>
          {t("translation:REGISTER_CONFIRMATION.content")}
        </Typography>
        <Typography className={classes.link} onClick={resendEmail}>
          {t("translation:REGISTER_CONFIRMATION.linkTitle")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RegisterStatus;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import colors from "../../common/styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    homeContainer: {
      width: "100%",
      flexWrap: "nowrap",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignContent: "center",
      },
    },
    descriptionContainer: {
      //.width: "50%",
      maxWidth: "50vw",
      [theme.breakpoints.down("md")]: {
          width: "100vw",
          maxWidth: "100vw",
      },
    },
    descriptionCard: {
      backgroundColor: fade(`${colors.secondaryColor}`, 0.6),
      borderRadius: "0",
      boxShadow: "none",
    },
    alignCenter: {
      width: "57%",
      margin: "0 auto",
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    title: {
      color: colors.darkerGreyColor,
      fontFamily: "VistaSansBold",
      fontSize: "22px",
      lineHeight: "36px",
      marginBottom: theme.spacing(4),
      //paddingTop: theme.spacing(6.5),
    },
    description: {
      color: colors.darkerGreyColor,
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      lineHeight: "28px",
    },
    loginContainer: {
      width: "50%",
      backgroundColor: colors.whiteColor,
      padding: "50px 115px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "50px 15px",
      },
    },
    buttonSize: {
      width: "140px",
      height: "48px",
      marginTop: "32px",
    },
  })
);

export default useStyles;

import { IUser } from "../../models/User";

interface ILoginResponseModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  language: string;
  jwt: string;
  expiresAt: string;
  isSuperUser: boolean;
  impersonatedUserEmail: string | null;
}

export const mapFromLoginResponseToIUserModel = (
  data: ILoginResponseModel
): IUser => ({
  id: data.id,
  email: data.email,
  firstName: data.firstName,
  lastName: data.lastName,
  name: `${data.firstName} ${data.lastName}`,
  country: data.country,
  language: data.language,
  jwt: data.jwt,
  expiresAt: data.expiresAt,
  isSuperUser: data.isSuperUser,
  impersonatedUserEmail: data.impersonatedUserEmail
});

import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import useTypographyStyles from "../styles/typography";
import useButtonStyles from "../styles/buttonStyles";
import useStyles from "./NothingSelectedModal.style";

interface IDelete {
    title: string;
    content: string;
    closeModal(): void;
}

const NothingIsSelectedModal: React.FC<IDelete> = (props) => {
    const classes = useStyles();
    const typographyClasses = useTypographyStyles();
    const buttonClasses = useButtonStyles();
    const { title, content, closeModal } = props;
    const { t } = useTranslation(["translation"]);

    return (
        <>
            <Grid item>
                <Typography
                    align="center"
                    component="h3"
                    className={typographyClasses.headerThreeBlack}
                >
                    {title}
                </Typography>
                <Typography className={classes.modalParagraph}>{content}</Typography>
            </Grid>
            <Grid item className={classes.buttonsContainer}>
                <Button
                    className={buttonClasses.primaryButtonLargeWidth}
                    fullWidth={true}
                    onClick={closeModal}
                >
                    {t("translation:common.ok")}
                </Button>
            </Grid>
        </>
    );
};

export default NothingIsSelectedModal;

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Grid,
  Modal,
  Typography, Button,
} from "@material-ui/core";
import CheckedBoldBox from "../../../../common/components/CheckedBoldBox";
import Send from "../../../../common/components/Actions/Send";
import CheckAll from "../../../../common/components/Actions/CheckAll";
import Sort from "../../../../common/components/Actions/Sort";
import DeleteModal from "../../../../common/components/DeleteModal";
import MyTemplateDetails from "./MyTemplateDetails";
import {
  getTemplateById,
  getTemplates,
  removeTemplate,
  updateTemplate,
} from "../../../../data-services/Templates/TemplatesService";
import { ITemplateModel } from "../../../../models/TemplatesModel";
import SendModal from "../../../../common/components/SendModal";
import { ISendModel, IShareManyModel } from "../../../../models/SendModel";
import { send, shareMany } from "../../../../data-services/common/SendService";
import StatusModal from "../../../../common/components/StatusModal";
import SaveChanges from "../SaveChanges";
import useStyles from "./MyTemplates.style";
import UncheckedboxSVG from "../../../../common/components/UncheckedBox";
import TestExportExcel from './TestExportExcel';
import TestExportPdf from './TestExportPdf';
import {
  getDetectorTypeFormatted,
  getDetectorTypeFormattedForFilterMenu
} from "../../../../data-services/Templates/TemplateMapper";
import { useAuth } from "../../../../context/context"
import { useInterval } from "../../../../context/interval"
import ExportModal from "../../../../common/components/ExportModal";
import Export from "../../../../common/components/Actions/Export";
import { exportDetectorsPDF } from "../../../../common/components/Export/ExportPDF";
import { exportDetectorsExcel } from "../../../../common/components/Export/ExportExcel";
import NothingSelectedModal from "../../../../common/components/NothingSelectedModal";
import useNothingIsSelectedModalStyles from "../../../../common/styles/nothingIsSelectedModalGeneralStyle";
import LoadingSpinner from "../../../../LoadingSpinner";
interface IMyTemplates {
  filtersData(data: any): void;
  selectedMyTemplates: string[];
}

const MyTemplates: React.FC<IMyTemplates> = (props) => {
  const classes = useStyles();
  const nothingIsSelectedModalStyles = useNothingIsSelectedModalStyles();
  const cellAlignment = "left";
  const { t } = useTranslation(["translation"]);

  const { filtersData, selectedMyTemplates } = props;

  const [templates, setTemplates] = useState<ITemplateModel[]>([]);
  const [selectedTemplatesIds, setSelectedTemplatesIds] = useState<string[]>([]);
  const [template, setTemplate] = useState<ITemplateModel>();
  const [templateIdToRemove, setTemplateIdToRemove] = useState("");
  const [templateIdToSave, setTemplateIdToSave] = useState("");
  const [newTemplateName, setNewTemplateName] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openExportNothingIsSelectedModal, setOpenExportNothingIsSelectedModal] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [openSendNothingIsSelectedModal, setOpenSendNothingIsSelectedModal] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);
  const [emailList, setEmailList] = useState([""]);
  const timeZone = moment.tz.guess();
  const authStore = useAuth();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(authStore?.getCurrentLanguageCode())
  const [allSelected, setAllSelected] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [dataLeftToFetch, setDataLeftToFetch] = useState(true);
  const [offset, setOffset] = useState(0);
  const [allDataFetched, setAllDataFetched] = useState(false);

  const pageLimit = 100;

  const contactDetails = t("translation:SensorData.export.contactDetails")
    + ' ' + authStore?.userHistory?.firstName + ', '
    + authStore?.userHistory?.lastName + ', '
    + authStore?.userHistory?.email;

  const handleOpenSendModal = () => {
    if (selectedTemplatesIds.length > 0 || template) {
      setOpenSendModal(true);
      setOpenDetailsModal(false);
    } else {
      setOpenSendNothingIsSelectedModal(true);
    }
  };

  const handleOpenShareManyModal = () => {
    if (selectedTemplatesIds.length > 0) {
      setOpenSendModal(true);
      setTemplate(undefined);
    } else {
      setOpenSendNothingIsSelectedModal(true);
    }
  };

  const handleCloseSendModal = () => {
    setOpenSendModal(false);
    setOpenSendNothingIsSelectedModal(false);
    setTemplate(undefined);
  };

  const handleOpenStatusModal = () => {
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    setEmailList([""]);
    window.location.reload();
  };

  const allMyTemplatesIds = templates.map(
    (template: ITemplateModel) => template.templateSensorId
  );

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    template: ITemplateModel
  ) => {
    if (event.target.checked) {
      fetchTemplateById(template.templateSensorId);
      setTemplate(undefined);

      setSelectedTemplatesIds(
        selectedTemplatesIds.concat(template.templateSensorId)
      );
      // setSelectedTemplatesNames(
      //   selectedTemplatesNames.concat(template.templateName)
      // );
    } else {
      setTemplate(undefined);

      setSelectedTemplatesIds(
        selectedTemplatesIds.filter((id) => id !== template.templateSensorId)
      );
    }
  };

  const getSelectedTemplatesNames = () => {
    if (template) {
      return template.templateName;
    } else {
      return templates
        .filter((template) =>
          selectedTemplatesIds.includes(template.templateSensorId)
        )
        .map((template) => template.templateName)
        .join(", ");
    }
  };

  const myTemplatesSortingOptions = [
    { name: t('translation:common.sortByLastUploadDate'), key: "date" },
    { name: t('translation:common.sortByTemplateName'), key: "name" },
    { name: t('translation:common.sortByDetectorType'), key: "detectorType" },
  ];

  const sortMyTemplatesByName = (myTemplatesToSort: ITemplateModel[]) => {
    setSortedBy("name");
    return myTemplatesToSort.sort((a, b) => {
      if (a.templateName.toUpperCase() < b.templateName.toUpperCase()) {
        return -1;
      }
      if (a.templateName.toUpperCase() > b.templateName.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  };

  const sortMyTemplByDetectorType = (myTemplatesToSort: ITemplateModel[]) => {
    setSortedBy("detectorType");
    return myTemplatesToSort.sort((a, b) => {
      const detectorTypeA = getDetectorTypeFormatted(a, t);
      const detectorTypeB = getDetectorTypeFormatted(b, t);

      if (detectorTypeA.toUpperCase() < detectorTypeB.toUpperCase()) {
        return -1;
      }
      if (detectorTypeA.toUpperCase() > detectorTypeB.toUpperCase()) {
        return 1;
      }
      if (a.templateName.toUpperCase() < b.templateName.toUpperCase()) {
        return -1;
      }
      if (a.templateName.toUpperCase() > b.templateName.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  };

  const sortMyTemplatesByDate = (myTemplatesToSort: ITemplateModel[]) => {
    setSortedBy("date");
    return myTemplatesToSort.sort((a, b) => {
      if (moment(b.lastUpload).isAfter(a.lastUpload)) {
        return 1;
      }
      return -1;
    });
  };

  const sortMyTemplates = (key: string, myTemplates: ITemplateModel[] = []) => {
    let myTemplatesToSort = []
    if (myTemplates.length > 0) {
      myTemplatesToSort = myTemplates;
    }
    else {
      myTemplatesToSort = [...templates];
    }
    setSortedBy(key);

    if (dataLeftToFetch) {
      fetchTemplateData(false, key, 0, pageLimit);
      setOffset(0);
    }
    else {
      switch (key) {
        case "name":
          setTemplates(sortMyTemplatesByName(myTemplatesToSort));
          break;
        case "detectorType":
          setTemplates(sortMyTemplByDetectorType(myTemplatesToSort));
          break;
        default:
          setTemplates(sortMyTemplatesByDate(myTemplatesToSort));
      }
    }
  };

  const fetchTemplateById = async (templateId: string) => {
    if (templates[templates.map(e => e.templateSensorId).indexOf(templateId)].uploadedAt != null) {
      setTemplate(templates[templates.map(e => e.templateSensorId).indexOf(templateId)]);
      return;
    }
    const { data } = await getTemplateById(templateId, false, authStore?.getImpersonatedUserEmail() || null);

    if (data == null) {
      return;
    }

    let myTemplate = data as ITemplateModel;

    templates.splice(templates.map(e => e.templateSensorId).indexOf(myTemplate?.templateSensorId), 1, myTemplate)
    setTemplates(templates);
    setTemplate(myTemplate);
  };

  const fetchTemplateData = async (withFullInfo: boolean = false, sortBy: string = 'name', offset: number = 0, limit: number = 100) => {
    if (!dataLeftToFetch && !withFullInfo) return;

    if (allSelected && withFullInfo) {
      return;
    }

    if (withFullInfo) {
      setAllSelected(true);

      setIsFetching(true);
      setDataLeftToFetch(false);
      offset = 0;
      limit = 9999;
      sortBy = 'none';
    }

    const { data } = await getTemplates(authStore?.getImpersonatedUserEmail() || null, withFullInfo, sortBy, offset, limit);
    if (data) {
      let newData:any = [];
      if (offset > 0) {
        newData = [...templates, ...data];

        if (sortBy == "detectorType") {
          setTemplates(sortMyTemplByDetectorType(newData));
        }
        else {
          setTemplates(newData);
        }
      }
      else {
        newData = data;
         if (withFullInfo) {
          // just sort and set templates
          //sortMyTemplates(sortedBy, newData);
          switch (sortBy) {
            case "name":
              setTemplates(sortMyTemplatesByName(newData));
              break;
            case "detectorType":
              setTemplates(sortMyTemplByDetectorType(newData));
              break;
            default:
              setTemplates(sortMyTemplatesByDate(newData));
          }
         }
         else {
          // The sort by detector type is done on frontend for better performance
          // Avoid an extra JOIN just for the sorting
          if (sortBy == "detectorType") {
            setTemplates(sortMyTemplByDetectorType(newData));
          }
          else {
            setTemplates(newData);
          }
         }
      }

      setIsFetching(false);

      if (withFullInfo) {
        setAllDataFetched(true);
        setSelectedTemplatesIds(newData.map((t: ITemplateModel) => t.templateSensorId));
      }

      if (data.length < pageLimit) {
        setDataLeftToFetch(false);
      }

      filtersData(newData);
    }
  };

  useInterval(() => {
    if (currentLanguageCode !== authStore?.getCurrentLanguageCode()) {
      setCurrentLanguageCode(authStore?.getCurrentLanguageCode());
      filtersData(templates);
    }
  }, 300);

  const handleScroll = (e: any) => {
    if (e.target.documentElement.scrollTop + window.innerHeight + 1 >= e.target.documentElement.scrollHeight)
    {
        setIsFetching(true);
      //fetchData(false, sortedBy, offset, pageLimit);
    }
  };

  useEffect(() => {
    fetchTemplateData(false, sortedBy, 0, 100);
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!dataLeftToFetch) {
      if (allDataFetched) {
        setIsFetching(false);
        return;
      }
      if (allSelected) return;
      setIsFetching(false);
    }
    if (!isFetching){
      return;
    }
    if(templates.length > 0 ){
        setOffset(offset+pageLimit)
        fetchTemplateData(false, sortedBy, offset+pageLimit, pageLimit);
    }
  }, [isFetching]);

  const deleteTemplate = async (sensorSettingId: string): Promise<any> => {
    try {
      const { status } = await removeTemplate(sensorSettingId, authStore?.getImpersonatedUserEmail() || null);
      if (status === "success") {
        fetchTemplateData(false, sortedBy, 0, 100);
        setOffset(0);
        setSelectedTemplatesIds([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [sortedBy, setSortedBy] = useState(myTemplatesSortingOptions[0].key);

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortedBy(event.target.value as string);
  };

  const handleOpenDeleteModal = (id: string) => {
    setTemplateIdToRemove(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setTemplate(undefined);
  };

  const handleOpenSaveModal = (templateSensorId: string) => {
    setTemplateIdToSave(templateSensorId);
    setOpenSaveModal(true);
  };

  const handleCloseSaveModal = () => {
    setTemplateIdToSave("");
    setOpenSaveModal(false);
  };

  const handleOpenDetailsModal = (template: ITemplateModel) => {
    fetchTemplateById(template.templateSensorId);
    setOpenDetailsModal(true);
    setNewTemplateName("");
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setTemplate(undefined);
  };

  const handleOpenExportModal = () => {
    if (selectedTemplatesIds.length > 0) {
      setOpenExportModal(true);
    } else {
      setOpenExportNothingIsSelectedModal(true);
    }

  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
    setOpenExportNothingIsSelectedModal(false);
    setTemplate(undefined);
  };

  const getEmailListWithoutEmptyFields = () => {
    let res = [];
    for (let i = 0; i < emailList.length; ++i) {
      if (emailList[i].length !== 0) {
        res.push(emailList[i]);
      }
    }
    return res;
  };

  const sendTemplate = async (): Promise<any> => {
    let data: ISendModel | undefined;

    if (template) {
      data = {
        emails: getEmailListWithoutEmptyFields(),
        setting: {
          favoriteName: template.templateName,
          bleSensorInformationId: template.bleSensorInformationId,
          userConfigModel: template.userConfigModel,
          sensorProfile: template.sensorProfile,
          zones: template.zones,
          wiredPushButtonModel: template.wiredPushButtonModel,
          daliPushButtonModel: template.daliPushButtonModel,
          blePushButtonModel: template.blePushButtonModel,
          getSetTunableWhiteListModelReply: template.getSetTunableWhiteListModelReply,
          getSetTunableWhitePresetModelReply: template.getSetTunableWhitePresetModelReply,
          daliDeviceDataScenesModelReplies: template.daliDeviceDataScenesModelReplies,
          getSetWirelessLinkFunctionModelReply: template.getSetWirelessLinkFunctionModelReply,
          controlGearDeviceCount: template.controlGearDeviceCount,
          inputDeviceCount: template.inputDeviceCount,
          hvacShortAddressExternalRelay: template.hvacShortAddressExternalRelay,
          cutOffShortAddressExternalRelay: template.cutOffShortAddressExternalRelay,
          daliBusDevices: template.daliBusDevices
        },
      };
    }
    handleCloseSendModal();
    handleCloseDetailsModal();

    try {
      if (data) {
        const { status } = await send(data);
        handleOpenStatusModal();
        status === "success" ? setSendStatus(true) : setSendStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const shareTemplates = async (): Promise<any> => {
    let data: IShareManyModel | undefined;

    if (templates.length > 0) {
      data = {
        emails: getEmailListWithoutEmptyFields(),
        settings: templates
          .filter((template) =>
            selectedTemplatesIds.includes(template.templateSensorId)
          )
          .map((template) => ({
            favoriteName: template.templateName,
            bleSensorInformationId: template.bleSensorInformationId,
            userConfigModel: template.userConfigModel,
            sensorProfile: template.sensorProfile,
            zones: template.zones,
            wiredPushButtonModel: template.wiredPushButtonModel,
            daliPushButtonModel: template.daliPushButtonModel,
            blePushButtonModel: template.blePushButtonModel,
            getSetTunableWhiteListModelReply: template.getSetTunableWhiteListModelReply,
            getSetTunableWhitePresetModelReply: template.getSetTunableWhitePresetModelReply,
            daliDeviceDataScenesModelReplies: template.daliDeviceDataScenesModelReplies,
            getSetWirelessLinkFunctionModelReply: template.getSetWirelessLinkFunctionModelReply,
            controlGearDeviceCount: template.controlGearDeviceCount,
            inputDeviceCount: template.inputDeviceCount,
            hvacShortAddressExternalRelay: template.hvacShortAddressExternalRelay,
            cutOffShortAddressExternalRelay: template.cutOffShortAddressExternalRelay,
            daliBusDevices: template.daliBusDevices
          })),
      };
    }
    handleCloseSendModal();
    handleCloseDetailsModal();

    try {
      if (data) {
        const { status } = await shareMany(data);
        handleOpenStatusModal();
        status === "success" ? setSendStatus(true) : setSendStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusContent = () => {
    if (sendStatus) {
      return (
        <>
          <Grid item>
            <Typography className={classes.modalParagraph}>
              {t("translation:Templates_Mytemplates.sendStatus.successContent")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.modalParagraph}>
              {t("translation:Templates_Mytemplates.sendStatus.successContentInfo")}
            </Typography>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item>
          <Typography className={classes.modalParagraph}>
            {t("translation:Templates_Mytemplates.sendStatus.failedContent")}
          </Typography>
        </Grid>
      );
    }
  };

  const editTemplate = async (
    id: string | undefined,
    newName: string
  ): Promise<any> => {
    try {
      if (id) {
        const { status } = await updateTemplate({ id, name: newName }, authStore?.getImpersonatedUserEmail() || null);
        if (status === "success") {
          fetchTemplateData(false, sortedBy, 0, 100);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveChanges = (templateSensorId: string) => {
    editTemplate(templateSensorId, newTemplateName);
  };

  const getPinCodeStr = (template: ITemplateModel) => {
    if (template.pinCode) {
      return template.pinCode;
    }
    return t("translation:Templates_Mytemplates.details.noPinCode");
  }

  const getTemplatedIdsFromSelectedFilters = () => {
    return templates
      .filter((myTemplates) => {
        if (selectedMyTemplates.length === 0) {
          return true;
        }
        return selectedMyTemplates.includes(
          getDetectorTypeFormattedForFilterMenu(myTemplates, t)
        );
      })
      .map((template: ITemplateModel) => {
        return template.templateSensorId;
      })
  }

  const exportPDF = () => {
    try {
      let selectedTemplates = templates.filter((template) => selectedTemplatesIds.includes(template.templateSensorId));
      exportDetectorsPDF(selectedTemplates, false, contactDetails, "Templates");
    } catch (e) {
      console.error(e);
    }
  };

  const exportExcel = () => {
    try {
      let selectedTemplates = templates.filter((template) => selectedTemplatesIds.includes(template.templateSensorId));
      exportDetectorsExcel(selectedTemplates, false, "Templates");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Modal open={isFetching}>
        <div style={{ top: '50%'}}>
          {
              isFetching
              &&
              <LoadingSpinner />
          }
          </div>
      </Modal>
      <Modal open={openSendModal ? openSendModal : openSendNothingIsSelectedModal} onClose={handleCloseSendModal}>
        <Grid container direction="column" className={openSendModal ? classes.modalSendCard : nothingIsSelectedModalStyles.style}>
          {
            openSendModal &&
            <SendModal
              title={t("translation:Templates_Mytemplates.sendModal.title")}
              subTitle={t("translation:Templates_Mytemplates.sendModal.subTitle")}
              content={t("translation:Templates_Mytemplates.sendModal.content")}
              send={template ? sendTemplate : shareTemplates}
              emails={emailList}
              setEmails={setEmailList}
              closeModal={handleCloseSendModal}
            />
          }
          {
            openSendNothingIsSelectedModal &&
            <NothingSelectedModal
              title={t("translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected")}
              content={t("translation:Templates_Mytemplates.sendModal.nothingIsSelected")}
              closeModal={handleCloseSendModal}
            />
          }
        </Grid>
      </Modal>
      <Modal open={openStatusModal} onClose={handleCloseStatusModal}>
        <Grid container direction="column" className={classes.modalStatusCard}>
          <StatusModal
            retry={false}
            retryMethod={template ? sendTemplate : shareTemplates}
            title={
              sendStatus
                ? t("translation:common.sendStatus.successTitle")
                : t("translation:common.sendStatus.failedTitle")
            }
            content={getStatusContent}
            closeModal={handleCloseStatusModal}
          />
        </Grid>
      </Modal>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <Grid container direction="column" className={classes.modalDeleteCard}>
          <DeleteModal
            title={
              template
                ? t("translation:common.deleteModal.title")
                : t(
                  "translation:Templates_Mytemplates.removeFromMyTemplates.title"
                )
            }
            content={
              template
                ? t("translation:common.deleteModal.content")
                : t(
                  "translation:Templates_Mytemplates.removeFromMyTemplates.content"
                )
            }
            deleteButtonName={
              template
                ? t("translation:common.delete")
                : t("translation:common.yes")
            }
            remove={() => {
              deleteTemplate(templateIdToRemove);
              handleCloseDeleteModal();
            }}
            closeModal={handleCloseDeleteModal}
          />
        </Grid>
      </Modal>
      <Modal open={openSaveModal} onClose={handleCloseSaveModal}>
        <Grid container direction="column" className={classes.modalSaveCard}>
          <SaveChanges
            saveUpdates={() => handleSaveChanges(templateIdToSave)}
            closeModal={handleCloseSaveModal}
          />
        </Grid>
      </Modal>
      <Modal open={openDetailsModal} onClose={handleCloseDetailsModal}>
        <Grid
          container
          direction="column"
          className={classes.templateDetailsContainer}
        >
          {template && (
            <MyTemplateDetails
              template={template}
              closeModal={() => handleCloseDetailsModal()}
              removeTemplate={deleteTemplate}
              openSendModal={handleOpenSendModal}
              newName={newTemplateName}
              setNewName={setNewTemplateName}
              openDeleteModal={() => {
                handleOpenDeleteModal(template.templateSensorId);
              }}
              openSaveModal={() => handleOpenSaveModal(template.templateSensorId)}
            />
          )}
        </Grid>
      </Modal>
      <Grid container className={classes.actionsBar}>
          <Grid item className={classes.actionItems}>
            <CheckAll
              allItemIds={getTemplatedIdsFromSelectedFilters()}
              selectedItemIds={selectedTemplatesIds}
              setSelectedItemsIds={setSelectedTemplatesIds}
              fetchData={() => fetchTemplateData(true)}
            />
            <Send
              openSendModal={handleOpenShareManyModal}
              closeModal={handleCloseSendModal}
            />

            <Export
              openExportModal={handleOpenExportModal}
              closeModal={handleCloseExportModal}
            />
            <Modal open={selectedTemplatesIds.length > 0 ? openExportModal : openExportNothingIsSelectedModal} onClose={handleCloseExportModal}>
              <Grid container direction="column" className={openExportModal ? classes.modalExportCard : nothingIsSelectedModalStyles.style}>
                {
                  openExportModal &&
                  <ExportModal
                    title={t("translation:exportModal.templateTitle")}
                    exportPDF={exportPDF}
                    exportExcel={exportExcel}
                    closeModal={handleCloseExportModal}
                  />
                }
                {
                  openExportNothingIsSelectedModal &&
                  <NothingSelectedModal
                    title={t("translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected")}
                    content={t("translation:Templates_Mytemplates.sendModal.nothingIsSelectedExport")}
                    closeModal={handleCloseExportModal}
                  />
                }
              </Grid>
            </Modal>
          </Grid>

          <Grid item>
            <Sort
              sortItems={sortMyTemplates}
              sortedBy={sortedBy}
              sortingOptions={myTemplatesSortingOptions}
              handleChangeSelect={handleChangeSelect}
            />
          </Grid>
        </Grid>
      <TableContainer className={classes.myTemplatesContainer}>
        <Table className={classes.table} aria-label="simple table">
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '5%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
          </colgroup>
          <TableHead className={classes.headingRow}>
            <TableRow>
              <TableCell className={classes.columnName} style={{ width: "61px" }}></TableCell>
              <TableCell className={classes.columnName} style={{ width: "44px" }}></TableCell>
              <TableCell className={classes.columnName}>
                {t(
                  "translation:Templates_Mytemplates.tableHeadings.templateName"
                )}
              </TableCell>
              <TableCell className={classes.columnName} align={cellAlignment}>
                {t(
                  "translation:Templates_Mytemplates.tableHeadings.detectorType"
                )}
              </TableCell>
              <TableCell className={classes.columnName} align={cellAlignment}>
                {t(
                  "translation:Templates_Mytemplates.tableHeadings.lastUpload"
                )}
              </TableCell>
              <TableCell className={classes.columnName} align={"right"}>
                {t("translation:Templates_Mytemplates.tableHeadings.pinCode")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates
              .filter((myTemplates) => {
                if (selectedMyTemplates.length === 0) {
                  return true;
                }
                return selectedMyTemplates.includes(
                  getDetectorTypeFormattedForFilterMenu(myTemplates, t)
                );
              })
              .map((template: ITemplateModel) => (
                <TableRow key={template.templateSensorId}>
                  <TableCell align={cellAlignment} className={classes.check}>
                    <Checkbox
                      checked={selectedTemplatesIds.some(
                        (id) => id === template.templateSensorId
                      )}
                      onChange={(event) => handleCheck(event, template)}
                      checkedIcon={<CheckedBoldBox />}
                      inputProps={{ "aria-labelledby": "checkbox" }}
                      icon={<UncheckedboxSVG />}
                      disableRipple
                    />
                  </TableCell>
                  <TableCell
                    align={cellAlignment}
                    className={classes.favorite}
                    onClick={() =>
                      handleOpenDeleteModal(template.templateSensorId)
                    }
                  >
                    <img src="/img/removeTemplateBlack.svg" alt="favorite" />
                  </TableCell>
                  <TableCell
                    align={cellAlignment}
                    className={classes.templateName}
                    onClick={() => handleOpenDetailsModal(template)}
                  >
                    {template.templateName}
                  </TableCell>
                  <TableCell className={classes.content} align={cellAlignment}>
                    {getDetectorTypeFormatted(template, t)}
                  </TableCell>
                  <TableCell className={classes.content} align={cellAlignment}>
                    {moment
                      .tz(template.lastUpload, timeZone)
                      .format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell className={classes.content} align={"right"}>
                    {getPinCodeStr(template)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyTemplates;

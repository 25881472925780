const whiteColor = '#FFFFFF';
const blackColor = '#000000';
const primaryColor: string = '#1A1A1A';
const secondaryColor: string = '#F6F6F6';
const veryLightGreyColor: string = '#E5E5E5';
const lightGreyColor: string = '#CCCCCC';
const darkGreyColor: string = '#353535';
const darkerGreyColor: string = '#4C4C4C';
const pinkSwan: string = '#B2B2B2';
const errorRedColor: string = '#FD222C';
const berry: string = '#AB0066';
const grey: string = '#7F7F7F';

export default {
    whiteColor,
    primaryColor,
    secondaryColor,
    veryLightGreyColor,
    lightGreyColor,
    darkGreyColor,
    darkerGreyColor,
    pinkSwan,
    errorRedColor,
    berry,
    blackColor,
    grey,
}
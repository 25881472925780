import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Input, InputLabel, Button } from "@material-ui/core";
import useStyles from "./AddTemplate.style";
import useTypographyStyles from "../../../common/styles/typography";
import useButtonStyles from "../../../common/styles/buttonStyles";

interface IAddTemplate {
  favoriteName: string;
  closeModal: () => void;
  setFavoriteName: (name: string) => void;
  saveTemplate: () => void;
}

const AddTemplate: React.FC<IAddTemplate> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const buttonStyles = useButtonStyles();
  const { t } = useTranslation(["translation"]);

  const { closeModal, favoriteName, setFavoriteName, saveTemplate } = props;

  return (
    <>
      <Grid item>
        <Typography
          align="center"
          component="h3"
          className={typoClasses.headerThreeBlack}
        >
          {t("translation:Detectors_search.addFavorite.title")}
        </Typography>
        <Typography className={classes.paragraph}>
          {t("translation:Detectors_search.addFavorite.content")}
        </Typography>
      </Grid>

      <Grid item className={classes.wrapperButton}>
        <InputLabel className={classes.label}>
          {t("translation:Detectors_search.addFavorite.inputLabel")}
        </InputLabel>
        <Input
          onChange={(e) => setFavoriteName(e.target.value)}
          disableUnderline={true}
          placeholder={t("translation:Detectors_search.addFavorite.inputLabel")}
          className={classes.inputName}
          fullWidth={true}
        />
      </Grid>
      <Grid item className={classes.wrapperButton}>
        <Button
          className={buttonStyles.lightButtonWithBorderLargeWidth}
          fullWidth={true}
          onClick={closeModal}
        >
          {t("translation:common.cancel")}
        </Button>
      </Grid>
      <Grid item className={classes.wrapperButton}>
        <Button
          className={buttonStyles.primaryButtonLargeWidth}
          fullWidth={true}
          disabled={favoriteName.length === 0}
          onClick={saveTemplate}
        >
          {t("translation:Detectors_search.addFavorite.addButton")}
        </Button>
      </Grid>
    </>
  );
};

export default AddTemplate;

import { ILanguage } from "./base-language";

export const sk_SK: ILanguage = {
  /** Routes */
  home: "/home",
  about: "/about",
  projectsMy: "/projects/my",
  detector: "/detector",
  templates: "/templates",
  register: "/register",
  registerConfirmation: "/register/confirmation",
  accountActivated: "/register/accountActivated",
  activationFailed: "/register/activationFailed",
  activationLinkExpired: "/activationLinkExpired",
  404: "/404",
  changeEmailConfirmation: "/changeEmailConfirmation",
  myAccount: "/myAccount",
  forgotPassword: "/login/forgotPassword",
  resetEmailConfirmation: "/login/resetEmailConfirmation",
  resetPassword: "/login/resetPassword/:token",
  changeEmail: "/changeEmail",
  installerView: "/installerView",
  installerViewLoggedIn: "/installerViewLoggedIn",
  project: "/project/:id",

  customerServicesLink: "https://www.niko.eu/sk-sk/pomoc/z%C3%A1kazn%C3%ADcky-servis",
  termsAndConditionsLink: "https://www.niko.eu/sk-sk/pr%C3%A1vnej/v%C5%A1eobecn%C3%A9-obchodn%C3%A9-podmienky",
  privacyPolicyLink: "https://www.niko.eu/sk-sk/pr%C3%A1vnej/privacy-policy"
};

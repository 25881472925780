import {
  ZonesEnum,
  PirEnum,
  CfgSystemCommonFunc0,
  CfgSystemCommonFunc1,
  CfgZoneCommonFunc1,
  EnSystemFunc1,
  EnSystemFunc2,
  EnZoneCommonFunc1,
  CfgSystemDaliFunc2,
  PushButtonCfgInfo,
  PushButtonFunction,
  EnSystemApp7,
  CfgZoneType,
  LightLevelArray
} from "./SensorDataEnums/SensorDataEnum";
import i18n from "i18next";

export default class SensorData {
  public UserConfigModel: any;
  public SensorProfileModel: any;
  public Zones: any;
  public WiredPushButtonModel: any;
  public BlePushButtonModel: any;
  public DaliPushButtonModel: any;
  public SensorInformation: any;
  public HvacShortAddressExternalRelay: any; // 255 not used
  public CutOffShortAddressExternalRelay: any; // 255 not used
  public GetSetTunableWhiteListModelReply: any;
  public GetSetTunableWhitePresetModelReply: any;
  public DaliDeviceDataScenesModelReplies: any;
  public GetSetWirelessLinkFunctionModelReply: any;

  constructor(detectorInfo: any) {
    this.UserConfigModel = JSON.parse(detectorInfo.userConfigModel);
    this.SensorProfileModel = JSON.parse(detectorInfo.sensorProfile);
    this.Zones = detectorInfo.zones ? JSON.parse(detectorInfo.zones) : "";
    this.WiredPushButtonModel = detectorInfo.wiredPushButtonModel
      ? JSON.parse(detectorInfo.wiredPushButtonModel)
      : "";

    this.BlePushButtonModel = detectorInfo.blePushButtonModel
      ? JSON.parse(detectorInfo.blePushButtonModel)
      : "";
    this.DaliPushButtonModel = detectorInfo.daliPushButtonModel
      ? JSON.parse(detectorInfo.daliPushButtonModel)
      : "";
    this.SensorInformation = detectorInfo.sensorInformation;
    this.HvacShortAddressExternalRelay =
      detectorInfo.hvacShortAddressExternalRelay;
    this.CutOffShortAddressExternalRelay =
      detectorInfo.cutOffShortAddressExternalRelay;
    this.GetSetTunableWhiteListModelReply = detectorInfo.getSetTunableWhiteListModelReply
      ? JSON.parse(detectorInfo.getSetTunableWhiteListModelReply)
      : { Hours: [] };
    this.GetSetTunableWhitePresetModelReply = detectorInfo.getSetTunableWhitePresetModelReply
      ? JSON.parse(detectorInfo.getSetTunableWhitePresetModelReply)
      : { Presets: [] };
    this.DaliDeviceDataScenesModelReplies = detectorInfo.daliDeviceDataScenesModelReplies
      ? JSON.parse(detectorInfo.daliDeviceDataScenesModelReplies)
      : [];
    this.GetSetWirelessLinkFunctionModelReply = detectorInfo.getSetWirelessLinkFunctionModelReply ?
      JSON.parse(detectorInfo.getSetWirelessLinkFunctionModelReply) : null;
  }

  public GetLuxLevel(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_V_SETPOINT;
  }

  public GetLightSwitchOnLevel(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_TURNON_LEVEL;
  }

  public GetSwitchAboveLux(zone: ZonesEnum): boolean {
    return this.GetBit(
      this.GetZone(zone).CFG_ZONE_COMMON_FUNC1,
      CfgZoneCommonFunc1.CFG_OVEREXP_ACTIVE
    );
  }

  public GetSwitchOffDelay(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_T_OFFPIR_DELAY;
  }

  public GetLightBehaviour(zone: ZonesEnum): boolean {
    return this.GetBit(
      this.GetZone(zone).CFG_ZONE_COMMON_FUNC1,
      CfgZoneCommonFunc1.CFG_AUTO_ON
    );
  }

  public GetDaylight(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_DAYLIGHT;
  }

  public GetDeskMaxArtificialLight(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_DESKMAXARTIFICIALLIGHT;
  }

  public GetOrientationLightLevel(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_ORIENTATION_LEVEL;
  }

  public GetOrientationLightSwitchOffDelay(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_T_ORIENTATION_DELAY;
  }

  public GetHvacOnTimer(): number {
    return this.UserConfigModel.CFG_T_HVACCON_DELAY;
  }

  public GetHvacOffTimer(): number {
    return this.UserConfigModel.CFG_T_HVACOFF_DELAY;
  }

  public GetStdByOffTimer(): number {
    return this.UserConfigModel.CFG_T_STDBY_MIN_DELAY;
  }

  public GetIntRelayNumber(): number {
    return this.SensorProfileModel.hwProfileInfo.NUM_INTRELAY;
  } //return number of channels

  public GetZonesLengthDali(): number {
    return this.SensorProfileModel.hwProfileInfo.NUM_ZONES;
  } //return number of zones ( Dali)

  public IsInFactoryMode(): boolean {
    return this.UserConfigModel.CFG_SYSTEM_CONFIGSTATE === 0;
  }

  public IsIntRelayOnHvac(): boolean {
    return (
      this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC0 ===
      CfgSystemCommonFunc0.CFG_INT_RELAY_HVAC
    );
  }

  public IsExtHvacRelayUsed(): boolean {
    return this.HvacShortAddressExternalRelay !== 255;
  }

  public GetExtHvacRelay(): number {
    return this.HvacShortAddressExternalRelay;
  }

  public IsIntRelayOnStandBy(): boolean {
    return (
      this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC0 ===
      CfgSystemCommonFunc0.CFG_INT_RELAY_STDBY_MIN
    );
  }

  public IsExtStandbyRelayUsed(): boolean {
    return this.CutOffShortAddressExternalRelay !== 255;
  }

  public GetExtStandbyRelay(): number {
    return this.CutOffShortAddressExternalRelay;
  }

  public IsIntRelayOnLighting(): boolean {
    return (
      this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC0 ===
      CfgSystemCommonFunc0.CFG_INT_RELAY_LIGHTING
    );
  }

  public IsChannel2OnLighting(): boolean {
      return this.UserConfigModel.Zone2.CFG_ZONE_TYPE == CfgZoneType.SEZ;
  }

  public GetSystemRedLedSwitch(): boolean {
    return this.GetBit(
      this.SensorProfileModel.swProfileInfo.EN_SYSTEM_FUNC1,
      EnSystemFunc1.SYSTEM_YGM_INDICATION
    );
  }

  public GetRedLedSwitch(): boolean {
    return this.GetBit(
      this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC1,
      CfgSystemCommonFunc1.CFG_YGM_INDICATION
    );
  }

  public GetBurnInState(): boolean {
    return this.GetBit(
      this.UserConfigModel.CFG_SYSTEM_DALI_FUNC2,
      CfgSystemDaliFunc2.CFG_BURNIN_ACTIVE
    );
  }

  public GetSystemWalkTestState(): boolean {
    return this.GetBit(
      this.SensorProfileModel.swProfileInfo.EN_SYSTEM_FUNC1,
      EnSystemFunc1.SYSTEM_WALK
    );
  }

  public GetSystem8HourTestState(): boolean {
    return this.GetBit(
      this.SensorProfileModel.swProfileInfo.Zone1.EN_ZONE_COMMON_FUNC1,
      EnZoneCommonFunc1.SYSTEM_8HOUR
    );
  }

  public GetWalkTestState(): boolean {
    return this.GetBit(
      this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC1,
      CfgSystemCommonFunc1.CFG_WALKTEST_ACTIVE
    );
  }

  public Get8HourTestState(): boolean {
    return this.GetBit(
      this.UserConfigModel.Zone1.CFG_ZONE_COMMON_FUNC1,
      CfgZoneCommonFunc1.CFG_8HOUR_ACTIVE
    );
  }

  public GetSystemBurnInState(): boolean {
    return this.GetBit(
      this.SensorProfileModel.swProfileInfo.EN_SYSTEM_FUNC2,
      EnSystemFunc2.SYSTEM_BURNIN
    );
  }

  public IsTunableWhiteAvailable(): boolean {
    return this.GetBit(
      this.SensorProfileModel.swProfileInfo.EN_SYSTEM_APP7,
      EnSystemApp7.APP7_FUNCTIONS_TUNABLE_WHITE
    );
  }

  public GetDaylightControlDimming(): boolean {
    return this.GetBit(
      this.UserConfigModel.CFG_SYSTEM_DALI_FUNC2,
      CfgSystemDaliFunc2.CFG_USR_DYN_SP_ACTIVE
    );
  }

  public AreZonesConfigured(): boolean {
    return this.GetBit(
      this.UserConfigModel.CFG_SYSTEM_DALI_FUNC2,
      CfgSystemDaliFunc2.CFG_DALI_CONFIGURED
    );
  }

  public GetPbOperationDuration(): number {
    return this.UserConfigModel.CFG_T_LP;
  }

  public GetDevicesInZone(i: number): number {
    return this.Zones[`DevicesInZone${i}`];
  }

  public GetDevicesInMUZ(): number {
    return this.Zones.DevicesInMUZ;
  }

  public GeOnDelayHVAC(): number {
    return this.UserConfigModel.CFG_T_HVACCON_DELAY;
  }

  public GeOffDelayHVAC(): number {
    return this.UserConfigModel.CFG_T_HVACOFF_DELAY;
  }

  public GetWirelessCommunication(): boolean {
    return this.GetBit(
      this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC1,
      CfgSystemCommonFunc1.CFG_WIRELESS_SLAVE_ENABLED
    );
  }

  public GetWirelessLinkEnabled(): boolean {
    return this.GetBit(
      this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC1,
      CfgSystemCommonFunc1.CFG_WIRELESS_LINK_ENABLED
    );
  }

  public IsChannel2Configured(): boolean {
    try {
      return this.GetBit(
        this.WiredPushButtonModel.PushButton1.PUSH_BUTTON_CFG_INFO,
        PushButtonCfgInfo.ZONE_2_CHANNEL_2
      );
    } catch {
      return true;
    }
  }

  private GetZone(zone: ZonesEnum) {
    switch (zone) {
      case ZonesEnum.Zone1:
        return this.UserConfigModel.Zone1;
      case ZonesEnum.Zone2:
        return this.UserConfigModel.Zone2;
      case ZonesEnum.Zone3:
        return this.UserConfigModel.Zone3;
      case ZonesEnum.Zone4:
        return this.UserConfigModel.Zone4;
      case ZonesEnum.Zone5:
        return this.UserConfigModel.Zone5;
      default:
        return this.UserConfigModel.Zone1;
    }
  }

  public GetSensitivityForElement(pirZone: PirEnum): number {
    switch (pirZone) {
      case PirEnum.ZoneA:
        return this.UserConfigModel.CFG_V_PIRA;
      case PirEnum.ZoneB:
        return this.UserConfigModel.CFG_V_PIRB;
      case PirEnum.ZoneC:
        return this.UserConfigModel.CFG_V_PIRC;
      default:
        throw `Argument out of range: ${pirZone}`;
    }
  }

  public GetNbOfPirElements(): number {
    return this.SensorProfileModel.hwProfileInfo.NUM_PIR;
  }

  public GetNetworkId(): number {
    return this.UserConfigModel.CFG_SYSTEM_NETWORK_ID;
  }

  public GetBit(source: number, parameterBit: number): boolean {
    if ((source & parameterBit) !== 0) return true;
    return false;
  }

  public ReturnSensitivity(level: number): string {
    switch (level) {
      case 0:
        return `${i18n.t("translation:SensorData.common.off")}`;
      case 1:
        return `${i18n.t("translation:SensorData.common.minimum")}`;
      case 2:
        return `${i18n.t("translation:SensorData.common.low")}`;
      case 3:
        return `${i18n.t("translation:SensorData.common.high")}`;
      case 4:
        return `${i18n.t("translation:SensorData.common.maximum")}`;
      default:
        return `${i18n.t("translation:SensorData.common.high")}`;
    }
  }

  public ReturnTime(seconds: number) {
    if (seconds === 1)
      return `${seconds} ${i18n.t("translation:SensorData.common.second")}`;
    else if (seconds < 60)
      return `${seconds} ${i18n.t("translation:SensorData.common.seconds")}`;
    else if (seconds === 60)
      return `1 ${i18n.t("translation:SensorData.common.minute")}`;
    else if (seconds > 60 && seconds % 60 === 0)
      return `${seconds / 60} ${i18n.t(
        "translation:SensorData.common.minutes"
      )}`;
    else
      return `${Math.floor(seconds / 60)} ${seconds / 60 < 2
        ? i18n.t("translation:SensorData.common.minute")
        : i18n.t("translation:SensorData.common.minutes")
        } ${seconds % 60} ${seconds % 60 === 1
          ? i18n.t("translation:SensorData.common.second")
          : i18n.t("translation:SensorData.common.seconds")
        }`;
  }

  public GetChannels() {
    let channels = [];

    for (let i = 1; i <= this.GetIntRelayNumber(); i++) {
      if (i === 1) {
        channels.push({
          type: `zone${i}`,
          label: `${i18n.t("translation:SensorData.channels.channel")} ${i}`,
          value: [],
          style: "h1",
        });
        channels.push({
          type: "luxLevel",
          label: `${i18n.t("translation:SensorData.channels.luxLevel")}:`,
          value: [
            `${this.GetLuxLevel(i)} ${i18n.t(
              "translation:SensorData.common.lux"
            )}`,
          ],
          style: "h3",
        });
        channels.push({
          type: "switchOffAboveLux",
          label: `${i18n.t(
            "translation:SensorData.channels.switchOffAboveLux"
          )}:`,
          value: [
            this.GetSwitchAboveLux(i)
              ? i18n.t("translation:common.yes")
              : i18n.t("translation:common.no"),
          ],
          style: "h3",
        });
        channels.push({
          type: "switchOffDelay",
          label: `${i18n.t("translation:SensorData.channels.switchOffDelay")}:`,
          value: [this.ReturnTime(this.GetSwitchOffDelay(i))],
          style: "h3",
        });
        channels.push({
          type: "movementIsDetected",
          label: `${i18n.t(
            "translation:SensorData.channels.behaviourTriggeredByMovement"
          )}:`,
          value: [
            this.GetLightBehaviour(i)
              ? i18n.t("translation:SensorData.channels.switchTheLightOnAndOff")
              : i18n.t("translation:SensorData.channels.onlySwitchLightOff")
          ],
          style: "h3",
        });
      }
      if (i === 2) {
        if (!this.IsChannel2OnLighting()) {
          channels.push({
            type: `zone${i}`,
            label: `${i18n.t("translation:SensorData.channels.channel")} ${i} ${i18n.t("translation:SensorData.channels.HVAC")}`,
            value: [],
            style: "h1",
          });
          channels.push({
            type: "hvacSwitchOnDelay",
            label: `${i18n.t(
              "translation:SensorData.channels.switchOnDelay"
            )}:`,
            value: [this.ReturnTime(this.GeOnDelayHVAC())],
            style: "h3",
          });
          channels.push({
            type: "hvacswitchOffDelay",
            label: `${i18n.t(
              "translation:SensorData.channels.switchOffDelay"
            )}:`,
            value: [this.ReturnTime(this.GeOffDelayHVAC())],
            style: "h3",
          });
        } else {
          channels.push({
            type: `zone${i}`,
            label: `${i18n.t("translation:SensorData.channels.channel")} ${i} ${i18n.t("translation:SensorData.channels.lightning")}`,
            value: [],
            style: "h1",
          });
          channels.push({
            type: "luxLevel",
            label: `${i18n.t("translation:SensorData.channels.luxLevel")}:`,
            value: [
              `${this.GetLuxLevel(i)} ${i18n.t(
                "translation:SensorData.common.lux"
              )}`,
            ],
            style: "h3",
          });
          channels.push({
            type: "switchOffAboveLux",
            label: `${i18n.t(
              "translation:SensorData.channels.switchOffAboveLux"
            )}:`,
            value: [
              this.GetSwitchAboveLux(i)
                ? i18n.t("translation:common.yes")
                : i18n.t("translation:common.no"),
            ],
            style: "h3",
          });
          channels.push({
            type: "switchOffDelay",
            label: `${i18n.t(
              "translation:SensorData.channels.switchOffDelay"
            )}:`,
            value: [this.ReturnTime(this.GetSwitchOffDelay(i))],
            style: "h3",
          });
          channels.push({
            type: "movementIsDetected",
            label: `${i18n.t(
              "translation:SensorData.channels.behaviourTriggeredByMovement"
            )}:`,
            value: [
              this.GetLightBehaviour(i)
                ? i18n.t("translation:SensorData.channels.switchTheLightOnAndOff")
                : i18n.t("translation:SensorData.channels.onlySwitchLightOff")
            ],
            style: "h3",
          });
        }
      }
    }

    return channels;
  }

  public GetFineTunning() {
    let fineTunning = [];

    fineTunning.push(...this.GetSensitivity())

    return fineTunning;
  }

  public GetPushButtons() {
    let pushButtons = [];

    for (let i = 1; i <= this.GetIntRelayNumber(); i++) {
      const wiredButton = this.WiredPushButtonModel["PushButton" + i];
      if (i === 1) {
        let controlledChannels = this.IsChannel2Configured() ? "1 & 2" : "1";

        pushButtons.push({
          type: `230 V inputs T${i}, Push button (1 action)`,
          //label: `${i18n.t("translation:SensorData.pushButtons.pushButtonName")} T${1}`,
          label: `${i18n.t("translation:SensorData.pushButtons.wiredButton")}${i}, ${i18n.t("translation:SensorData.pushButtons.pushButton")}`,
          value: [],
          style: "h2",
        });
        pushButtons.push({
          type: "action",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.action"
          )}`,
          value: [
            this.ExtractFunctionSimpleButtonString(wiredButton.PUSH_BUTTON_FUNCTION1)
          ],
          style: "h3",
        });
        pushButtons.push({
          type: "controls",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.controls"
          )}`,
          value: [
            `${i18n.t("translation:SensorData.channels.channel")} ${this.GetIntRelayNumber() >= 2 && this.IsChannel2OnLighting()
              ? controlledChannels
              : i
            }`,
          ],
          style: "h3",
        });
      } else if (i === 2 && this.IsChannel2OnLighting()) {
        pushButtons.push({
          type: `230 V inputs T${i}, Push button (1 action)`,
          //label: `${i18n.t("translation:SensorData.pushButtons.pushButtonName"} T${i}`,
          label: `${i18n.t("translation:SensorData.pushButtons.wiredButton")}${i}, ${i18n.t("translation:SensorData.pushButtons.pushButton")}`,
          value: [],
          style: "h2",
        });
        pushButtons.push({
          type: "action",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.action"
          )}`,
          value: [
            this.ExtractFunctionSimpleButtonString(wiredButton.PUSH_BUTTON_FUNCTION1)
          ],
          style: "h3",
        });
        pushButtons.push({
          type: "controls",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.controls"
          )}`,
          value: [`${i18n.t("translation:SensorData.channels.channel")} ${i}`],
          style: "h3",
        });
      }
    }

    return pushButtons;
  }

  public GetCommunication(isSensorSetting: boolean) {
    let communication = [];
    if (!isSensorSetting) return []; // do not show comm information for templates

    communication.push({
      type: "masterSlave",
      label: i18n.t("translation:SensorData.communication.masterSlave"),
      value: [],
      style: "h1",
    });
    communication.push({
      type: "wirelessCommunication",
      label: `${i18n.t(
        "translation:SensorData.communication.wirelessCommunication"
      )}:`,
      value: [
        this.GetWirelessCommunication() === true
          ? i18n.t("translation:common.yes")
          : i18n.t("translation:common.no"),
      ],
      style: "h3",
    });

    return communication;
  }

  public GetSettingsDali(includeMuz = false, isDetectorSetting : boolean) {
    let settings = [];

    const devicesInMuz = () => {
      return `${this.GetDevicesInMUZ()} ${this.GetDevicesInMUZ() === 1
        ? i18n.t("translation:SensorData.common.device")
        : i18n.t("translation:SensorData.common.devices")
        }`;
    };

    if (this.AreZonesConfigured()) {
      let nbZones = 0;
      let zonesSettings = [];
      let generalDetails = "";
      for (let i = 0; i < this.GetZonesLengthDali() && i < 4; i++) {
        if (this.GetDevicesInZone(i + 1) > 0) {
          nbZones++;
          const devices = `${this.GetDevicesInZone(i + 1)} ${this.GetDevicesInZone(i + 1) === 1
            ? i18n.t("translation:SensorData.common.device")
            : i18n.t("translation:SensorData.common.devices")
            }`;
          generalDetails += `${i18n.t(
            "translation:SensorData.fineTunning.zone"
          )} ${i + 1} (${devices}), `;
          if (isDetectorSetting)
          {
            zonesSettings.push({
              type: `zone${i + 1}`,
              label: `${i18n.t("translation:SensorData.fineTunning.zone")} ${i + 1
                }:`,
              value: [devices],
              style: "h2",
            });
          }
          else
          {
            zonesSettings.push({
              type: `zone${i + 1}`,
              label: `${i18n.t("translation:SensorData.fineTunning.zone")} ${i + 1
                }:`,
              value: [],
              style: "h2",
            });
          }
          zonesSettings.push({
            type: "luxLevel",
            label: `${i18n.t("translation:SensorData.channels.luxLevel")}:`,
            value: [
              `${this.GetLuxLevel(i + 1)} ${i18n.t(
                "translation:SensorData.common.lux"
              )}`,
            ],
            style: "h3",
          });
          zonesSettings.push({
            type: "movementIsDetected",
            label: `${i18n.t(
              "translation:SensorData.channels.movementIsDetected"
            )}:`,
            value: [
              `${this.GetLightBehaviour(i + 1)
                ? i18n.t("translation:SensorData.channels.switchTheLightOnAndOff")
                : i18n.t("translation:SensorData.channels.onlySwitchLightOff")
              }`,
            ],
            style: "h3",
          });
          zonesSettings.push({
            type: "switchOffAboveLux",
            label: `${i18n.t(
              "translation:SensorData.channels.switchOffAboveLux"
            )}:`,
            value: [
              `${this.GetSwitchAboveLux(i + 1)
                ? i18n.t("translation:common.yes")
                : i18n.t("translation:common.no")
              }`,
            ],
            style: "h3",
          });
          zonesSettings.push({
            type: "switchOffDelay",
            label: `${i18n.t(
              "translation:SensorData.channels.switchOffDelay"
            )}:`,
            value: [this.ReturnTime(this.GetSwitchOffDelay(i + 1))],
            style: "h3",
          });

          zonesSettings.push({
            type: "orientationLightLevel",
            label: `${i18n.t(
              "translation:SensorData.channels.orientationLightLevel"
            )}:`,
            value: [`${this.GetOrientationLightLevel(i + 1)} %`],
            style: "h3",
          });
          zonesSettings.push({
            type: "orientationLightSwitchOffDelay",
            label: `${i18n.t(
              "translation:SensorData.channels.orientationLightSwitchOffDelay"
            )}:`,
            value: [
              this.ReturnTime(this.GetOrientationLightSwitchOffDelay(i + 1)),
            ],
            style: "h3",
          });
        }
      }

      // console.log("===", isDetectorSetting, includeMuz)
      if (isDetectorSetting && includeMuz) {
        //multiZone
        const multiZoneDevices =
          this.GetDevicesInMUZ() === 0
            ? i18n.t("translation:SensorData.settings.notUsed")
            : devicesInMuz();

        zonesSettings.push({
          type: "MultiZone",
          label: `${i18n.t("translation:SensorData.ZONES.MultiZone")}:`,
          value: [multiZoneDevices],
          style: "h1",
        });

        generalDetails += `${i18n.t(
          "translation:SensorData.ZONES.MultiZone"
        )} (${multiZoneDevices})`;

        settings.push();
      }
      else {
        generalDetails = generalDetails.slice(0, -2); // remove ", "
      }

      if (!isDetectorSetting) generalDetails = `${nbZones} ${i18n.t("Templates_Mytemplates.details.zones")}`;
      zonesSettings = [
        {
          type: "zones",
          label: i18n.t("translation:SensorData.pushButtons.zones"),
          value: [generalDetails],
          style: "h1",
        },
        ...zonesSettings,
      ];

      return zonesSettings;
    } else {
      settings.push({
        type: `zone${1}`,
        label: i18n.t("translation:SensorData.settings.broadcast"),
        value: [],
        style: "h1",
      });
      settings.push({
        type: "luxLevel",
        label: `${i18n.t("translation:SensorData.channels.luxLevel")}:`,
        value: [
          `${this.GetLuxLevel(1)} ${i18n.t(
            "translation:SensorData.common.lux"
          )}`,
        ],
        style: "h3",
      });
      settings.push({
        type: "movementIsDetected",
        label: `${i18n.t(
          "translation:SensorData.channels.behaviourTriggeredByMovement"
        )}:`,
        value: [
          this.GetLightBehaviour(1)
            ? i18n.t("translation:SensorData.channels.switchTheLightOnAndOff")
            : i18n.t("translation:SensorData.channels.onlySwitchLightOff")
        ],
        style: "h3",
      });
      settings.push({
        type: "switchOffAboveLux",
        label: `${i18n.t(
          "translation:SensorData.channels.switchOffAboveLux"
        )}:`,
        value: [
          this.GetSwitchAboveLux(1)
            ? i18n.t("translation:common.yes")
            : i18n.t("translation:common.no"),
        ],
        style: "h3",
      });
      settings.push({
        type: "switchOffDelay",
        label: `${i18n.t("translation:SensorData.channels.switchOffDelay")}:`,
        value: [this.ReturnTime(this.GetSwitchOffDelay(1))],
        style: "h3",
      });
      settings.push({
        type: "orientationLightLevel",
        label: `${i18n.t(
          "translation:SensorData.channels.orientationLightLevel"
        )}:`,
        value: [`${this.GetOrientationLightLevel(1)} %`],
        style: "h3",
      });
      settings.push({
        type: "orientationLightSwitchOffDelay",
        label: `${i18n.t(
          "translation:SensorData.channels.orientationLightSwitchOffDelay"
        )}:`,
        value: [this.ReturnTime(this.GetOrientationLightSwitchOffDelay(1))],
        style: "h3",
      });
      return settings;
    }
  }

  public GetSettingsDaliComfort(isDetectorSetting: boolean) {
    let settings = [];
    const defaultSettings = this.GetSettingsDali(true, isDetectorSetting);

    const getHvacSwitchOnDelay = `${i18n.t(
      "translation:SensorData.settings.hvacSwitchOnDelay"
    )}: ${this.ReturnTime(this.GetHvacOnTimer())}`;

    const getHvacSwitchOffDelay = `${i18n.t(
      "translation:SensorData.settings.switchOffDelay"
    )}: ${this.ReturnTime(this.GetHvacOffTimer())}`;

    const getStandBySwitchOffDelay = `${i18n.t(
      "translation:SensorData.settings.switchOffDelay"
    )}: ${this.ReturnTime(this.GetStdByOffTimer())}`;

    const getHvacSettings = () => {
      if (!isDetectorSetting) return "";

      if (this.IsIntRelayOnHvac()) {
        return i18n.t("translation:SensorData.settings.internalRelay");
      } else if (this.IsExtHvacRelayUsed()) {
        return `${i18n.t(
            "translation:SensorData.settings.externalRelay"
          )} ${this.GetExtHvacRelay()}`;
      } else {
        return i18n.t("translation:SensorData.settings.notUsed");
      }
    };

    const isHvacRelayUsed = () => {
      if (this.IsIntRelayOnHvac() || this.IsExtHvacRelayUsed()) {
        return true;
      }
      return false;
    };

    const getStandBySettings = () => {
      if (!isDetectorSetting) return "";
      if (this.IsIntRelayOnStandBy()) {
        return i18n.t("translation:SensorData.settings.internalRelay");
      } else if (this.IsExtStandbyRelayUsed()) {
        return `${i18n.t(
          "translation:SensorData.settings.externalRelay"
        )} ${this.GetExtStandbyRelay()}`;
      } else {
        return i18n.t("translation:SensorData.settings.notUsed");
      }
    };

    const isStandByRelayUsed = () => {
      if (this.IsIntRelayOnStandBy() || this.IsExtStandbyRelayUsed()) {
        return true;
      }
      return false;
    };

    const getVLightingSettings = () => {
      if (!isDetectorSetting) return "";
      if (this.IsIntRelayOnLighting()) {
        return i18n.t("translation:SensorData.settings.internalRelay");
      } else {
        return i18n.t("translation:SensorData.settings.notUsed");
      }
    };

    const isVLightingRelayUsed = () => {
      if (this.IsIntRelayOnLighting()) {
        return true;
      }
      return false;
    };

    settings.push({
      type: "HVAC",
      label: `${i18n.t("translation:SensorData.channels.HVAC")}:`,
      value: [getHvacSettings()],
      style: "h1",
    });

    if (isHvacRelayUsed() || !isDetectorSetting) {
      settings.push({
        type: "standardHvacSwitchOnDelay",
        label: `${i18n.t(
          "translation:SensorData.settings.hvacSwitchOnDelay"
        )}:`,
        value: [this.ReturnTime(this.GetHvacOnTimer())],
        style: "h3",
      });
      settings.push({
        type: "standardHvacSwitchOffDelay",
        label: `${i18n.t("translation:SensorData.settings.switchOffDelay")}:`,
        value: [this.ReturnTime(this.GetHvacOffTimer())],
        style: "h3",
      });
    }

    settings.push({
      type: "standbyMinimization",
      label: `${i18n.t(
        "translation:SensorData.settings.standbyMinimization"
      )}:`,
      value: [getStandBySettings()],
      style: "h1",
    });
    if (isStandByRelayUsed()  || !isDetectorSetting) {
      settings.push({
        type: "switchOffDelay",
        label: `${i18n.t("translation:SensorData.settings.switchOffDelay")}:`,
        value: [this.ReturnTime(this.GetStdByOffTimer())],
        style: "h3",
      });
    }

    settings.push({
      type: "230VLighting",
      label: `${i18n.t("translation:SensorData.settings.230VLighting")}:`,
      value: [getVLightingSettings()],
      style: "h1",
    });

    if (isVLightingRelayUsed()  || !isDetectorSetting) {
      settings.push({
        type: "luxLevel",
        label: `${i18n.t("translation:SensorData.channels.luxLevel")}:`,
        value: [
          `${this.GetLuxLevel(5)} ${i18n.t(
            "translation:SensorData.common.lux"
          )}`,
        ],
        style: "h3",
      });
      settings.push({
        type: "switchOffAboveLux",
        label: `${i18n.t(
          "translation:SensorData.channels.switchOffAboveLux"
        )}:`,
        value: [
          `${this.GetSwitchAboveLux(5)
            ? i18n.t("translation:common.yes")
            : i18n.t("translation:common.no")
          }`,
        ],
        style: "h3",
      });
      settings.push({
        type: "switchOffDelay",
        label: `${i18n.t("translation:SensorData.channels.switchOffDelay")}:`,
        value: [this.ReturnTime(this.GetSwitchOffDelay(5))],
        style: "h3",
      });
      settings.push({
        type: "movementIsDetected",
        label: `${i18n.t(
          "translation:SensorData.channels.movementIsDetected"
        )}:`,
        value: [
          `${this.GetLightBehaviour(5)
            ? i18n.t("translation:SensorData.channels.switchTheLightOnAndOff")
            : i18n.t("translation:SensorData.channels.onlySwitchLightOff")
          }`,
        ],
        style: "h3",
      });
    }

    return [...defaultSettings, ...settings];
  }

  public GetSensitivity() {
    let letter = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
    ];
    //let checkEqual = <any>[];

    let fineTunning: any = []

    //Sensitivity
    fineTunning.push({
      type: "sensitivity",
      label: `${i18n.t("translation:SensorData.fineTunning.sensitivity")}:`,
      value: [],
      style: "h1",
    });

    let sensitivityFineTunning = <any>[];
    if (this.GetNbOfPirElements() == 3)
      for (let i = 0; i < this.GetNbOfPirElements(); i++) {
        sensitivityFineTunning.push({
          type: `sector${letter[i]}`,
          label: `${i18n.t("translation:SensorData.fineTunning.sector")} ${letter[i]
            }:`,
          value: [this.ReturnSensitivity(this.GetSensitivityForElement(i))],
          style: "h3",
        });
        //checkEqual.push(this.ReturnSensitivity(this.GetSensitivityForElement(i)));
      }

    if (this.GetNbOfPirElements() == 1) {
      fineTunning.push({
        type: "sensitivity",
        label: `${i18n.t("translation:SensorData.fineTunning.sensitivity")}:`,
        value: [this.ReturnSensitivity(this.GetSensitivityForElement(0))],
        style: "h3",
      });
    } else {
      fineTunning = [...fineTunning, ...sensitivityFineTunning];
    }


    return fineTunning;
  }

  public GetFineTunningDali(isDetectorSetting: boolean) {
    let fineTunning = this.GetSensitivity()


    //Daylight control when dimming
    fineTunning.push({
      type: "daylightControlDimming",
      label: `${i18n.t(
        "translation:SensorData.fineTunning.daylightControlDimming"
      )}:`,
      value: [],
      style: "h2",
    });
    fineTunning.push({
      type: "daylightControlDimming",
      label: `${i18n.t(
        "translation:SensorData.fineTunning.daylightControlDimming"
      )}:`,
      value: [
        this.GetDaylightControlDimming()
          ? i18n.t("translation:common.yes")
          : i18n.t("translation:common.no"),
      ],
      style: "h3",
    });

    //Lux level at desk
    if (this.AreZonesConfigured()  && isDetectorSetting) {
      fineTunning.push({
        type: "luxLevelAtDesk",
        label: `${i18n.t("translation:SensorData.fineTunning.luxLevelAtDesk")}:`,
        value: [],
        style: "h1",
      });
      // console.log(this.GetZonesLengthDali())
      for (let i = 1; i <= this.GetZonesLengthDali(); i++) {
        if (this.GetDevicesInZone(i) > 0) {
          fineTunning.push({
            type: `zone${i}`,
            label: `${i18n.t("translation:SensorData.fineTunning.zone")} ${i}`,
            value: [],
            style: "h2",
          });
          fineTunning.push({
            type: "whenTheLightsAreOn",
            label: `${i18n.t(
              "translation:SensorData.fineTunning.whenTheLightsAreOn"
            )}:`,
            value: [
              `${this.GetDaylight(i) + this.GetDeskMaxArtificialLight(i)
              } ${i18n.t("translation:SensorData.common.lux")}`,
            ],
            style: "h3",
          });
          fineTunning.push({
            type: "whenTheLightsAreOff",
            label: `${i18n.t(
              "translation:SensorData.fineTunning.whenTheLightsAreOff"
            )}:`,
            value: [
              `${this.GetDaylight(i)} ${i18n.t(
                "translation:SensorData.common.lux"
              )}`,
            ],
            style: "h3",
          });
        }
      }
    }

    return fineTunning;
  }

  public GetFineTunningDaliSlave() {
    let fineTunning = this.GetSensitivity()
    return fineTunning;
  }

  public GetPresAndHoldTiming = (buttons: any) => {
    buttons.push({
      type: "pushButtonOperation",
      label: `${i18n.t("translation:SensorData.pushButtons.pushButtonOperation")}:`,
      value: [],
      style: "h2",
    });
    buttons.push({
      type: "pushButtonOperationText",
      label: `${i18n.t(
        "translation:SensorData.pushButtons.pushButtonOperationText"
      )}:`,
      value: [
        `${this.GetPbOperationDuration() / 1000} ${this.GetPbOperationDuration() / 1000 === 1
          ? i18n.t("translation:SensorData.common.second")
          : i18n.t("translation:SensorData.common.seconds")
        }`,
      ],
      style: "h3",
    });

    return buttons
  }

  // button Dali Standard
  public GetPushButtonsDali(isDetectorSetting : boolean) {
    let buttons = [];
    if (isDetectorSetting)
    {
      const wiredButton = this.WiredPushButtonModel["PushButton" + 1];
      //pushButton
      buttons.push({
        type: `230 V inputs T${1}, Push button (1 action)`,
        //label: `${i18n.t("translation:SensorData.pushButtons.pushButtonName")} T1`,
        label: `${i18n.t("translation:SensorData.pushButtons.wiredButton")}${1}, ${i18n.t("translation:SensorData.pushButtons.pushButton")}`,
        value: [],
        style: "h1",
      });
      buttons.push({
        type: "action",
        label: `${i18n.t(
          "translation:SensorData.pushButtons.action"
        )}`,
        value: [
          this.ExtractFunctionSimpleButtonString(wiredButton.PUSH_BUTTON_FUNCTION1)
        ],
        style: "h3",
      });
      buttons.push({
        type: "controls",
        label: `${i18n.t(
          "translation:SensorData.pushButtons.controls"
        )}`,
        value: [i18n.t("translation:SensorData.pushButtons.allZones")],
        style: "h3",
      });
    }

    buttons = this.GetPresAndHoldTiming(buttons)
    return buttons;
  }

  public GetButtonActionandControl(pushButtonsData: any) {
    return pushButtonsData.el.PUSH_BUTTON_FUNCTION1 > 0 ? "ON" : "OFF";
  }

  public ExtractFunctionSimpleButtonString(pushButtonFunction: number) {
    switch (pushButtonFunction) {
      case PushButtonFunction.ON_OFF:
        return i18n.t("translation:SensorData.pushButtons.switchOnOff")
      case PushButtonFunction.ONLY_ON:
        return i18n.t("translation:SensorData.common.onlySwitchOn");
      case PushButtonFunction.ONLY_OFF:
        return i18n.t("translation:SensorData.common.onlySwitchOff");
        default:
          return "";
    }
  }

  public ExtractButtonFunctionString(pushButtonFunction: number, pushButtonCfgInfo: number) {
    var isSwitch = this.GetBit(pushButtonCfgInfo, PushButtonCfgInfo.SWITCH)
    switch (pushButtonFunction) {
      case PushButtonFunction.NO_FUNCTION:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.NoAction"
        );
      case PushButtonFunction.ON_OFF:
        return isSwitch ? i18n.t("translation:SensorData.common.off") :
        i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.ONOFFAndDimmingUpDown"
        );
      case PushButtonFunction.ONLY_ON:
        return isSwitch ? i18n.t("translation:SensorData.common.on") : i18n.t(
          "translation:SensorData.common.onlySwitchOn"
        );
      case PushButtonFunction.ONLY_OFF:
        return isSwitch ? i18n.t("translation:SensorData.common.off") : i18n.t(
          "translation:SensorData.common.onlySwitchOff"

        );
      case PushButtonFunction.TW_WARMER:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.DimmingWarmer"
        );
      case PushButtonFunction.TW_COOLER:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.DimmingCooler"
        );
      case PushButtonFunction.TW1:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.TunableWhitePreset"
        );
      case PushButtonFunction.TW2:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.TunableWhitePreset"
        );
      case PushButtonFunction.TW3:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.TunableWhitePreset"
        );
      case PushButtonFunction.TW4:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.TunableWhitePreset"
        );
      case PushButtonFunction.CALL_SCENE:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.LightScene"
        );
      case PushButtonFunction.AUTO:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.ResumeDaylightControl"
        );

      //NOT SURE ABOUT NEXT ONE:
      case PushButtonFunction.LEVEL:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.PresenceLightLevel"
        );
      //..............
      case PushButtonFunction.TWO_LEVEL_ACTIVE:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.NightModeOn"
        );
      case PushButtonFunction.DLC_ACTIVE:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.NightModeOff"
        );
      case PushButtonFunction.FDOOR_OPEN:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.FoldingDoorOpen"
        );
      case PushButtonFunction.FDOOR_CLOSED:
        return i18n.t(
          "translation:SensorData.INPUT_DEVICE_FUNCTION.FoldingDoorClosed"
        );

      default:
        return "";
    }
  }


  public BuildInformationItems(pushButtonSensorData: any, functionId = 1): any {

    let informationItems: any = [];
    if (!this.AreZonesConfigured()) {
      informationItems.push(i18n.t("translation:SensorData.INPUT_DEVICE_FUNCTION.ONOFFAndDimmingUpDown"));
      informationItems.push(i18n.t("translation:SensorData.INPUT_DEVICE_CONTROLS.broadcast"));

    }
    else {
      let functionName = (functionId == 1)
        ? this.ExtractButtonFunctionString(pushButtonSensorData.PUSH_BUTTON_FUNCTION1, pushButtonSensorData.PUSH_BUTTON_CFG_INFO)
        : this.ExtractButtonFunctionString(pushButtonSensorData.PUSH_BUTTON_FUNCTION2, pushButtonSensorData.PUSH_BUTTON_CFG_INFO)
      var functionType = (functionId == 1)
        ? pushButtonSensorData.PUSH_BUTTON_FUNCTION1
        : pushButtonSensorData.PUSH_BUTTON_FUNCTION2

      /*
      if (functionType == PushButtonFunction.TW1
        || functionType == PushButtonFunction.TW2
        || functionType == PushButtonFunction.TW3
        || functionType == PushButtonFunction.TW4
        || functionType == PushButtonFunction.TWO_LEVEL_ACTIVE
        || functionType == PushButtonFunction.DLC_ACTIVE
        || functionType == PushButtonFunction.AUTO
        || functionType == PushButtonFunction.NO_FUNCTION
      ) {
        informationItems.push(functionName);
        informationItems.push("");
      }
      else
      */
      if (functionType == PushButtonFunction.LEVEL) {
        informationItems.push(functionName);
        if (functionId == 2) {
          informationItems.push(`${pushButtonSensorData.Param2}%`);
        }
        else {
          informationItems.push(`${pushButtonSensorData.Param1}%`);
        }
      }
      else if (functionType == PushButtonFunction.CALL_SCENE) {
        informationItems.push(functionName);
        let parameter = Math.round((functionId == 1) ? pushButtonSensorData.Param1 : pushButtonSensorData.Param2) + 1;
        informationItems.push(`${i18n.t("translation:SensorData.functions.lightScene")} ${parameter}`);
      }
      else {
        informationItems.push(functionName);
        let zones = this.GetButtonsControl(pushButtonSensorData.PUSH_BUTTON_CFG_INFO).filter((zone) => zone);
        zones = zones.map((zone) => (zone.includes("Internal relay") ? "230V Lightning" : zone))
        informationItems.push(zones);
      }
      // }
    }

    return informationItems;
  }


  public GetPushButtonCfgInfoTranslation(pushButtonCfgInfo: number) {
    switch (pushButtonCfgInfo) {
      case PushButtonCfgInfo.ZONE_1_CHANNEL_1:
        return i18n.t("translation:SensorData.ZONES.Zone1");
      case PushButtonCfgInfo.ZONE_2_CHANNEL_2:
        return i18n.t("translation:SensorData.ZONES.Zone2");
      case PushButtonCfgInfo.ZONE_3:
        return i18n.t("translation:SensorData.ZONES.Zone3");
      case PushButtonCfgInfo.ZONE_4:
        return i18n.t("translation:SensorData.ZONES.Zone4");
      case PushButtonCfgInfo.MUZ:
        return i18n.t("translation:SensorData.ZONES.MultiZone");
      case PushButtonCfgInfo.ZONE_5_INTERNAL_RELAY:
        return i18n.t("translation:SensorData.ZONES.InternalRelay");

      default:
        return "";
    }
  }

  public GetButtonsControl(buttonControlZones: number): string[] {
    let zones = [];

    for (let zone in PushButtonCfgInfo) {
      if (this.GetBit(buttonControlZones, Number(zone))) {
        zones.push(this.GetPushButtonCfgInfoTranslation(Number(zone)));
      }
    }

    return zones;
  }

  public GetButtonsDaliComfort(isDetectorSetting : boolean) {
    const wiredButtons: any = {};
    const bleButtons: any = {};
    const daliButtons: any = {};
    let pushButtons: any[] = [];
    let count = 0;
    if (isDetectorSetting)
    {
    //wiredButtons
    pushButtons.push({
      type: "wiredButtonsTitle",
      label: `${i18n.t("translation:SensorData.pushButtons.wiredButtonsTitle")}:`,
      value: [],
      style: "h1",
    });
    for (let i = 1; i <= 4; i++) {
      if (!this.WiredPushButtonModel) {
        continue;
      }
      const wiredButton = this.WiredPushButtonModel["PushButton" + i];
      // console.log(`Wired Button ${i}: `, this.BuildInformationItems(wiredButton))
      // console.log(wiredButton.PUSH_BUTTON_CFG_INFO)
      // console.log(PushButtonCfgInfo.SWITCH)
      if (
        this.GetBit(wiredButton.PUSH_BUTTON_CFG_INFO, PushButtonCfgInfo.SWITCH)
      ) {
        let [wiredAction1, wiredControls] = this.BuildInformationItems(wiredButton);
        let [wiredAction2, wiredControls2] = this.BuildInformationItems(wiredButton, 2);

        pushButtons.push({
          type: `230 V inputs T${i}, Switch (2 actions)`,
          label: `${i18n.t(
            "translation:SensorData.pushButtons.wiredButton"
          )}${i}, ${i18n.t(
            "translation:SensorData.pushButtons.switchActions"
          )}`,
          value: [],
          style: "h2",
        });
        pushButtons.push({
          type: "action1",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.action"
          )} 1`,
          value: [wiredAction1],
          style: "h3",
        });
        pushButtons.push({
          type: "controls1",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.controls",
            {
              i,
            }
          )}`,
          value: Array.isArray(wiredControls) ? wiredControls : [wiredControls],
          style: "h3",
        });
        pushButtons.push({
          type: "action2",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.action"
          )} 2`,
          value: [wiredAction2],
          style: "h3",
        });
        pushButtons.push({
          type: "controls2",
          label: `${i18n.t(
            "translation:SensorData.pushButtons.controls",
            {
              i,
            }
          )} `,
          value: Array.isArray(wiredControls2) ? wiredControls2 : [wiredControls2],
          style: "h3",
        });
      } else {
        if (wiredButton.PUSH_BUTTON_FUNCTION1 >= 0) {

          let [wiredAction, wiredControls] = this.BuildInformationItems(wiredButton);
          // console.log(`Button ${i} Action: `, (wiredAction) ? wiredAction : undefined)
          // console.log(`Button ${i} Control: `, wiredControls)
          pushButtons.push({
            type: `230 V inputs T${i}, Push button (1 action)`,
            label: `${i18n.t(
              "translation:SensorData.pushButtons.wiredButton"
            )}${i}, ${i18n.t(
              "translation:SensorData.pushButtons.pushButton"
            )}`,
            value: [],
            style: "h2",
          });
          pushButtons.push({
            type: "action",
            label: `${i18n.t(
              "translation:SensorData.pushButtons.action"
            )}`,
            value: [wiredAction],
            style: "h3",
          });
          pushButtons.push({
            type: "controls",
            label: `${i18n.t(
              "translation:SensorData.pushButtons.controls",
              { i }
            )}`,
            value: Array.isArray(wiredControls) ? wiredControls : [wiredControls],
            style: "h3",
          });
        }
      }
    }

    //bleButtons
    const blePushButtons = <any>[];

    for (let i = 1; i <= 4; i++) {
      if (!this.BlePushButtonModel || !this.BlePushButtonModel["wirelessPushButton" + i]) {
        continue;
      }

      this.BlePushButtonModel["wirelessPushButton" + i].pushButtonsData.forEach(
        (element: any) => {
          if (element.PUSH_BUTTON_FUNCTION1 > 0) {
            count++;

            let buttonKey = `${i18n.t("translation:SensorData.pushButtons.blePushButton")}` + ' '.repeat(count); //Whitespaces - labels will be used as object props, so they have to be different
            let [buttonAction, buttonControls] = this.BuildInformationItems(element)

            blePushButtons.push({
              type: `blePushButton${count}`,
              label: buttonKey,
              value: [],
              style: "h2",
            });
            blePushButtons.push({
              type: "action",
              label: `${i18n.t(
                "translation:SensorData.pushButtons.action",
              )}`,
              value: [buttonAction],
              style: "h3",
            });
            blePushButtons.push({
              type: "controls",
              label: `${i18n.t(
                "translation:SensorData.pushButtons.controls",
                { count }
              )}`,
              value: Array.isArray(buttonControls) ? buttonControls : [buttonControls],
              style: "h3",
            });
          }
        }
      );
    }

    if (blePushButtons.length > 0) {
      pushButtons.push({
        type: "blePushButtonTitle",
        label: `${i18n.t("translation:SensorData.pushButtons.blePushButtonTitle")}:`,
        value: [],
        style: "h1",
      });
    }

    pushButtons = [...pushButtons, ...blePushButtons];

    //daliButtons
    let isAnyPushButtonUsed = false;
    for (let i = 1; i <= 4; i++) {
      if (!this.DaliPushButtonModel) {
        continue;
      }
      const daliButton = this.DaliPushButtonModel["DaliPushButton" + i];
      isAnyPushButtonUsed = daliButton.PushButtons.some(
        (el: any) => el.PushButton.PUSH_BUTTON_FUNCTION1 > 0
      );
      if (isAnyPushButtonUsed) break;
    }

    if (isAnyPushButtonUsed) {
      pushButtons.push({
        type: "daliPushButtonsTitle",
        label: `${i18n.t("translation:SensorData.pushButtons.daliPushButtonsTitle")}:`,
        value: [],
        style: "h1",
      });

      for (let i = 1; i <= 4; i++) {
        if (!this.DaliPushButtonModel) {
          continue;
        }
        const daliButton = this.DaliPushButtonModel["DaliPushButton" + i];
        const isPushButtonUsed = daliButton.PushButtons.some(
          (el: any) => el.PushButton.PUSH_BUTTON_FUNCTION1 > 0
        );

        if (isPushButtonUsed) {
          pushButtons.push({
            type: `daliPushButton${i}`,
            label: `${i18n.t(
              "translation:SensorData.pushButtons.daliPushButton"
            )} ${i}`,
            value: isPushButtonUsed
              ? []
              : [i18n.t("translation:SensorData.settings.notUsed")],
            style: "h2",
          });


          for (let j = 0; j < 4; j++) {
            const pushButton = daliButton.PushButtons[j]["PushButton"];
            let index = j + 1;
            if (pushButton.PUSH_BUTTON_FUNCTION1 > 0) {

              let [inputAction, inputControls] = this.BuildInformationItems(pushButton)
              pushButtons.push({
                type: `input${i}${j}`,
                label: `${i18n.t(
                  "translation:SensorData.pushButtons.input"
                )} ${index}`,
                value: [],
                style: "h3",
              });
              pushButtons.push({
                type: `action${i}${j}`,
                label: `${i18n.t(
                  "translation:SensorData.pushButtons.action",
                )}`,
                value: [inputAction],
                style: "h3",
              });
              pushButtons.push({
                type: `controls${i}${j}`,
                label: `${i18n.t(
                  "translation:SensorData.pushButtons.controls",
                )}`,
                value: Array.isArray(inputControls) ? inputControls : [inputControls],
                style: "h3",
              });
            }
          }
        }
      }
    }
    }

    pushButtons = this.GetPresAndHoldTiming(pushButtons)
    return pushButtons;
  }

  public GetPresenceLightLevel(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_PRESENCE_LEVEL;
  }

  public GetPresenceDelay(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_T_PRESENCE_DELAY;
  }

  public GetNoPresenceLightLevel(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_NON_PRESENCE_LEVEL;
  }

  public GetNoPresenceDelay(zone: ZonesEnum): number {
    return this.GetZone(zone).CFG_ZONE_T_NON_PRESENCE_DELAY;
  }

  public GetFunctionsDaliComfort(isDetectorSetting : boolean) {
    let functionsDaliComfort = [];

    //tunableWhite
    let isHumanCentricLighting =
      this.GetSetTunableWhiteListModelReply.Command === 1
        ? i18n.t("translation:common.yes")
        : i18n.t("translation:common.no");

    let isTunableWhitePresetUsed = this.GetSetTunableWhitePresetModelReply.Presets.some(
      (el: any) => el.Kelvin !== 65535
    );

    const getActiveCurve = (): {
      activeCurveHour: string;
      activeCurveValue: string;
    }[] => {
      let tunableWhiteListByHour: any = [];

      this.GetSetTunableWhiteListModelReply.Hours.map(
        (el: any, index: number) => {
          let activeCurveHour = `${i18n.t(
            "translation:SensorData.common.hour"
          )} ${index + 1}:`;
          let activeCurveValue = "";
          let counter = 0;
          for (const property in el) {
            activeCurveValue += `${el[property]} ${counter === 0
              ? `${i18n.t("translation:SensorData.common.kelvin")}, `
              : `${i18n.t("translation:SensorData.common.lux")}`
              }`;
            counter++;
          }
          tunableWhiteListByHour.push({ activeCurveHour, activeCurveValue });
        }
      );
      return tunableWhiteListByHour;
    };

    const getTunableWhitePreset = (): {
      presetName: string;
      presetValues: string;
    }[] => {
      let preset: any = [];
      if (isTunableWhitePresetUsed) {
        let counter = 0;
        this.GetSetTunableWhitePresetModelReply.Presets.map(
          (el: any, index: number) => {
            if (el.Kelvin !== 65535) {
              counter++;

              preset.push({
                type: `TunableWhitePresetName${counter}`,
                label: `${i18n.t(
                  "translation:SensorData.functions.tunableWhitePreset"
                )} ${counter}`,
                value: [],
                style: "h3",
              })
              preset.push({
                type: `TunableWhitePresetCoulour${counter}`,
                label: `${i18n.t(
                  "translation:SensorData.functions.colourTemperature"
                )}`,
                value: [`${el.Kelvin} ${i18n.t("translation:SensorData.functions.k")}`],
                style: "h3",
              })
              if (el.LuxSetting !== 65535) {
                preset.push({
                  type: `TunableWhitePresetLux${counter}`,
                  label: `${i18n.t(
                    "translation:SensorData.functions.luxLevel"
                  )}`,
                  value: [`${el.LuxSetting} ${i18n.t("translation:SensorData.functions.lux")}`],
                  style: "h3",
                })
              }
              else {
                preset.push({
                  type: `TunableWhitePresetLux${counter}`,
                  label: `${i18n.t(
                    "translation:SensorData.functions.luxLevel"
                  )}`,
                  value: [`${i18n.t("translation:SensorData.functions.tunableWhitePresetFollowZone")}`],
                  style: "h3",
                })
              }

            }
          }
        );
      }
      return preset;
    };

    const base64ToArray = (base64: string) => {
      let binary_string = window.atob(base64);
      let len = binary_string.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }

      return bytes.buffer;
    };

    const getDecivesAndLuxLevelsForScene = (sceneNumber: number) => {
      let devicesAndLuxLevels = {} as { [index: number]: number };

      let daliDevicesScenes = this.DaliDeviceDataScenesModelReplies;
      for (let i = 0; i < daliDevicesScenes.length; i++) {
        const daliDeviceLuxLevel = new Uint8Array(
          base64ToArray(daliDevicesScenes[i].Scenes)
        );

        if (daliDeviceLuxLevel[sceneNumber] !== 255) {
          devicesAndLuxLevels[i + 1] = daliDeviceLuxLevel[sceneNumber];
        }
      }

      return devicesAndLuxLevels;
    };

    const GetLightSceneLuxLevelInterval = (valueStr: string) =>
    {
      var value = parseInt(valueStr)
      var minPercent = LightLevelArray.indexOf(value);
      var maxPercent =  LightLevelArray.lastIndexOf(value);
      // console.log("Lux levels", LightLevelArray, value, minPercent, maxPercent)
      return minPercent == maxPercent ? `${minPercent}` :  `${minPercent} - ${maxPercent}`
    }

    const getNumberOfFoldingDoors = () => {
      // console.log(this.GetSetWirelessLinkFunctionModelReply.NetworkIdList)
      const networkIdList = new Uint8Array(
        base64ToArray(this.GetSetWirelessLinkFunctionModelReply.NetworkIdList)
      );
      //console.log(networkIdList)
      let fDoors = -1;
      if (networkIdList.length) while(networkIdList[fDoors+1] != 254) fDoors++;
      return fDoors;
    }

    const getNumberOfAdjAreasDetectors = () => {
      // console.log(this.GetSetWirelessLinkFunctionModelReply.NetworkIdList)
      const networkIdList = new Uint8Array(
        base64ToArray(this.GetSetWirelessLinkFunctionModelReply.NetworkIdList)
      );
      //console.log(networkIdList)
      let nbDetectors = 0;
      if (networkIdList.length) while(networkIdList[nbDetectors] != 254) nbDetectors++;
      //console.log(nbDetectors)
      return nbDetectors+1;
    }

    const isFoldingDoorWired = () => {
      return this.GetSetWirelessLinkFunctionModelReply.Parameter == 1;
    }

    const get230vInputsWiredToFoldingDoor = () => {
      let V230Inputs = "";
      if (isFoldingDoorWired())
      {
        for (let i = 1; i <= 4; i++) {
          const wiredButton = this.WiredPushButtonModel["PushButton" + i];
          if (wiredButton.PUSH_BUTTON_FUNCTION1 == PushButtonFunction.FDOOR_OPEN || wiredButton.PUSH_BUTTON_FUNCTION1 == PushButtonFunction.FDOOR_CLOSED)
            V230Inputs = V230Inputs + "T" + i + ",";
        }
      }
      V230Inputs == "" ? V230Inputs = `${i18n.t("translation:common.no")}` : V230Inputs = V230Inputs.slice(0,-1)
      // console.log(V230Inputs)
      return V230Inputs;
    }

    let presenceLightLevel = this.GetPresenceLightLevel(1);
    let presenceDelay = this.ReturnTime(this.GetPresenceDelay(1));
    let noPresenceLightLevel = this.GetNoPresenceLightLevel(1);
    const noPresenceDelay =
      this.GetNoPresenceDelay(1) === 65535
        ? i18n.t("translation:SensorData.common.never")
        : this.ReturnTime(this.GetNoPresenceDelay(1));


    if (this.IsTunableWhiteAvailable()) {
      functionsDaliComfort.push({
        type: "tunableWhite",
        label: `${i18n.t("translation:SensorData.functions.tunableWhite")}:`,
        value: [],
        style: "h2",
      });
      functionsDaliComfort.push({
        type: "humanCentricLighting",
        label: `${i18n.t(
          "translation:SensorData.functions.humanCentricLighting"
        )}:`,
        value: [isHumanCentricLighting],
        style: "h3",
      });


      if (this.GetSetTunableWhiteListModelReply.Command === 1) {
        functionsDaliComfort.push({
          type: "activeCurve",
          label: `${i18n.t("translation:SensorData.functions.activeCurve")}:`,
          value: [],
          style: "h2",
        });


        const activeCurve = getActiveCurve();
        activeCurve.forEach((element: any, index: number) => {
          functionsDaliComfort.push({
            type: `activeCurveHour${index}`,
            label: element.activeCurveHour,
            value: [element.activeCurveValue],
            style: "h3",
          });
        });
      }


      if (!isTunableWhitePresetUsed) {
        if (isDetectorSetting)
          functionsDaliComfort.push({
            type: "tunableWhiteTitle",
            label: `${i18n.t(
              "translation:SensorData.functions.tunableWhiteTitle"
            )}:`,
            value: [i18n.t("translation:SensorData.settings.notUsed")],
            style: "h1",
          });
      } else {
        functionsDaliComfort.push({
          type: "tunableWhiteTitle",
          label: `${i18n.t("translation:SensorData.functions.tunableWhiteTitle")}:`,
          value: [],
          style: "h1",
        });
        const tunablePreset = getTunableWhitePreset();
        // tunablePreset.forEach((element: any, index: number) => {

        // });
        functionsDaliComfort.push(...tunablePreset)
      }
    }

    //nightMode
    if (this.AreZonesConfigured()) {
      functionsDaliComfort.push({
        type: "nightMode",
        label: `${i18n.t("translation:SensorData.functions.nightMode")}:`,
        value: [],
        style: "h2",
      });
      functionsDaliComfort.push({
        type: "presenceLightLevel",
        label: `${i18n.t(
          "translation:SensorData.functions.presenceLightLevel"
        )}:`,
        value: [`${presenceLightLevel}%`],
        style: "h3",
      });
      functionsDaliComfort.push({
        type: "switchToNopresenceLightLevel",
        label: `${i18n.t(
          "translation:SensorData.functions.switchToNopresenceLightLevel"
        )}:`,
        value: [presenceDelay],
        style: "h3",
      });
      functionsDaliComfort.push({
        type: "noPresenceLightLevel",
        label: `${i18n.t(
          "translation:SensorData.functions.noPresenceLightLevel"
        )}:`,
        value: [`${noPresenceLightLevel}%`],
        style: "h3",
      });
      functionsDaliComfort.push({
        type: "switchLightsOff",
        label: `${i18n.t("translation:SensorData.functions.switchLightsOff")}:`,
        value: [noPresenceDelay],
        style: "h3",
      });
    }

    const lightScenesAvailable = () => {
      for (let i = 0; i < 16; i++) {
        let scene = getDecivesAndLuxLevelsForScene(i);
        //console.log("Scene",scene)
        if (Object.keys(scene).length !== 0 && !Object.entries(scene).some((entry: any) => entry[1] === undefined)) {
          return true;
        }
      }
    }
    //lightScene
    if (isDetectorSetting && this.DaliDeviceDataScenesModelReplies.length > 0) {

      if (lightScenesAvailable())
      {
        functionsDaliComfort.push({
          type: "lightScenes",
          label: `${i18n.t("translation:SensorData.functions.lightScenes")}:`,
          value: [],
          style: "h1",
        });
      }

      // console.log(this.DaliDeviceDataScenesModelReplies)
      for (let i = 0; i < 16; i++) {
        let scene = getDecivesAndLuxLevelsForScene(i);
        //console.log("Scene",scene)
        if (Object.keys(scene).length !== 0 && !Object.entries(scene).some((entry: any) => entry[1] === undefined)) {
          let differentValues: any = {};
          let value: any = [];

          for (const prop in scene) {
            if (differentValues[scene[prop]])
              differentValues[scene[prop]] += 1;
            else
              differentValues[scene[prop]] = 1;
          }//Get number of luminaires and lux level

          //console.log("differentValues", differentValues)
          Object.entries(differentValues).reverse().forEach((val: any, i: number) => {
            value.push(`${i18n.t("translation:SensorData.functions.group")} ${i + 1}:  ${GetLightSceneLuxLevelInterval(val[0])}%`);
            value.push((val[1] > 1) ? `${val[1]} ${i18n.t("translation:SensorData.functions.luminaires")}` : `${val[1]} ${i18n.t("translation:SensorData.functions.luminaire")}`);
          })//Create value array

          if (this.DaliDeviceDataScenesModelReplies.length > Object.keys(scene).length) {
            let diff = this.DaliDeviceDataScenesModelReplies.length - Object.keys(scene).length;
            value.push(`${i18n.t("translation:SensorData.functions.notInUse")}:`);
            value.push((diff > 1) ? `${diff} ${i18n.t("translation:SensorData.functions.luminaires")}` : `${diff} ${i18n.t("translation:SensorData.functions.luminaire")}`);
          }

          functionsDaliComfort.push({
            label: `${i18n.t("translation:SensorData.functions.lightScene")} ${i + 1}:`,
            type: `lightScene${i + 1}`,
            value: [(Object.keys(scene).length > 1) ? `${Object.keys(scene).length} ${i18n.t("translation:SensorData.functions.luminaires")}` : `${Object.keys(scene).length} ${i18n.t("translation:SensorData.functions.luminaire")}`],
            style: "h2",
          });
          functionsDaliComfort.push({
            type: "lightSceneLuxLevel",
            label: `${i18n.t("translation:SensorData.functions.lightSceneLuxLevel")}:`,
            value: value,
            style: "h3",
          });
        }
      }
    }

    if (isDetectorSetting && this.GetSetWirelessLinkFunctionModelReply != null)
    {
      //console.log(this.GetSetWirelessLinkFunctionModelReply)
      //console.log(this.UserConfigModel.CFG_SYSTEM_COMMON_FUNC1)
      //console.log(this.GetWirelessLinkEnabled())
      if (this.GetSetWirelessLinkFunctionModelReply.Function == 1)  // Folding doors
      {
        let nbFDoors = getNumberOfFoldingDoors()
        functionsDaliComfort.push({
          type: "foldingDoors",
          label: `${i18n.t("translation:SensorData.functions.foldingDoor")}:`,
          value: [],
          style: "h1",
        });

        functionsDaliComfort.push({
          type: "foldingDoorsEmpty",
          label: ``,
          value: [`${i18n.t("translation:SensorData.functions.foldingDoorDetails", {count: nbFDoors})}`],
          style: "h3",
        });
        // console.log(nbFDoors)

        functionsDaliComfort.push({
          type: "foldingDoorWired",
          label: `${i18n.t("translation:SensorData.functions.foldingDoorWired")}:`,
          value: [get230vInputsWiredToFoldingDoor()],
          style: "h3",
        });
      }
      else if (this.GetSetWirelessLinkFunctionModelReply.Function == 2)  // Adj area
      {
        functionsDaliComfort.push({
          type: "adjArea",
          label: `${i18n.t("translation:SensorData.functions.adjArea")}:`,
          value: [],
          style: "h1",
        });

        let lightLevel = this.GetSetWirelessLinkFunctionModelReply.Parameter;
        if (lightLevel != 254 && getNumberOfAdjAreasDetectors() > 1) // this is the active master detector
        {
          functionsDaliComfort.push({
            type: "adjAreaEmpty",
            label: ``,
            value: [`${i18n.t("translation:SensorData.functions.adjAreaActiveMaster", {count: getNumberOfAdjAreasDetectors()})}`],
            style: "h3",
          });

          functionsDaliComfort.push({
            type: "adjArealightLevel",
            label: `${i18n.t("translation:SensorData.common.lightLevel")}:`,
            value: [lightLevel+'%'],
            style: "h3",
          });
        }
        else
        {
          functionsDaliComfort.push({
            type: "adjAreaEmpty",
            label: ``,
            value: [`${i18n.t("translation:SensorData.functions.adjAreaMaster")}`],
            style: "h3",
          });
        }
      }
    }

    return functionsDaliComfort;
  }
}

import { ILanguageModel } from "../../models/LanguageModel";
import { ILanguageCountryModel } from "../../models/LanguageCountryModel";

export const languageList: ILanguageModel[] = [
    {
        name: "Select",
        key: "0",
        languageId: "",
        localeCode: "",
        localCodeGoogle: "",
    },
    {
        name: "Danish",
        key: "1",
        languageId: "735d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "da-DK",
        localCodeGoogle: "da",
    },
    {
        name: "Dutch",
        key: "2",
        languageId: "675d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "nl-BE",
        localCodeGoogle: "nl",
    },
    {
        name: "English",
        key: "3",
        languageId: "6b5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "en",
        localCodeGoogle: "en",
    },
    {
        name: "French",
        key: "4",
        languageId: "6f5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "fr-BE",
        localCodeGoogle: "fr",
    },
    {
        name: "German",
        key: "5",
        languageId: "795d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "de-DE",
        localCodeGoogle: "de",
    },
    {
        name: "Italian",
        key: "6",
        languageId: "7b5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "it-CH",
        localCodeGoogle: "it",
    },
    {
        name: "Norwegian",
        key: "7",
        languageId: "775d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "nb-NO",
        localCodeGoogle: "no",
    },
    {
        name: "Polish",
        key: "8",
        languageId: "7d5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "pl-PL",
        localCodeGoogle: "pl",
    },
    {
        name: "Slovak",
        key: "9",
        languageId: "7f5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "sk-SK",
        localCodeGoogle: "sk",
    },
    {
        name: "Swedish",
        key: "10",
        languageId: "755d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "sv-SE",
        localCodeGoogle: "sv",
    },
];

export const languageCountryList: ILanguageCountryModel[] = [
    {
        name: "België",
        key: "14",
        languageId: "675d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "be-NL",
        localCodeGoogle: "nl",
        country: "Belgium",
    },
    {
        name: "Belgique",
        key: "13",
        languageId: "6f5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "be-FR",
        localCodeGoogle: "fr",
        country: "Belgium",
    },
    {
        name: "Danmark",
        key: "1",
        languageId: "735d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "da-DK",
        localCodeGoogle: "da",
        country: "Denmark",
    },
    {
        name: "Deutschland",
        key: "5",
        languageId: "795d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "de-DE",
        localCodeGoogle: "de",
        country: "Germany",
    },
    {
        name: "France",
        key: "4",
        languageId: "6f5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "fr-BE",
        localCodeGoogle: "fr",
        country: "France",
    },
    {
        name: "International (EN)",
        key: "3",
        languageId: "6b5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "en",
        localCodeGoogle: "en",
        country: "Belgium",
    },
    {
        name: "Nederland",
        key: "2",
        languageId: "675d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "nl-BE",
        localCodeGoogle: "nl",
        country: "Netherlands",
    },
    {
        name: "Norway",
        key: "7",
        languageId: "775d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "nb-NO",
        localCodeGoogle: "no",
        country: "Norway",
    },
    {
        name: "Österreich",
        key: "10",
        languageId: "795d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "at-DE",
        localCodeGoogle: "de",
        country: "Austria",
    },
    {
        name: "Polski",
        key: "8",
        languageId: "7d5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "pl-PL",
        localCodeGoogle: "pl",
        country: "Poalnd",
    },
    {
        name: "Schweiz",
        key: "12",
        languageId: "795d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "sz-DE",
        localCodeGoogle: "de",
        country: "Switzerland",
    },
    {
        name: "Slovensko",
        key: "9",
        languageId: "7f5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "sk-SK",
        localCodeGoogle: "sk",
        country: "Slovakia",
    },
    {
        name: "Suisse",
        key: "11",
        languageId: "6f5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "sz-FR",
        localCodeGoogle: "fr",
        country: "Switzerland",
    },
    {
        name: "Sverige",
        key: "15",
        languageId: "755d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "sv-SE",
        localCodeGoogle: "sv",
        country: "Sweden",
    },
    {
        name: "Svizzera",
        key: "6",
        languageId: "7b5d95ea-6990-e611-80e3-c4346bad32b4",
        localeCode: "it-CH",
        localCodeGoogle: "it",
        country: "Switzerland",
    }
];

export const countryList = [
    { name: "Select", languageId: languageList[0].languageId, key: "0" },
    { name: "Austria", languageId: languageList[5].languageId, key: "Austria" },
    { name: "Belgium", languageId: languageList[2].languageId, key: "Belgium" },
    { name: "Denmark", languageId: languageList[1].languageId, key: "Denmark" },
    { name: "France", languageId: languageList[4].languageId, key: "France" },
    { name: "Germany", languageId: languageList[5].languageId, key: "Germany" },
    {
        name: "International (EN)",
        languageId: languageList[3].languageId,
        key: "International (EN)",
    },
    {
        name: "Netherlands",
        languageId: languageList[2].languageId,
        key: "Netherlands",
    },
    { name: "Norway", languageId: languageList[7].languageId, key: "Norway" },
    { name: "Poland", languageId: languageList[8].languageId, key: "Poland" },
    {
        name: "Slovakia",
        languageId: languageList[9].languageId,
        key: "Slovakia",
    },
    { name: "Sweden", languageId: languageList[10].languageId, key: "Sweden" },
    {
        name: "Switzerland",
        languageId: languageList[5].languageId,
        key: "Switzerland",
    },
];

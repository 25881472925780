import {makeStyles, createStyles} from "@material-ui/core/styles";
import {fade} from "@material-ui/core/styles/colorManipulator";
import colors from "../../common/styles/colors";

const inputAlign = {
    paddingLeft: "8px",
    borderLeft: `4px solid transparent`,
    marginLeft: `-12px`,
    minHeight: "100px",
};
const useStyles = makeStyles((theme) =>
    createStyles({
        homeContainer: {
            width: "100%",
            flexWrap: "nowrap",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignContent: "center",
            },
        },
        descriptionContainer: {
            width: "50%",
            [theme.breakpoints.down("md")]: {
                width: "100%",
            },
        },
        descriptionCard: {
            backgroundColor: fade(`${colors.secondaryColor}`, 0.6),
            borderRadius: "0",
            boxShadow: "none",
        },
        alignCenter: {
            width: "57%",
            margin: "0 auto",
            paddingBottom: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                width: "90%",
            },
        },
        title: {
            color: colors.blackColor,
            fontFamily: "VistaSansBold",
            fontSize: "35px",
            marginLeft: "70px",
            marginBottom: theme.spacing(4),
            paddingTop: theme.spacing(4),
        },
        titleRight: {
            color: colors.blackColor,
            fontFamily: "VistaSansBold",
            fontSize: "35px",

            marginBottom: theme.spacing(4),
            paddingTop: theme.spacing(4),
        },
        description: {
            color: colors.darkerGreyColor,
            fontFamily: "VistaSans",
            fontSize: "16px",
            lineHeight: "28px",
            marginLeft: "70px",
            marginBottom: theme.spacing(4),
        },
        descriptionRightGrid: {
            color: colors.darkerGreyColor,
            fontFamily: "VistaSans",
            fontSize: "16px",
            lineHeight: "28px",
            marginBottom: theme.spacing(4),
            width: "70%"
        },
        loginContainer: {
            width: "50%",
            backgroundColor: colors.whiteColor,
            [theme.breakpoints.down("md")]: {
                width: "100%",
            },
            [theme.breakpoints.down("xs")]: {
                padding: "50px 15px",
            },
        },
        buttonSize: {
            width: "140px",
            height: "48px",
            marginTop: "32px",
        },
        formContainer: {
            width: "352px",
            [theme.breakpoints.down("md")]: {
                marginLeft: "70px"
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
        verticalInputSpacing: {
            ...inputAlign,
        },
        errorBorder: {
            borderLeft: `4px solid ${colors.errorRedColor}`,
        },
        inputLabel: {
            color: colors.darkerGreyColor,
            fontSize: "16px",
            lineHeight: "28px",
            fontFamily: "Roboto, sans-serif",
        },
        textInput: {
            borderRadius: "24px",
            backgroundColor: colors.veryLightGreyColor,
            color: colors.primaryColor,
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    border: "0",
                },
            },
            padding: "10px 24px",
            "& .MuiInputBase-input": {
                fontSize: "16px",
                lineHeight: "18px",
                fontFamily: "Roboto, sans-serif",
                color: colors.primaryColor,
                height: "18px",
                backgroundColor: `${colors.veryLightGreyColor}!important`,
            },
            "& .MuiOutlinedInput-input": {
                padding: "5px 0",
            },
            "& .MuiInputBase-root": {
                paddingRight: "0",
            },
        },
        verticalSpacing: {
            marginBottom: theme.spacing(2),
        },
        error: {
            color: colors.errorRedColor,
            fontSize: "12px",
            lineHeight: "16px",
            marginTop: "8px",
        },
    })
);

export default useStyles;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Button,
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core";
import useTypographyStyles from "../../common/styles/typography";
import useButtonStyles from "../../common/styles/buttonStyles";
import useStyles from "./ChangePassword.style";
import ShowPassword from "../../common/components/ShowPassword";
import validators from "../../common/utils/validators";

interface IChangePassword {
  closeModal(): void;
  saveChanges(oldPassword: string, newPassword: string): void;
  changePasswordResponse: number | undefined;
}

const ChangePassword: React.FC<IChangePassword> = (props) => {
  const classes = useStyles();
  const typographyClasses = useTypographyStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [badOldPassword, setBadOldPassword] = useState(false);
  const [badNewPassword, setBadNewPassword] = useState(false);

  const { closeModal, saveChanges, changePasswordResponse } = props;

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPassword = event.target.value;
    if (newPassword !== undefined) {
      setNewPassword(newPassword);
    }
    if (newPassword !== "" && validators.isPasswordValid(newPassword) === true) {
      setBadNewPassword(false);
    }
  };

  const handleOldPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const oldPassword = event.target.value;
    if (oldPassword !== undefined) {
      setOldPassword(oldPassword);
    }
    setBadOldPassword(false);
  };

  const handleSaveChanges = () => {
    if (oldPassword === "" || oldPassword === undefined){
      setBadOldPassword(true);
    }
    else if (newPassword === "" || newPassword=== undefined || validators.isPasswordValid(newPassword) === false){
      setBadNewPassword(true);
    } else if (oldPassword === newPassword){
      setBadNewPassword(true);

    } else  {
      saveChanges(oldPassword, newPassword);
    }
  };

  const getPasswordError = (password: string | undefined) => {
    if (password === "" || password === undefined) {
      return t("translation:common.requiredError");
    }
    if (validators.isPasswordValid(password) === false) {
      return t("translation:common.passwordWeakError");
    }
    if (oldPassword === newPassword) {
      return t("translation:common.passwordsAreTheSame");
    }
  };

  useEffect(() => {
    if (changePasswordResponse === 400) {
      setBadNewPassword(true);
    }
    else if (changePasswordResponse === 404) {
      setBadOldPassword(true);
    }
  }, [changePasswordResponse]);

  return (
    <>
      <Grid item>
        <Typography
          align="center"
          component="h3"
          className={typographyClasses.headerThreeBlack}
        >
          {t("translation:login_My_account.changePasswordModal.title")}
        </Typography>
      </Grid>
      <Grid item className={classes.form}>
        <div
          className={`${classes.verticalInputSpacing} ${oldPassword === "" && classes.errorBorder
            } `}
        >
          <InputLabel className={classes.inputLabel} required={true}>
            {t("translation:login_My_account.changePasswordModal.oldPassword")}
          </InputLabel>
          <TextField
            type={showOldPassword ? "text" : "password"}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowOldPassword}>
                    <ShowPassword showPassword={showOldPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            focused={false}
            placeholder="********"
            className={classes.textInput}
            onChange={handleOldPasswordChange}
          />
          {oldPassword === "" && (
            <Box>
              <Typography className={classes.error}>
                {oldPassword === ""
                  ? `${t("translation:common.requiredError")}`
                  : `${t("translation:common.passwordError")}`}
              </Typography>
            </Box>
          )}
          {badOldPassword && (
            <Box>
              <Typography className={classes.error}>
                {`${t("translation:common.passwordError")}`}
              </Typography>
            </Box>
          )}
        </div>
      </Grid>
      <Grid item className={classes.newPassword}>
        <div
          className={`${classes.verticalInputSpacing} ${newPassword === "" && classes.errorBorder
            } `}
        >
          <InputLabel className={classes.inputLabel} required={true}>
            {t("translation:login_My_account.changePasswordModal.newPassword")}
          </InputLabel>
          <TextField
            type={showNewPassword ? "text" : "password"}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowNewPassword}>
                    <ShowPassword showPassword={showNewPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            focused={false}
            placeholder="********"
            className={classes.textInput}
            onChange={handleNewPasswordChange}
          />
          {newPassword === "" && (
            <Box>
              <Typography className={classes.error}>
                {newPassword === ""
                  ? `${t("translation:common.requiredError")}`
                  : `${t("translation:common.passwordError")}`}
              </Typography>
            </Box>
          )}
          {badNewPassword && (
            <Box>
              <Typography className={classes.error}>
                {getPasswordError(newPassword)}
              </Typography>
            </Box>
            
          )}
        </div>
      </Grid>
          <Grid item className={classes.textContainer}>
            <Typography className={classes.modalParagraph}>
                {t("translation:login_My_account.changePasswordModal.details")}
            </Typography>
            </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
          className={buttonClasses.lightButtonWithBorderLargeWidth}
          onClick={closeModal}
        >
          {t("translation:common.cancel")}
        </Button>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
           disabled={!oldPassword || !newPassword || badOldPassword || badNewPassword}
          className={buttonClasses.primaryButtonLargeWidth}
          onClick={handleSaveChanges}
        >
          {t("translation:common.saveChanges")}
        </Button>
      </Grid>
    </>
  );
};

export default ChangePassword;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import { commonParagraph } from "../../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      fontSize: "44px",
      lineHeight: "47px",
      fontFamily: "VistaSansReg",
      color: colors.primaryColor,
      fontWeight: 700,
      marginBottom: theme.spacing(4),
    },
    content: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      paddingBottom: theme.spacing(4),
    },
  })
);

export default useStyles;

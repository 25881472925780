import SensorData from "./SensorData";
import i18n from "i18next";

export const GetSensorDescription230VMaster = (detectorInfo: any, isSensorSetting: boolean) => {
  const SensorDataMethods = new SensorData(detectorInfo);

  let detector = {
    channels: SensorDataMethods.GetChannels(),
    fineTunning: SensorDataMethods.GetFineTunning(),
    pushButtons: SensorDataMethods.GetPushButtons(),
    communication: SensorDataMethods.GetCommunication(isSensorSetting),
    tabs: {
      channels: `${i18n.t("translation:SensorData.tabs.channelsTab")}`,
      fineTunning: `${i18n.t("translation:SensorData.tabs.fineTunningTab")}`,
      pushButtons: `${i18n.t("translation:SensorData.tabs.pushButtonsTab")}`,
      communication: `${i18n.t(
        "translation:SensorData.tabs.communicationTab"
      )}`,
    },
  };

  return detector;
};

export const GetSensorDescription230VSlave = (detectorInfo: any) => {
  const SensorDataMethods = new SensorData(detectorInfo);

  let detector = {
    fineTunning: SensorDataMethods.GetFineTunning(),
    tabs: {
      fineTunning: `${i18n.t("translation:SensorData.tabs.fineTunningTab")}`,
    },
  };

  return detector;
};

export const GetSensorDescriptionDaliMaster = (detectorInfo: any, isSensorSetting: boolean) => {
  const SensorDataMethods = new SensorData(detectorInfo);

  let detector = {
    settings: SensorDataMethods.GetSettingsDali(false, isSensorSetting),
    fineTunning: SensorDataMethods.GetFineTunningDali(isSensorSetting),
    pushButtons: SensorDataMethods.GetPushButtonsDali(isSensorSetting),
    tabs: {
      settings: `${i18n.t("translation:SensorData.tabs.settingsTab")}`,
      fineTunning: `${i18n.t("translation:SensorData.tabs.fineTunningTab")}`,
      pushButtons: `${i18n.t("translation:SensorData.tabs.pushButtonsTab")}`,
    },
  };

  return detector;
};

export const GetSensorDescriptionDaliMasterComfort = (detectorInfo: any, isSensorSetting: boolean) => {
  const SensorDataMethods = new SensorData(detectorInfo);

  let detector = {
    settings: SensorDataMethods.GetSettingsDaliComfort(isSensorSetting),
    fineTunning: SensorDataMethods.GetFineTunningDali(isSensorSetting),
    pushButtons: SensorDataMethods.GetButtonsDaliComfort(isSensorSetting),
    functions: SensorDataMethods.GetFunctionsDaliComfort(isSensorSetting),
    tabs: {
      settings: `${i18n.t("translation:SensorData.tabs.settingsTab")}`,
      fineTunning: `${i18n.t("translation:SensorData.tabs.fineTunningTab")}`,
      pushButtons: `${i18n.t("translation:SensorData.tabs.pushButtonsTab")}`,
      functions: `${i18n.t("translation:SensorData.tabs.functionsTab")}`,
    },
  };

  return detector;
};

export const GetSensorDescriptionDaliSlave = (detectorInfo: any) => {
  const SensorDataMethods = new SensorData(detectorInfo);

  let detector = {
    fineTunning: SensorDataMethods.GetFineTunningDaliSlave(),
    tabs: {
      fineTunning: `${i18n.t("translation:SensorData.tabs.fineTunningTab")}`,
    },
  };

  return detector;
};

const GetSensorDescription = (detector: any, isSensorSetting: boolean) => {
  try {
    let family = detector.sensorInformation.detectorFamily.toLowerCase();
    let type = detector.sensorInformation.detectorType.toLowerCase();
    let daliComfort = detector.sensorInformation.detectorOutputInfo;

    if (family.includes("230v")) {
      if (type.includes("master")) {
        return GetSensorDescription230VMaster(detector, isSensorSetting);
      } else {
        return GetSensorDescription230VSlave(detector);
      }
    } else if (family.includes("dali")) {
      if (type.includes("master")) {
        if (
          daliComfort === "wirelessComfort" ||
          daliComfort === "daliComfort"
        ) {
          return GetSensorDescriptionDaliMasterComfort(detector, isSensorSetting);
        }
        return GetSensorDescriptionDaliMaster(detector, isSensorSetting);
      } else {
        return GetSensorDescriptionDaliSlave(detector);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export default GetSensorDescription;

/* 230V Object
{
    channels: [
        {
            behaviourTriggeredByMovement: "Behaviour triggered by movement: Switch the light ON and OFF"
            luxLevel: "Lux Level: 500 lux"
            name: "Channel 1"
            switchOffAboveLux: "Switch-off above lux level: ON"
            switchOffDelay: "Switch-off delay: 5 minutes"
        },
        {
            behaviourTriggeredByMovement: "Behaviour triggered by movement: Switch the light ON and OFF"
            luxLevel: "Lux Level: 500 lux"
            name: "Channel 2 Lightning "
            switchOffAboveLux: "Switch-off above lux level: ON"
            switchOffDelay: "Switch-off delay: 5 minutes"
        }
    ],
    fineTunning: [
        {
            name: "Sensitivity: High"
        }
    ],
    pushButtons: [
        {
            name: "Push button T1"
            pushButtonBehaviour: "Push button behaviour: Switch devices ON/OFF"
            pushButtonControlledChannels: "This push button controls: Channel 1 & 2"
        },
        {
            name: "Push button T2"
            pushButtonBehaviour: "Push button behaviour: Switch devices ON/OFF"
            pushButtonControlledChannels: "This push button controls: Channel 2"
        }
    ],
    communication: [
        name: "Master - slave"
        wirelessCommunication: "Wireless Communication: OFF "
    ],
    tabs: {
        channels: "Channels"
        communication: "Communication"
        fineTunning: "Fine-Tunning"
        pushButtons: "Push buttons"
    }
}
*/

/* Dali Object
{
    settings: [
        {
            name: "Broadcast "
            luxLevel: "Lux Level: 300 lux"
            behaviourTriggeredByMovement: "Behaviour triggered by movement: Switch devices ON and OFF"
            orientationLightLevel: "Orientation light level: 20 %"
            orientationLightSwitchOffDelay: "Orientation light switch-off delay: 10 minutes"
            switchOffAboveLux: "Switch-off above lux level: OFF"
            switchOffDelay: "Switch-off delay: 15 minutes"
        }
    ],
    fineTunning: [
        {
            name: "Sensitivity: High"
        },
        {
            name: "Daylight control when dimming"
            value: "Daylight control when dimming: OFF"
        }
    ],
    pushButtons: [
        {
            name: "Push button T1"
            pushButtonBehaviour: "Push button behaviour: Switch devices ON / OFF"
            pushButtonControls: "Push Button Controls: All Zones"
        },
        {
            name: "Push Button Operation"
            operation: "Press the push button before the device reacts for: 1 second"
        }
    ],
    tabs: {
        settings: "Settings"
        fineTunning: "Fine-Tunning"
        pushButtons: "Push buttons"
    }
}
*/

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Checkbox,
} from "@material-ui/core";
import ChevronUp from "../../../../common/components/ChevronUp";
import ChevronDown from "../../../../common/components/ChevronDown";
import CheckedBoldBox from "../../../../common/components/CheckedBoldBox";
import UncheckedGreyBox from "../../../../common/components/UncheckedGreyBox";
import useStyles from "./FilterTemplates.style";

export interface IFilterSet {
  [key: string]: {
    name: string;
    detectorType: string;
    count: number;
  }[];
}

interface IFilterTemplates {
  getSelectedTabFilters: string[];
  filtersSet: IFilterSet;
  selectedNames(data: string[]): void;
}

const FilterTemplates: React.FC<IFilterTemplates> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { getSelectedTabFilters, filtersSet, selectedNames } = props;
  const [openDali2, setOpenDali2] = useState(false);
  const [open230V, setOpen230V] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    setSelectedFilters(getSelectedTabFilters);
  }, [getSelectedTabFilters]);

  const handleExpandDali2 = () => {
    setOpenDali2(!openDali2);
  };

  const handleExpand230V = () => {
    setOpen230V(!open230V);
  };

  const isFilterSelected = (filter: string): boolean => {
    return selectedFilters.some((f) => f === filter);
  };

  const handleSelectedFilters = (selected: boolean, filter: string, count: number) => {
    if (count === 0) {
      return;
    }

    if (selected) {
      const filters = selectedFilters.concat(filter);
      setSelectedFilters(filters);
      selectedNames(filters);
    } else {
      const filters = selectedFilters.filter(
          (filterName) => filterName !== filter
      );
      setSelectedFilters(filters);
      selectedNames(filters);
    }
  }

  return (
    <List>
      <ListItem button onClick={handleExpandDali2} className={classes.infoItem}>
        <ListItemText className={classes.boldText} primary="DALI" />
        {openDali2 ? <ChevronUp /> : <ChevronDown />}
      </ListItem>
      <Collapse in={openDali2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {filtersSet["dali"] &&
            filtersSet["dali"].map(
              (data, index) => (
                  <ListItem button className={classes.infoItem} key={index}>
                    <ListItemIcon className={classes.checkbox}>
                      <Checkbox
                        edge="start"
                        disabled={data.count === 0}
                        disableRipple
                        checked={isFilterSelected(data.detectorType)}
                        onChange={(event) =>
                            handleSelectedFilters(event.target.checked, data.detectorType, data.count)
                        }
                        checkedIcon={<CheckedBoldBox />}
                        inputProps={{ "aria-labelledby": "checkbox" }}
                        icon={<UncheckedGreyBox />}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={
                        data.count === 0 ? classes.greyedOutText : isFilterSelected(data.detectorType)
                                                                    ? classes.boldText : classes.nestedInfo
                      }
                      primary={`${data.detectorType} (${data.count})`}
                      onClick={(event) => {
                        handleSelectedFilters(!isFilterSelected(data.detectorType), data.detectorType, data.count)
                      }}
                    />
                  </ListItem>
                )
            )}
        </List>
      </Collapse>
      <ListItem button onClick={handleExpand230V} className={classes.infoItem}>
        <ListItemText className={classes.boldText} primary="230 V" />
        {open230V ? <ChevronUp /> : <ChevronDown />}
      </ListItem>
      <Collapse in={open230V} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {filtersSet["230V"] &&
            filtersSet["230V"].map(
              (data, index) => (
                  <ListItem
                    button
                    className={classes.infoItem}
                    key={index}
                  >
                    <ListItemIcon className={classes.checkbox}>
                      <Checkbox
                        edge="start"
                        disabled={data.count === 0}
                        checked={isFilterSelected(data.detectorType)}
                        onChange={(event) =>
                            handleSelectedFilters(event.target.checked, data.detectorType, data.count)
                        }
                        disableRipple
                        checkedIcon={<CheckedBoldBox />}
                        inputProps={{ "aria-labelledby": "checkbox" }}
                        icon={<UncheckedGreyBox />}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={
                        data.count === 0 ? classes.greyedOutText : isFilterSelected(data.detectorType)
                                                                     ? classes.boldText : classes.nestedInfo
                      }
                      primary={`${data.detectorType} (${data.count}) `}
                      onClick={(event) => {
                        handleSelectedFilters(!isFilterSelected(data.detectorType), data.detectorType, data.count)
                      }}
                    />
                  </ListItem>
                )
            )}
        </List>
      </Collapse>
    </List>
  );
};

export default FilterTemplates;

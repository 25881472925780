import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import { AppRoute } from "../../constants/routes";
import { getLocalizedRoute } from "../../services/i18n/utils/routeLocalization";
import useButtonStyles from "../../styles/buttonStyles";
import useStyles from "./ResponseStatus.style";

interface IActionStatus {
  title: string;
  content: string;
}

const ResponseStatus: React.FC<IActionStatus> = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const { formatMessage, locale } = useIntl();
  let history = useHistory();

  const { title, content } = props;

  const redirectToLogin = () => {
    history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
  };

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.itemContainer}>
        <img
          className={classes.image}
          src="/img/Detectortoolportal_height_small.jpg"
          alt="accountActivated"
        />
      </Grid>

      <Grid item className={classes.contentWrapper}>
        <img src="/img/checkmark.svg" alt="checkmark" />
        <Typography className={classes.title} component="h3">
          {title}
        </Typography>
        <Typography className={classes.content}>{content}</Typography>
        <Button
          onClick={redirectToLogin}
          className={` ${buttonClasses.primaryButton} ${classes.button}`}
        >
          {t("translation:common.loginButton")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResponseStatus;

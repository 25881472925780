import { IProjectsResponse } from "../../models/ResponseModel";
import httpService from "../HTTPService";
import {
  IProjectResponseModel,
  mapFromIProjectResponseModelToIProject,
} from "./ProjectMapper";

const baseUrl = "Project";

export const getAllProjects = async (impersonatedUserEmail: string | null,
                                    withFullInfo: boolean = false,
                                    sortBy: string = 'name',
                                    offset: number = 0,
                                    limit: number = 10000): Promise<IProjectsResponse> => {
  let url = baseUrl + `/GetAll`;
  url += `?withFullInfo=${withFullInfo}`;
  url += `&sortBy=${sortBy}`;
  url += `&offset=${offset}`;
  url += `&limit=${limit}`;

  url = impersonateIfNeeded(url, impersonatedUserEmail);
  try {
    const response = await httpService.sendMessage("get", url);
    if (response.status === 200) {
      return {
        status: "success",
        data: response.data.map((p: IProjectResponseModel) =>
          mapFromIProjectResponseModelToIProject(p)
        ),
      };
    }
    return { status: "error" };
  } catch (error) {
    return { status: "error" };
  }
};

export const getProjectById = async (projectId: string, impersonatedUserEmail: string | null): Promise<any> => {
  let url = baseUrl + `/GetOne/${projectId}`;
  url = impersonateIfNeeded(url, impersonatedUserEmail);

  try {
    const response = await httpService.sendMessage("get", url, projectId);
    if (response.status === 200) {
      return {
        status: "success",
        data: mapFromIProjectResponseModelToIProject(response.data),
      };
    }
    return { status: "error" };
  } catch (error) {
    return { status: "error" };
  }
};

export const removeProjects = async (ids: string[], impersonatedUserEmail: string | null): Promise<any> => {
  try {
    let url = baseUrl + `/deleteMany`;
    url = impersonateIfNeeded(url, impersonatedUserEmail);

    const response = await httpService.sendMessage("post", url, ids);
    if (response.status === 200) {
      return { status: "success" };
    }
  } catch (error) {
    return { status: "error" };
  }
};

export const updateProject = async (data: { name: string; id: string; }, impersonatedUserEmail: string | null): Promise<any> => {
  try {
    let url = baseUrl;
    url = impersonateIfNeeded(url, impersonatedUserEmail);

    const response = await httpService.sendMessage("put", url, data);
    if (response.status === 200) {
      return { status: "success" };
    }
  } catch (error) {
    return "error";
  }
};

const impersonateIfNeeded = (initialUrl: string, impersonatedUserEmail: string | null): string =>  {
  let url = initialUrl;
  if (impersonatedUserEmail !== null) {
    if (url.includes('?')) {
      url += ("&impersonate=" + impersonatedUserEmail);
    }
    else {
      url += ("?impersonate=" + impersonatedUserEmail);
    }
  }
  return url;
}

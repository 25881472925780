import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";
import {
  commonHeaderThree,
  commonParagraph,
} from "../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    statusWrapper: {
      //paddingTop: theme.spacing(7.5),
      //width: "85%",
      //margin: "0 auto",
      marginBottom: "-6px",
      flexWrap: "nowrap",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingTop: theme.spacing(3),
      },
    },
    title: {
      color: colors.primaryColor,
      ...commonHeaderThree,
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(5),
    },
    descriptionContainer: {
      width: "45%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
          textAlign: "center",
        display:'none',
      },
    },
    contentWrapper: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colors.whiteColor,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "20px 15px",
      },
    },
    content: {
      width: "55%",
      color: colors.primaryColor,
      ...commonParagraph,
      textAlign: "center",
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    link: {
      color: colors.darkerGreyColor,
      ...commonParagraph,
      textDecorationColor: colors.darkerGreyColor,
    },
    image: {
      //width: "100%",
    },
  })
);

export default useStyles;

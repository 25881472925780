import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import useButtonStyles from "../styles/buttonStyles";
import useTypographyStyles from "../styles/typography";
import useStyles from "./StatusModal.style";

interface IStatusModal {
  title: string;
  retry?: boolean;
  retryMethod(): void;
  content(): void;
  closeModal(): void;
}

const StatusModal: React.FC<IStatusModal> = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const typographyClasses = useTypographyStyles();
  const { t } = useTranslation(["translation"]);

  const { title, content, closeModal, retry, retryMethod } = props;

  return (
    <>
      <Grid item>
        <Typography
          className={typographyClasses.headerThreeBlack}
          component="h3"
        >
          {title}
        </Typography>
      </Grid>
      {content()}
      {retry &&
        <Grid item className={classes.buttonsContainerTwo}>
          <Button
              className={buttonClasses.primaryButton}
              fullWidth={true}
              onClick={closeModal}
          >
            {t("translation:common.back")}
          </Button>
          <Button
              className={buttonClasses.lightButtonWithBorder}
              fullWidth={true}
              onClick={retryMethod}
          >
            {t("translation:common.retry")}
          </Button>
        </Grid>
      }

      {!retry &&
        <Grid item className={classes.buttonContainer}>
          <Button
          className={buttonClasses.primaryButton14px}
          fullWidth={true}
          onClick={closeModal}
          >
            {t("translation:common.ok")}
          </Button>
        </Grid>
      }
    </>
  );
};

export default StatusModal;

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@material-ui/core";
import useStyles from "./StatusChangePassword.style";
import useButtonStyles from "../../common/styles/buttonStyles";
import useTypographyStyles from "../../common/styles/typography";

interface IStatusModal {
  closeModal(): void;
}

const StatusChangePassword: React.FC<IStatusModal> = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const typographyClasses = useTypographyStyles();
  const { t } = useTranslation(["translation"]);

  const { closeModal } = props;

  return (
    <>
      <Grid item className={classes.contentWrapper}>
        <img
          src="/img/checkmark.svg"
          alt="checkmark"
          className={classes.image}
        />
        <Typography
          className={typographyClasses.headerThreeBlack}
          component="h3"
        >
          {t("translation:login_My_account.changePasswordModal.statusTitle")}
        </Typography>
        <Typography
          className={`${classes.content} ${typographyClasses.paragraphPrimary}`}
        >
          {t("translation:login_My_account.changePasswordModal.statusContent")}
        </Typography>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button
          className={buttonClasses.primaryButton}
          fullWidth={true}
          onClick={closeModal}
        >
          {t("translation:login_My_account.changePasswordModal.logout")}
        </Button>
      </Grid>
    </>
  );
};

export default StatusChangePassword;

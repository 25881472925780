import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../common/styles/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalDeleteCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "402px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
    actionsBar: {
      justifyContent: "space-between",
      flexWrap: "nowrap",
      [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-around",
        },
      },
    },
    actionsWrapper: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      //display: "flex",
      justifyContent: "space-between",
      maxWidth: "650px",
      width: "100%",
      display: "flex",

      "& button": {
        marginRight: "40px",
        marginBottom: "2px",
        [theme.breakpoints.down("sm")]: {
          marginRight: "2px",
        }
      },
    },
    modalExportCard: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "508px",
      height: "488px",
      paddingTop: theme.spacing(13),
      position: "absolute",
      backgroundColor: colors.secondaryColor,
      alignItems: "center",
    },
  })
);

export default useStyles;

import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(request => {
    const existingToken = localStorage.getItem("tokens");
    if (existingToken) {
        request.headers['Authorization'] = `Basic ${existingToken}`;
    }
    request.headers['Cache-Control'] = 'no-cache';
    request.headers['Pragma'] = 'no-cache';
    request.headers['Expires'] = '0';

    return request;
}, error => {
  return Promise.reject(error);
});

// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     return response;
//   }, function (error) {
//     return Promise.reject(error);
//   });

class HTTPService {
    sendMessage(method: string, url: string, body = {}, options: any = { headers: {} }) {
        const request = method.toLowerCase();
        const error = 'The first sendMessage() parameter must have one of the following values: get, post, put or delete!';
        const extraOptions = options;

        switch (request) {
            case 'get':
                return this.get(url, extraOptions);
            case 'post':
                return this.post(url, body, extraOptions);
            case 'put':
                return this.put(url, body, extraOptions);
            case 'delete':
                return this.delete(url, extraOptions);
            default:
                throw new Error(error);
        }
    }

        private get = (url:string, options: any) => axios.get(url, options)

        private post = (url: string, body: object, options: any) => axios.post(url, body, options)

        private put = (url: string, body: object, options: any) => axios.put(url, body, options)

        private delete = (url: string, options: any) => axios.delete(url, options)
}

const httpService = new HTTPService();
export default httpService;

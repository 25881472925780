import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import ChevronDown from "./ChevronDown";
import useStyles from "./DropDownSelect.style";
import { useTranslation } from "react-i18next";

interface IOption {
  name: string;
  key: string;
  languageId: string;
}

interface IDropDownSelect {
  dropdownType: string; // "countries" or "languages"
  selectOptions: IOption[];
  selectedOption: string;
  handleChangeSelect(event: React.ChangeEvent<{ value: unknown }>): void;
}

const DropDownSelect: React.FC<IDropDownSelect> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);

  const { dropdownType, selectOptions, selectedOption, handleChangeSelect } = props;

  const getDisplayName = (name: string) => {
      return t(`translation:${dropdownType}.${name}`);
  }

  return (
    <FormControl variant="outlined" fullWidth={true}>
      <Select
        value={selectedOption}
        onChange={handleChangeSelect}
        className={classes.select}
        IconComponent={ChevronDown}
        MenuProps={{
          classes: { paper: classes.dropdownList },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {selectOptions.map((item, index) => (
          <MenuItem
            key={index}
            value={item.key}
            className={classes.popupSelect}
          >
              {item.name}
            {/*{getDisplayName(item.name)}*/}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownSelect;
